import React, { useState, useContext, useEffect } from "react";
import moment from "moment";
import { GlobalContext } from "../../global-context";

import { makeStyles, Container, Typography } from "@material-ui/core";

import gql from "graphql-tag";
import fragments from "../utils/graphQL/fragments";

import CalculatorForm from "./moveCalculator/CalculatorForm";

const log = false;

////////// STYLES //////////
const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    position: "relative",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  head: {
    marginBottom: theme.spacing(1),
    lineHeight: 1.25,
    fontSize: "24px",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(0.75),
      fontSize: "21px",
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(0.5),
      fontSize: "18px",
    },
  },
  sub: {
    maxWidth: "640px",
    marginBottom: theme.spacing(2),
    lineHeight: 1.25,
    fontSize: "14px",
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      fontSize: "13px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12px",
    },
  }
}));

////////// TOOLS //////////
const getStartTime = () => {
  let start = moment().startOf(`minute`).add(90, `minutes`);
  const addStep = 10 - (start.minutes() % 10);
  start = start.add(addStep, `minutes`);
  return moment.utc(start);
};

////////// COMPONENT //////////
export default function MoveCalculator(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  // State
  const [moves, setMoves] = useState([
    {
      index: 0,
      pickupTime: getStartTime(),
      consumerPickup: false,
      validation: {
        pickup: true,
        delivery: true,
        ptime: true,
        pdate: true,
        stock: true,
        vin: true,
        make: true,
        model: true,
        year: true,
        color: true,
        cname: true,
        cnumber: true,
      },
    },
    {
      index: 1,
      pickupTime: getStartTime(),
      consumerPickup: false,
      validation: {
        pickup: true,
        delivery: true,
        ptime: true,
        pdate: true,
        stock: true,
        vin: true,
        make: true,
        model: true,
        year: true,
        color: true,
        cname: true,
        cnumber: true,
      },
    },
  ]);

  const getLaneByLocationIds = async (pickupId, deliveryId, inverseLane = false) => {
    try {
      const pickup = inverseLane ? deliveryId : pickupId;
      const delivery = inverseLane ? pickupId : deliveryId;
      const res = await ctx.apolloClient.query({
        query: GET_LANES_BY_LOCATIONS,
        variables: { pickupId: pickup, deliveryId: delivery },
      });
      if (res.data && res.data.lanes.length > 0) {
        log && console.log(`Found lane:`, res.data.lanes[0]);
        return res.data.lanes[0];
      }
    } catch (err) {
      log && console.log(`Failed to find lane:`, err);
      ctx.handleNotifications(true, `error`, `Failed to find lane: ` + err.toString());
    }
  };

  const handleLaneChange = async (lane) => {
    log && console.log("Handle lane change: ", lane);
    if (!lane) return;
    
    const inboundPickupId = lane && lane.pickup && lane.pickup.id ? lane.pickup.id : 0;
    const inboundDeliveryId = lane && lane.delivery && lane.delivery.id ? lane.delivery.id : 0;
    const movePickupId = moves[0] && moves[0].pickup && moves[0].pickup.id ? moves[0].pickup.id : 0;
    const moveDeliveryId = moves[0] && moves[0].delivery && moves[0].delivery.id ? moves[0].delivery.id : 0;

    //Handle the pickup location changing
    if (inboundPickupId != movePickupId) {
      moves[0].pickup = lane.pickup;
      moves[1].delivery = lane.pickup;
      if (!lane.pickup && log) console.log("clearing pickup");
      // await updateAllMoveLocationsAndLanes(0, lane.pickup);
    }

    //Handle the delivery location changing
    if (inboundDeliveryId != moveDeliveryId) {
      moves[0].delivery = lane.delivery;
      moves[1].pickup = lane.delivery;
      if (!lane.delivery && log) console.log("clearing delivery");
      // await updateAllMoveLocationsAndLanes(1, lane.delivery);
    }
  };

  return (
    <>
      <div className={cls.root}>
        <Container maxWidth="lg">
          <Typography className={cls.head}>Move Calculator</Typography>
          <Typography className={cls.sub}>
            Welcome to the move calculator. This form allows you to calculate 
            the route and cost information of a move based on today's date. 
          </Typography>
          <CalculatorForm
            validation={moves[0].validation}
            onLaneChange={handleLaneChange}
            getLaneByLocationIds={getLaneByLocationIds}
          />
        </Container>
      </div>
    </>
  );
}

////////// GRAPHQL //////////

const GET_LANES_BY_LOCATIONS = gql`
  query get_lanes_by_locations($pickupId: bigint!, $deliveryId: bigint!) {
    lanes(
      where: { origin_location_id: { _eq: $pickupId }, destination_location_id: { _eq: $deliveryId } }
      order_by: [{ favorite: desc }, { description: asc }]
    ) {
      ...Lane
    }
  }
  ${fragments.lane}
`;
