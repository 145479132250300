import React, { useState, useContext, useEffect } from 'react';
import Geocode from "react-geocode";
import { GlobalContext } from '../../../global-context';

import { makeStyles, TextField, InputAdornment, Icon, MenuItem, Divider } from '@material-ui/core';
import { 
  Modal, 
  ModalHeader, 
  ModalContent, 
  ModalFooter, 
  ModalAction 
} from '../../reusable/ModalComponents';

const log = false;

////////// COMPONENT //////////
export default function AddressBuilderModal(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const { open, onClose, addressInput } = props;

  const [street, setStreet] = useState(``);
  const [city, setCity] = useState(``);
  const [state, setState] = useState(``);
  const [zip, setZip] = useState(``);
  const [country, setCountry] = useState(``);

  useEffect(() => {
    if (addressInput) setStreet(addressInput);
  }, [addressInput])

  const handleInputChange = setHandler => event => {
    if (event.target.value !== `-`) setHandler(event.target.value);
    else setHandler(``);
  };
  const handleKeyUp = event => {
    if (event.key === `Enter` && street.length > 0) handleSubmit();
  }

  const handleSubmit = async () => {
    const submittedAddress = `${street} ${city} ${state} ${zip} ${country}`;
    Geocode.setApiKey(ctx.userProfile["https://api_keys.io/jwt/claims"]["GoogleMapsKey"]);
    Geocode.fromAddress(submittedAddress)
      .then(res => {
        log && console.log(`Found Google results:`, res);
        const locationInput = {
          name: res.results[0].formatted_address.split(`,`)[0],
          address: res.results[0].formatted_address,
          place_id: res.results[0].place_id,
          latitude: res.results[0].geometry.location.lat,
          longitude: res.results[0].geometry.location.lng,
        }
        ctx.handleNotifications(true, `info`, `Related address found - '${locationInput.address}'`);
        handleClose(locationInput);
      })
      .catch(err => {
        console.log(`Error finding location from address:`, err);
        ctx.handleNotifications(true, `warning`, `No address found! Please provide more information.`);
      });
  }
  const handleClose = (locationInput) => {
    if (onClose) onClose(locationInput);
    setStreet(``);
    setCity(``);
    setState(``);
    setZip(``);
    setCountry(``);
  }

  return (<>
    <Modal open={open}>

      <ModalHeader handleClose={handleClose}>Find Location</ModalHeader>

      <ModalContent subtitle={`Use the fields below to provide enough information for Google to properly find & build your location. This tool is typically used when you can't find your address in the Google\xa0suggestions.`}>
        <div className={cls.smallBreak} />

        <TextField
          required
          fullWidth
          label="Street Address"
          placeholder="Enter the street address..."
          value={street}
          onChange={handleInputChange(setStreet)}
          margin="dense"
          variant="outlined"
          className={cls.input}
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ verticalAlign: "top" }} position="start">
                <Icon color="disabled" fontSize="small">home_work</Icon>
              </InputAdornment>
            )
          }}
          onKeyUp={handleKeyUp}
        />

        <div className={cls.break} />

        <TextField
          fullWidth
          label="City/County"
          placeholder="(Optional) Enter the city/county..."
          value={city}
          onChange={handleInputChange(setCity)}
          margin="dense"
          variant="outlined"
          className={cls.input}
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ verticalAlign: "top" }} position="start">
                <Icon color="disabled" fontSize="small">location_city</Icon>
              </InputAdornment>
            )
          }}
        />

        <div className={cls.break} />

        <TextField
          select
          fullWidth
          label="State/Territory"
          placeholder="(Optional) Select a state/territory..."
          value={state || `-`}
          onChange={handleInputChange(setState)}
          margin="dense"
          variant="outlined"
          className={cls.input}
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ verticalAlign: "top" }} position="start">
                <Icon color="disabled" fontSize="small">flag</Icon>
              </InputAdornment>
            )
          }}
        >
          <MenuItem value={`-`}><div className={cls.placeholder}>(Optional) Select a State/Territory</div></MenuItem>
          <Divider className={cls.divider} />
          <MenuItem value={`AL`}>AL (Alabama)</MenuItem>
          <MenuItem value={`AK`}>AK (Alaska)</MenuItem>
          <MenuItem value={`AZ`}>AZ (Arizona)</MenuItem>
          <MenuItem value={`AR`}>AR (Arkansas)</MenuItem>
          <MenuItem value={`CA`}>CA (California)</MenuItem>
          <MenuItem value={`CO`}>CO (Colorado)</MenuItem>
          <MenuItem value={`CT`}>CT (Connecticut)</MenuItem>
          <MenuItem value={`DE`}>DE (Delaware)</MenuItem>
          <MenuItem value={`FL`}>FL (Florida)</MenuItem>
          <MenuItem value={`GA`}>GA (Georgia)</MenuItem>
          <MenuItem value={`HI`}>HI (Hawaii)</MenuItem>
          <MenuItem value={`ID`}>ID (Idaho)</MenuItem>
          <MenuItem value={`IL`}>IL (Illinois)</MenuItem>
          <MenuItem value={`IN`}>IN (Indiana)</MenuItem>
          <MenuItem value={`IA`}>IA (Iowa)</MenuItem>
          <MenuItem value={`KS`}>KS (Kansas)</MenuItem>
          <MenuItem value={`KY`}>KY (Kentucky)</MenuItem>
          <MenuItem value={`LA`}>LA (Louisiana)</MenuItem>
          <MenuItem value={`ME`}>ME (Maine)</MenuItem>
          <MenuItem value={`MD`}>MD (Maryland)</MenuItem>
          <MenuItem value={`MA`}>MA (Massachusetts)</MenuItem>
          <MenuItem value={`MI`}>MI (Michigan)</MenuItem>
          <MenuItem value={`MN`}>MN (Minnesota)</MenuItem>
          <MenuItem value={`MS`}>MS (Mississippi)</MenuItem>
          <MenuItem value={`MO`}>MO (Missouri)</MenuItem>
          <MenuItem value={`MT`}>MT (Montana)</MenuItem>
          <MenuItem value={`NE`}>NE (Nebraska)</MenuItem>
          <MenuItem value={`NV`}>NV (Nevada)</MenuItem>
          <MenuItem value={`NH`}>NH (New Hampshire)</MenuItem>
          <MenuItem value={`NJ`}>NJ (New Jersey)</MenuItem>
          <MenuItem value={`NM`}>NM (New Mexico)</MenuItem>
          <MenuItem value={`NY`}>NY (New York)</MenuItem>
          <MenuItem value={`NC`}>NC (North Carolina)</MenuItem>
          <MenuItem value={`ND`}>ND (North Dakota)</MenuItem>
          <MenuItem value={`OH`}>OH (Ohio)</MenuItem>
          <MenuItem value={`OK`}>OK (Oklahoma)</MenuItem>
          <MenuItem value={`OR`}>OR (Oregon)</MenuItem>
          <MenuItem value={`PA`}>PA (Pennsylvania)</MenuItem>
          <MenuItem value={`RI`}>RI (Rhode Island)</MenuItem>
          <MenuItem value={`SC`}>SC (South Carolina)</MenuItem>
          <MenuItem value={`SD`}>SD (South Dakota)</MenuItem>
          <MenuItem value={`TN`}>TN (Tennessee)</MenuItem>
          <MenuItem value={`TX`}>TX (Texas)</MenuItem>
          <MenuItem value={`UT`}>UT (Utah)</MenuItem>
          <MenuItem value={`VT`}>VT (Vermont)</MenuItem>
          <MenuItem value={`VA`}>VA (Virginia)</MenuItem>
          <MenuItem value={`WA`}>WA (Washington)</MenuItem>
          <MenuItem value={`WV`}>WV (West Virginia)</MenuItem>
          <MenuItem value={`WI`}>WI (Wisconsin)</MenuItem>
          <MenuItem value={`WY`}>WY (Wyoming)</MenuItem>
          <Divider className={cls.divider} />
          <MenuItem value={`DC`}>DC (District of Columbia)</MenuItem>
          <Divider className={cls.divider} />
          <MenuItem value={`AS`}>AS (American Samoa)</MenuItem>
          <MenuItem value={`GU`}>GU (Guam)</MenuItem>
          <MenuItem value={`MP`}>MP (Northern Mariana Islands)</MenuItem>
          <MenuItem value={`PR`}>PR (Puerto Rico)</MenuItem>
          <MenuItem value={`VI`}>VI (U.S. Virgin Islands)</MenuItem>
          <MenuItem value={`UM`}>UM (U.S. Minor Outlying Islands)</MenuItem>
        </TextField>

        <div className={cls.break} />

        <TextField
          fullWidth
          label="ZIP Code"
          placeholder="(Optional) Enter the ZIP code..."
          value={zip}
          onChange={handleInputChange(setZip)}
          margin="dense"
          variant="outlined"
          className={cls.input}
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ verticalAlign: "top" }} position="start">
                <Icon color="disabled" fontSize="small">dialpad</Icon>
              </InputAdornment>
            )
          }}
        />

        <div className={cls.break} />

        <TextField
          select
          fullWidth
          label="Country"
          placeholder="(Optional) Select a country..."
          value={country || `-`}
          onChange={handleInputChange(setCountry)}
          margin="dense"
          variant="outlined"
          className={cls.input}
          InputProps={{
            startAdornment: (
              <InputAdornment style={{ verticalAlign: "top" }} position="start">
                <Icon color="disabled" fontSize="small">public</Icon>
              </InputAdornment>
            )
          }}
        >
          <MenuItem value={`-`}><div className={cls.placeholder}>(Optional) Select a Country</div></MenuItem>
          <Divider className={cls.divider} />
          <MenuItem value={`USA`}>USA (United States)</MenuItem>
        </TextField>
      </ModalContent>

      <ModalFooter>
        <ModalAction onClick={() => handleSubmit()} disabled={street.length < 1}>Submit</ModalAction>
        <ModalAction onClick={() => handleClose()} color="secondary">Cancel</ModalAction>
      </ModalFooter>

    </Modal>
  </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '21px',
    fontWeight: 500,
  },
  input: {
    margin: 0,
  },
  placeholder: {
    color: theme.palette.text.disabled,
    fontWeight: 400,
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  bigBreak: {
    width: '100%',
    height: theme.spacing(3),
  },
}));