import React, { useState, useEffect } from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  group: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginBottom: '15px'
  }
}));

export default function BigButtonGroup({ onChange, preselect, children }) {
  const cls = useStyles()

  const [selected, setSelected] = useState(preselect)

  const handleChange = (id, title) => {
    setSelected(id)
    if (onChange) onChange(id, title)
  }

  return (<>
    <div className={cls.group}>
        {React.Children.map(children, (child, i) => {
            const count = React.Children.count(children)
            return React.cloneElement(child, {
                key: child.props.key,
                title: child.props.title,
                subtitle: child.props.subtitle,
                selected: (selected === child.props.id),
                onChange: handleChange,
                orientation: 'horizontal',
                disabled: child.props.disabled,
                width: Math.floor((.9 / count) * 100) + '%'
            })
        })}
    </div>
  </>)
}