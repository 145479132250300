import React from 'react';
import { makeStyles, Grid, Typography, Tooltip } from '@material-ui/core';

////////// TOOLS //////////
const capFirst = str => {
  if (str) return str.charAt(0).toUpperCase() + str.slice(1);
};
const checkNeg = num => {
  if (num > 0) return num;
  else return 0;
};

////////// COMPONENT //////////
export default function MoveDetailsAP(props) {
  const cls = useStyles();
  const { move } = props;

  const appayments = move.appayments || [];
  const paidPayments = appayments && appayments.length > 0 ? appayments.filter(ap => ap.status === `paid`) : [];

  const total =
    appayments && appayments.length > 0
      ? appayments.map(ap => ap.amount).reduce((total, current) => total + current)
      : 0;
  const paid =
    paidPayments && paidPayments.length > 0
      ? paidPayments.map(ap => ap.amount).reduce((total, current) => total + current)
      : 0;
  const due = total - paid;

  let lineItems =
    appayments && appayments.length > 0
      ? appayments.map(ap => {
          let name = `Unknown Key`;
          let notes = `N/A`;
          let amount = ap.amount;

          if (ap.type === `move pay`) {
            if (ap.move.move_type === `drive`) {
              name = ` Move Pay - Drive`;
              notes = `Standard pay rate for transporting the vehicle`;
            } else {
              name = ` Move Pay - Ride`;
              notes = `Standard pay rate for the ride back`;
            }
          } else if (ap.type === `accessorial`) {
            name = `Accessorial - ${capFirst(ap.accessorial.code)}`;
            notes = capFirst(ap.accessorial.notes);
          }

          if (ap.amount < 0) amount = `($${Math.abs(amount).toFixed(2)})`;
          else amount = `$${amount.toFixed(2)}`;

          return { name: name, notes: notes, amount: amount };
        })
      : [];

  lineItems = lineItems.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  return (
    <>
      {appayments && appayments.length > 0 ? (
        <>
          <div className={cls.paper}>
            <Grid container spacing={0} alignItems='flex-start' wrap='nowrap'>
              <Grid item xs>
                <Tooltip placement='top-start' title='Driver ID'>
                  <Typography className={cls.headTxtL}>
                    {move.driver_id ? `#${move.driver_id}` : `No Driver ID`}
                  </Typography>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip placement='top-end' title='Driver Name'>
                  <Typography className={cls.headTxtR}>{move.driver_name ? move.driver_name : `HopDriver`}</Typography>
                </Tooltip>
              </Grid>
            </Grid>
            <div className={cls.break} />

            {lineItems.length > 0 &&
              lineItems.map((item, i) => (
                <React.Fragment key={`payment-detail${i}`}>
                  <div className={cls.block}>
                    <Typography className={cls.detailNameTxt}>{item.name}</Typography>
                    <Grid container spacing={2} alignItems='flex-start' wrap='nowrap'>
                      <Grid item xs>
                        <Typography className={cls.detailNotesTxt}>{item.notes}</Typography>
                      </Grid>
                      <Grid item>
                        <Typography className={cls.detailAmountTxt}>{item.amount}</Typography>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={cls.lineBreak} />
                </React.Fragment>
              ))}

            {lineItems.length > 0 ? <div className={cls.break} /> : null}

            <Grid container spacing={0} alignItems='flex-start' wrap='nowrap'>
              <Grid item xs></Grid>
              <Grid item>
                <Typography className={cls.key}>Total:&nbsp;&nbsp;&nbsp;</Typography>
                <div className={cls.smallBreak} />
                <Typography className={cls.key}>Amount&nbsp;Paid:&nbsp;&nbsp;&nbsp;</Typography>
                <div className={cls.smallBreak} />
                <Typography className={cls.key}>Amount&nbsp;Due:&nbsp;&nbsp;&nbsp;</Typography>
              </Grid>
              <Grid item>
                <Typography className={cls.val}>${checkNeg(total).toFixed(2)}</Typography>
                <div className={cls.smallBreak} />
                <Typography className={cls.val}>${checkNeg(paid).toFixed(2)}</Typography>
                <div className={cls.smallBreak} />
                <Typography className={cls.val}>${checkNeg(due).toFixed(2)}</Typography>
              </Grid>
            </Grid>
          </div>
        </>
      ) : (
        <div className={cls.notFound}>
          <Typography className={cls.notFoundTxt}>NO AP RECORD FOUND</Typography>
        </div>
      )}
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  headTxtL: {
    lineHeight: 1.25,
    fontSize: '21px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
    },
    cursor: 'default',
  },
  headTxtR: {
    lineHeight: 1.25,
    fontSize: '16px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
    cursor: 'default',
  },
  detailNameTxt: {
    lineHeight: 1.25,
    fontSize: '16px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  detailNotesTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: '12px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  detailAmountTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    fontSize: '12px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  key: {
    lineHeight: 1,
    textAlign: 'right',
    fontSize: '16px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  val: {
    lineHeight: 1,
    textAlign: 'right',
    fontSize: '16px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  block: {
    display: 'block',
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    background: '#e0e0e0',
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
}));
