import React, { useContext, useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { GlobalContext } from '../../../global-context';
import axios from 'axios';
import {
  makeStyles, withStyles, Grid,
  GridItem,
  Typography, Tooltip,
  InputBase,
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';

import Divide from '../../reusable/Divide'
import { isNumberValue } from 'apollo-utilities';


const log = true;

export default function CustomerDetailsBranding(props) {
    const ctx = useContext(GlobalContext);
    const cls = useStyles();

    var { branded, config } = props.customer;
    var { editMode, customer, inMemoryCustomer, handleInMemoryCustomer } = props;

    const [ checkBranded, setCheckBranded ] = useState(branded);
    const [ obj, setObj ] = useState({
      name: config && config.branding && config.branding.name ? config.branding.name : null,
      primary_color: config && config.branding && config.branding.name ? config.branding.primary_color : null,
      secondary_color: config && config.branding && config.branding.secondary_color ? config.branding.secondary_color : null,
      logo_url: config && config.branding && config.branding.logo_url ? config.branding.logo_url : null,
    })

    useEffect(() => { 
      console.log("Memory", inMemoryCustomer)
      console.log("obj", obj)
      handleInMemoryCustomer(obj);
    }, [obj])

    const handleBranded = () => {
      setCheckBranded(!checkBranded)
      handleInMemoryCustomer({ branded: !checkBranded })
    }

    const handleChange = event => {
      setObj({...obj, [event.target.name]: event.target.value})
    };

    const handleLogoUpload = async files => {
      log && console.log('Handling logo upload: ', files)

      for (var i = 0; i < files.length; i++) {
        var file = files[0];
        var reader = await new FileReader();
        reader.addEventListener('loadend', async e => {
          log && console.log("File", file)
          try {
            const response = await axios({
              url: '/.netlify/functions/requestUploadUrl',
              method: 'POST',
              data: {
                prevLogo: config && config.branding && config.branding.logo_url ? config.branding.logo_url : null,
                file: {
                  name: file.name,
                  path: file.path,
                  size: file.size,
                  type: file.type
                },
                fileName: customer.id,
                fileType: file.type,
                bucketName: 'hopdrive-branding-logos'
              },
              headers: {
                'content-type': 'application/json',
              },
            });

            const { uploadURL } = response.data;
            log && console.log('Received an upload URL: ', uploadURL);
            console.log('file object: ', file)
            log && console.log(`About to upload size:${file.size} type:${file.type} to ${uploadURL}`);
            const uploadResponse = await axios.put(uploadURL, file, { headers: { 'Content-Type': 'image/jpeg' } });
            log && console.log('Response from S3 logo upload: ', uploadResponse);

            if (uploadResponse.status >= 200 && uploadResponse.status < 400) {
              log && console.log(`Successful logo upload. Updating customer config with URL...`)
              let logoUrl = '';
              try {
                logoUrl = uploadURL.split('?')[0];
                log && console.log(`Logo URL parsed from the S3 upload request URL: ${logoUrl}`);
                setObj({...obj, logo_url: logoUrl})
              } catch (error) {
                console.error(error);
              }
            } else {
              log && console.log(`Failed to upload the logo`);
              ctx.handleNotifications(true, `error`, `Failed to upload the logo`);
            }
          } catch (error) {
            console.error(`Unknown error getting an upload url: ${error.toString()}`);
            ctx.handleNotifications(true, `error`, `Failed to upload the logo`);
          }
        });
        reader.readAsArrayBuffer(file);
      }
      return false;
    };
  
    return (<>
      <div className={cls.main}>
          {ctx.width === `sm` || ctx.width === `xs` ? <div className={cls.bigBreak} /> : null}
          <Grid container spacing={2}>
              <Grid item md={12} sm={12} xs={12}>
                  <div className={ cls.paper }>
                      <FormGroup>
                        <FormControlLabel 
                          className={ cls.checkbox }
                          control={ <Checkbox checked={ checkBranded } onChange={ handleBranded } name="checkBranded" /> }
                          disabled={ !editMode }
                          label="Enable Customer Whitelabeling"
                        /> 
                        <Typography className={cls.sub}>
                          Allow customers to display their own unique branding to consumers. White-labeling is currently 
                          displayed via the concierge portal and related sms&nbsp;messaging.
                        </Typography>
                        { checkBranded ? ( !editMode ? <div className={ cls.container }>
                            {/* STATIC BRANDING CONFIGURATIONS */}
                            <div className={cls.listItem}>
                              <Grid item sm={4}><Typography className={cls.keyTxt}>Brand&nbsp;Name:&nbsp;&nbsp;</Typography></Grid>
                              <Typography className={cls.valTxt}>{ config && config.branding && config.branding.name ? config.branding.name : null }</Typography>
                            </div>
                            <div className={cls.listItem}>
                              <Grid item sm={4}><Typography className={cls.keyTxt}>Primary&nbsp;Color:&nbsp;&nbsp;</Typography></Grid>
                              <Typography className={cls.valTxt}>{ config && config.branding && config.branding.primary_color ? config.branding.primary_color : null }</Typography>
                            </div>
                            <div className={cls.listItem}>
                              <Grid item sm={4}><Typography className={cls.keyTxt}>Secondary&nbsp;Color:&nbsp;&nbsp;</Typography></Grid>
                              <Typography className={cls.valTxt}>{ config && config.branding && config.branding.secondary_color ? config.branding.secondary_color : null }</Typography>
                            </div>
                            <div className={cls.listItem}>
                              <Grid item sm={12}><Typography className={cls.keyTxt}>Logo:&nbsp;&nbsp;</Typography></Grid>
                              <Grid item sm={6} xs={12} className={ cls.logo }>
                                <img src={ config && config.branding && config.branding.logo_url ? config.branding.logo_url: null } width='100%' />
                              </Grid>
                            </div>
                        </div> : <div className={ cls.container }>
                            {/* EDITABLE BRANDING CONFIGURATIONS */}
                           <div className={cls.listItem}>
                              <Typography className={cls.keyTxt}>Brand&nbsp;Name:&nbsp;&nbsp;</Typography>
                              <InputBase 
                                className={cls.valTxt}
                                defaultValue = { config && config.branding && config.branding.name ? config.branding.name : null }
                                name='name'
                                onChange={ handleChange }
                                inputProps={{ className: cls.nakedValInput }}
                              />
                            </div>
                            <div className={cls.listItem}>
                              <Typography className={cls.keyTxt}>Primary&nbsp;Color:&nbsp;&nbsp;</Typography>
                              <Tooltip title="Hex-Code Requried (e.g. #f44232)">
                                <InputBase 
                                  className={cls.valTxt}
                                  defaultValue = { config && config.branding && config.branding.primary_color ? config.branding.primary_color : null }
                                  name='primary_color'
                                  onChange={ handleChange }
                                  inputProps={{ className: cls.nakedValInput }}
                                /> 
                              </Tooltip>                           
                            </div>
                            <div className={cls.listItem}>
                              <Typography className={cls.keyTxt}>Secondary&nbsp;Color:&nbsp;&nbsp;</Typography>
                              <Tooltip title="Hex-Code Required (e.g. #f44232)">
                                <InputBase 
                                  className={cls.valTxt}
                                  defaultValue = { config && config.branding && config.branding.secondary_color ? config.branding.secondary_color : null }
                                  name='secondary_color'
                                  onChange={ handleChange }
                                  inputProps={{ className: cls.nakedValInput }}
                                />  
                              </Tooltip>                            
                            </div>
                            <div className={cls.listItem}>
                              <Typography className={cls.keyTxt}>Logo:&nbsp;&nbsp;</Typography>                          
                            </div>
                            <div>
                            <Grid container spacing={3}>
                              <Grid item sm={6} xs={12}>
                                <img src={ obj.logo_url} width='100%' />
                              </Grid>
                              <Grid item sm={6} xs={12}>
                                <DropzoneArea
                                  useChipsForPreview={true}
                                  acceptedFiles={['image/*']}
                                  dropzoneText={'Drag and drop an image here or click'}
                                  maxFileSize={1000000}
                                  uploadMultiple={false}
                                  onChange={handleLogoUpload}
                                  filesLimit={1}
                                  showAlerts={false}
                                />
                              </Grid>
                            </Grid>
                            </div>
                        </div> ) : null}
                      </FormGroup>
                  </div>
              </Grid>
          </Grid>
      </div>
    </>)
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  container: {
    display: 'block'
  },
  checkbox: {
    display: 'block',
    marginBottom: '0.5rem'
  },
  main: {
    display: 'flex',
    flexWrap: 'nowrap',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  sub: {
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    marginBottom: '0.7rem',
    marginLeft: '0.1rem'
  },
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
    overflow: 'auto'
  },
  headTxt: {
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
    cursor: 'default',
  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 400,
    margin: '0.4em',
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  nakedValTxt: {
    width: '100%',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  nakedForm: {
    width: '100%',
    minHeight: '17px',
    maxHeight: '17px',
    textAlign: 'right',
  },
  nakedSelect: {
    fontSize: 14,
    fontWeight: 400,
    minHeight: '17px',
    maxHeight: '17px',
    padding: 0,
    background: '#00000008',
    textAlign: 'right',
  },
  nakedValInput: {
    width: '100%',
    minHeight: '17px',
    maxHeight: '17px',
    padding: 0,
    background: '#00000008',
    textAlign: 'right',
  },
  switch: {
    width: '100%',
  },
  bigBreak: {
    width: '100%',
    height: theme.spacing(3),
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: '#eee',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  logo: {
    maxWidth: '10rem',
    display: 'flex',
    justifyContent: 'end',
  }
}));