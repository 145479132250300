import React, { useState, useEffect } from "react";
import { Tooltip, Badge, Icon } from '@material-ui/core';

export default function PendingZeroIcon(props) {
  const [armoves, setArmoves] = useState(props.armoves);
  useEffect(() => { props.subscribeToMore() })
  useEffect(() => { setArmoves(props.armoves) }, [props.armoves])
  return (
    <Tooltip title={'Zero-Dollar ARMoves'}>
      <Badge badgeContent={armoves && armoves.length} color="error">
        <Icon>rate_review</Icon>
      </Badge>
    </Tooltip>
  )
}

