import React, { useState, useContext } from 'react';
import { GlobalContext } from "../../../global-context";
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableHead, TableRow, Checkbox, Typography, Select, IconButton, MenuItem, OutlinedInput, Button, Dialog, InputLabel, FormControl, Popover, Paper } from '@material-ui/core';
import FilterIcon from '@material-ui/icons/FilterList';
import MarkSelectedPaid from './MarkSelectedPaid';
import axios from "axios";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import EmailIcon from '@material-ui/icons/Email';
import moment from 'moment';
import AutoRefresh from '../../reusable/AutoRefresh';
const log = true

const useStyles = makeStyles(theme => ({
  root: {
    margin: "auto",
    width: '80%',
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
    overflowX: 'hidden',
  },
  form: {
    display: "block",
    float: "right",
    marginBottom: theme.spacing(3),
  },
  select: {
    minWidth: 200,
  },
  label: {
    position: "relative",
    left: "14px",
    top: "10px",
  },
  filterBtn: {
    display: "block",
    float: "right",
    position: "relative",
    top: "20px",
    zIndex: 999
  },
  header: {
    marginBottom: theme.spacing(3),
    fontSize: "150%",
    display: "block",
    float: "left",
  },
  text: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
  },
  button: {
    textTransform: "none !important",
    display: "block",
    float: "right",
    minWidth: "200px"
  },
  formBtn: {
    textTransform: "none !important",
    display: "block",
    float: "right",
    margin: theme.spacing(2),
  },
  wrapper: {
    margin: "auto",
    width: '80%',
    padding: theme.spacing(2),
    overflowX: 'hidden',
  },
  paper: {
    padding: theme.spacing(3),
    boxShadow: 'none'
  },
  picker: {
    margin: theme.spacing(2),
  },
  cursor: {
    '&:hover': {
      cursor: "pointer"
    },
  },
  accessorial: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}));

const DriverPayReviewPage = props => {
  const context = useContext(GlobalContext);
  const classes = useStyles();
  // Init state with all moves set to false (unchecked)
  const [selected, setSelected] = useState(Object.assign({}, { all: false }, props.appayments.reduce((o, i) => (o[i.id] = false, o), {})));
  const [showMarkPaid, setShowMarkPaid] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'filter-popover' : undefined;
  const [expanded, setExpanded] = useState({});
  const [startDate, setStartDate] = useState(props.startDate);
  const [endDate, setEndDate] = useState(props.endDate);

  function handleFilterOpen(event) {
    setAnchorEl(event.currentTarget);
  };
  function handleClose() {
    setAnchorEl(null);
  };

  const handleCheck = event => {
    if (!props.driver) {
      return;
    };
    const e = event.target.value;
    if (e === 'all') setSelected(Object.assign({}, { all: !selected[e] }, props.appayments.filter(o => o.driver_id === parseInt(props.driver)).reduce((o, i) => (o[i.id] = !selected[e], o), {})));
    else setSelected({ ...selected, [e]: !selected[e], all: false });
  };

  const handleSubtotal = () => {
    let selection = props.appayments.filter(o => selected[o.id])
    let subtotal = 0;
    selection.map(appayment => {
      subtotal = subtotal + appayment.amount;
    })
    return subtotal
  }

  function handleChange(event) {
    // Re-initialize selection when switching drivers
    setSelected(Object.assign({}, { all: false }, props.appayments.reduce((o, i) => (o[i.id] = false, o), {})))
    props.setDriver(event.target.value);
  }

  const handleAccessorials = move => {
    let accessorials = Array.from(move.accessorials || []);
    let accessorialsQty = 0;
    if (accessorials.length < 1) {
      return accessorialsQty;
    }
    else {
      accessorials.map(accessorial => {
        accessorialsQty = accessorialsQty + accessorial.ap_amount;
      })
      return accessorialsQty;
    }
  }

  const handleExpand = (move = null) => {
    if (!move) return
    else if (!move.accessorials) return
    else if (move.accessorials.length < 1) return
    else if (expanded[move.id]) setExpanded({ ...expanded, [move.id]: false })
    else setExpanded({ ...expanded, [move.id]: true })
  }

  const handleFilterFormAccept = event => {
    event.preventDefault();
    props.setStartDate(startDate)
    props.setEndDate(endDate)
    handleClose();
  }

  const handleFilterFormCancel = event => {
    event.preventDefault();
    setStartDate(props.startDate)
    setEndDate(props.endDate)
    handleClose();
  }
  //formats the selected appayments for the table in the driver email
  const formatPaymentData = (paymentsArr) => {
    return paymentsArr.map(o => {
      if (o.type === 'move pay') {
        return {
          move: o.move.id,
          pickupDate: moment(o.move.pickup_time).format("MM/DD/YYYY"),
          pickupTime: moment(o.move.pickup_time).format("LT"),
          type: `${o.type} (${o.move.move_type})`,
          description: o.move.lane.description,
          distance: `${o.move.lane.distance_miles} mi`,
          totalPay: o.amount.toFixed(2)
        }
      } else if (o.type === 'accessorial') {
        return {
          move: o.move.id,
          pickupDate: moment(o.move.pickup_time).format("MM/DD/YYYY"),
          pickupTime: moment(o.move.pickup_time).format("LT"),
          type: `${o.type} (${o.accessorial.code})`,
          description: o.accessorial.notes,
          distance: '-',
          totalPay: o.amount.toFixed(2)
        }
      } else {
        return {
          move: o.move.id,
          pickupDate: moment(o.move.pickup_time).format("MM/DD/YYYY"),
          pickupTime: moment(o.move.pickup_time).format("LT"),
          type: `${o.notes}`,
          description: o.notes,
          distance: '-',
          totalPay: o.amount.toFixed(2)
        }
      }
    })
  }
  //functions for finding min and max values in an array
  function getMinDate(dates) {
    return dates.reduce((minDate, date) => date < minDate ? date : minDate, dates[0]);
  }
  function getMaxDate(dates) {
    return dates.reduce((maxDate, date) => date > maxDate ? date : maxDate, dates[0]);
  }

  const sendEmail = async () => {
    //Construct values required by email template
    const paidAmount = Number(handleSubtotal()).toFixed(2);
    const selectedPaymentData = props.appayments.filter(o => selected[o.id])
    const selectedMoves = selectedPaymentData.filter(o => o.type === 'move pay');
    const moveCount = selectedMoves.length;
    const paymentDataArray = formatPaymentData(selectedPaymentData)
    const pickupDateArray = paymentDataArray.map(o => o.pickupDate)
    console.log("pickupDateArray", pickupDateArray)
    //The ternaries are to cover edge cases for when only one date, or only non-move charges are selected
    const emailStartDate = getMinDate(pickupDateArray)
    const emailEndDate = getMaxDate(pickupDateArray)
    log && console.log(paidAmount, moveCount, paymentDataArray, emailStartDate, emailEndDate)
    try {
      return await axios({
        method: `POST`,
        url: `/.netlify/functions/handleDriverPayEmail`,
        data: {
          driverId: props.driver,
          moveCount: moveCount,
          paidAmount: paidAmount,
          startDate: emailStartDate,
          endDate: emailEndDate,
          payments: paymentDataArray
        },
        headers: {
          'authorization': `Bearer ${context.userToken}`
        }
      }).then(res => {
        log && console.log(`Successfully sent email`, res);
        // context.handleNotifications(true, "success", "Successfully sent email")
        return true
      }).catch(err => {
        console.log(`Error occured when attempting to send email:`, err);
        context.handleNotifications(true, "error", "Failed to send email: " + err.toString())
        return false
      });
    }
    catch (err) {
      console.log(`Error occured when attempting to send email:`, err);
      context.handleNotifications(true, "error", "Error occured when attempting to send email: " + err.toString())
      return false
    }
  }

  return (
    <>
      <div className={classes.root}>
        <Typography className={classes.header} color="textSecondary">
          Unpaid AP records
          <AutoRefresh refetch={props.refetch} interval={60000} persistAs="driver_pay" />
        </Typography>
        <IconButton variant="outlined" className={classes.filterBtn} aria-label="Filter" onClick={handleFilterOpen}>
          <FilterIcon />
        </IconButton>
        {/* <IconButton variant="outlined" className={classes.filterBtn} aria-label="Filter" onClick={() => setEmailModalOpen(true)}>
          <EmailIcon />
        </IconButton> */}
        {/* <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleFilterFormCancel}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Paper className={classes.paper}>
            <form
            // onSubmit={handleFilterFormSubmit}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils} >
                <KeyboardDatePicker
                  className={classes.picker}
                  margin="normal"
                  id="startDate"
                  label="Start Date"
                  value={startDate}
                  onChange={(e) => setStartDate(moment(e))}
                  KeyboardButtonProps={{
                    'aria-label': 'change start date',
                  }}
                />
                <KeyboardDatePicker
                  className={classes.picker}
                  margin="normal"
                  id="endDate"
                  label="End Date"
                  value={endDate}
                  onChange={(e) => setEndDate(moment(e))}
                  KeyboardButtonProps={{
                    'aria-label': 'change end date',
                  }}
                />
              </MuiPickersUtilsProvider>
              <br />
              <Button
                onClick={handleFilterFormAccept}
                size="small"
                className={classes.formBtn}
                color="primary"
                variant="contained"
              >
                Accept
              </Button>
              <Button
                onClick={handleFilterFormCancel}
                size="small"
                className={classes.formBtn}
                color="default"
                variant="contained"
              >
                Close
          </Button>
            </form>
          </Paper>
        </Popover> */}
        <FormControl className={classes.form}>
          <InputLabel className={classes.label} htmlFor="driver-dropdown">
            Driver
          </InputLabel>
          <Select
            className={classes.select}
            autoWidth
            value={props.driver}
            onChange={handleChange}
            input={<OutlinedInput name="driver" labelWidth={44} placeholder="Driver" id="driver-dropdown" />}
          >
            {
              props.drivers.map(driver => <MenuItem key={`menuitem-driver-${driver.id}`} value={driver.id}> {driver.name} </MenuItem>)
            }
          </Select>
        </FormControl>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="none">
                <Checkbox
                  onChange={handleCheck}
                  value="all"
                  checked={selected["all"]}
                  indeterminate
                />
              </TableCell>
              <TableCell padding="none" align="left">Move</TableCell>
              <TableCell padding="none" align="left">Driver</TableCell>
              <TableCell padding="none" align="left">Pickup Date</TableCell>
              <TableCell padding="none" align="left">Pickup Time</TableCell>
              <TableCell padding="none" align="left">Type</TableCell>
              <TableCell padding="none" align="left">Description</TableCell>
              <TableCell padding="none" align="left">Distance</TableCell>
              {/* <TableCell padding="none" align="left">Move Pay</TableCell> */}
              {/* <TableCell padding="none" align="center">Accessorials</TableCell> */}
              <TableCell padding="none" align="right">Total Pay</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {
              Array.from(props.appayments.filter(o => o.driver_id === parseInt(props.driver)) || []).map(appayment => {
                // move.appayments.map(appayment => {
                return (
                  <React.Fragment key={`appayment-${appayment.id}-row`}>
                    <TableRow key={`table-row-${appayment.id}`}>
                      <TableCell padding="none" component="th" scope="row">
                        <Checkbox
                          onChange={handleCheck}
                          value={appayment.id}
                          checked={selected[appayment.id]}
                        />
                      </TableCell>
                      <TableCell padding="none" align="left">{appayment.move.id}</TableCell>
                      <TableCell padding="none" align="left">{appayment.move.driver_name}</TableCell>
                      <TableCell padding="none" align="left">{moment(appayment.move.pickup_time).format("MM/DD/YYYY")}</TableCell>
                      <TableCell padding="none" align="left">{moment(appayment.move.pickup_time).format("LT")}</TableCell>
                      <TableCell padding="none" align="left">{appayment.type === 'move pay' ? `${appayment.type} (${appayment.move.move_type})` : (appayment.type === 'accessorial') ? `${appayment.type} (${appayment.accessorial.code})` : appayment.notes}</TableCell>
                      <TableCell padding="none" align="left">{appayment.type === 'move pay' ? appayment.move.lane.description : (appayment.type === 'accessorial') ? appayment.accessorial.notes : appayment.notes}</TableCell>
                      <TableCell padding="none" align="right">{appayment.type === 'move pay' ? appayment.move.lane.distance_miles + ' mi' : null}</TableCell>
                      {/* <TableCell padding="none" align="left">${Number((appayment.move.move_type === 'drive') ? appayment.move.lane.driver_base_pay + appayment.move.lane.tolls : appayment.move.lane.driver_return_pay).toFixed(2)}</TableCell> */}
                      {/* <TableCell
                        padding="none"
                        className={move.accessorials.length > 0 ? classes.cursor : null}
                        style={{ color: move.accessorials ? (Number(handleAccessorials(move)) > 0 ? 'green' : 'red') : '' }}
                        align="center"
                        onClick={() => handleExpand(move)}
                      >
                        {handleAccessorials(move) === 0 || handleAccessorials(move) === "0.00" ? null : `$${handleAccessorials(move).toFixed(2)}`}
                      </TableCell> */}
                      <TableCell padding="none" align="right">${appayment.amount.toFixed(2)}</TableCell>

                    </TableRow>
                    {/* {expanded[move.id] && Array.from(move.accessorials).map((accessorial, i) => (
                        <TableRow key={`${accessorial.move_id}-accessorial-${accessorial.id}`}>
                          <TableCell padding="none" component="th" scope="row">
                            <div className={classes.accessorial} />
                          </TableCell>
                          <TableCell padding="none" align="left"></TableCell>
                          <TableCell padding="none" align="left"></TableCell>
                          <TableCell padding="none" align="left"></TableCell>
                          <TableCell padding="none" align="left"></TableCell>
                          <TableCell padding="none" align="left"></TableCell>
                          <TableCell padding="none" align="left"></TableCell>
                          <TableCell padding="none" align="left" style={{ fontSize: "80%" }}>></TableCell>
                          <TableCell padding="none" align="left">{accessorial.code}</TableCell>
                          <TableCell padding="none" align="center">${Number(accessorial.ap_amount).toFixed(2)}</TableCell>
                          <TableCell padding="none" align="right"></TableCell>
                        </TableRow>
                      ))
                      } */}
                  </React.Fragment>
                )
                // })
              })
            }

          </TableBody>
        </Table>
        <div style={{ display: "flex", float: "right" }}>
          <Typography className={classes.text} component="div" color="textSecondary" variant="h5">
            Selected Subtotal:
        </Typography>
          <Typography className={classes.text} component="div" variant="h6" color="textSecondary">{`$ ${handleSubtotal().toFixed(2)}`}</Typography>
        </div>
      </div >
      <div className={classes.wrapper}>
        <Button disabled={!Object.values(selected).includes(true)} color="primary" variant="contained" className={classes.button} onClick={() => setShowMarkPaid(true)}>Mark selected paid</Button>
      </div >
      <Dialog
        open={showMarkPaid}
      >
        {props.appayments && props.appayments.length > 0 &&
          <MarkSelectedPaid
            setShowMarkPaid={setShowMarkPaid}
            subtotal={Number(handleSubtotal().toFixed(2))}
            appayments={props.appayments.filter(o => selected[o.id])}
            setSelected={setSelected}
            sendEmail={sendEmail}
            refetch={props.refetch}
          />
        }
      </Dialog>
    </>
  )
}

export default DriverPayReviewPage;