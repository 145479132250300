import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { makeStyles } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker, TimePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from '../../reusable/ModalComponents';

////////// COMPONENT //////////
export default function PickupTimeEditModal(props) {
  const cls = useStyles();

  const { open, onClose, pickupTimeInput } = props;

  const [myDate, setMyDate] = useState(``);
  const [myTime, setMyTime] = useState(``);

  useEffect(() => {
    if (pickupTimeInput) {
      setMyDate(pickupTimeInput.pickupTime);
      setMyTime(pickupTimeInput.pickupTime);
    }
  }, [pickupTimeInput]);

  const handleDateChange = event => {
    setMyDate(moment(event).startOf(`day`).format());
  };

  const handleTimeChange = event => {
    setMyTime(moment(event).format());
  };

  const handleSubmit = async () => {
    let newPickupTime = `${moment.utc(moment(myDate)).format(`YYYY-MM-DD`)}T${moment
      .utc(moment(myTime))
      .format(`HH:mm`)}:00+00:00`;

    handleClose({
      pickupTime: newPickupTime,
    });
  };
  const handleClose = (output = null) => {
    if (onClose) onClose(output);
  };

  return (
    <>
      <Modal open={open}>
        <ModalHeader handleClose={handleClose}>Edit Pickup Time</ModalHeader>

        <ModalContent
          subtitle={`Edit the current move's pickup time stored in our database. Your changes will be made upon clicking 'Submit', then 'Save Changes'.`}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              autoOk
              fullWidth
              variant='inline'
              orientation='portrait'
              format='MM/dd/yyyy'
              label='Pickup Date (Your local date converted to UTC)'
              margin='none'
              inputVariant='outlined'
              value={myDate}
              onChange={handleDateChange}
            />

            <div className={cls.break} />

            <TimePicker
              autoOk
              fullWidth
              minutesStep={5}
              variant='inline'
              orientation='portrait'
              label='Pickup Time (Your local time converted to UTC)'
              margin='none'
              inputVariant='outlined'
              value={myTime}
              onChange={handleTimeChange}
            />
          </MuiPickersUtilsProvider>
        </ModalContent>

        <ModalFooter>
          <ModalAction onClick={() => handleSubmit()}>Submit</ModalAction>
          <ModalAction onClick={() => handleClose()} color='default'>
            Cancel
          </ModalAction>
        </ModalFooter>
      </Modal>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
}));
