import React, { useContext, useEffect } from "react";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { GlobalContext } from "../../../global-context";
import { Container, Button, FormControl, InputLabel, Select, MenuItem, TextField, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import JSONInput from 'react-json-editor-ajrm';
import locale from 'react-json-editor-ajrm/locale/en';
import Ajv from 'ajv';
import {BusinessRuleSchema} from './BusinessRuleSchema.js'
import {PromoArraySchema} from './PromoArraySchema.js'

var ajv = new Ajv(); // options can be passed, e.g. {allErrors: true}
var validate = ajv.compile(BusinessRuleSchema);
var validatePromo = ajv.compile(PromoArraySchema);

let log = false

const useStyles = makeStyles(theme => ({
    formControl: {
        marginLeft: "45px",
        width: "500px",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    root: {
        display: 'flex',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        },
    },
}));

export default function RulesForm(props) {
    const context = useContext(GlobalContext);
    const classes = useStyles();
    const [createForm, setCreateForm] = React.useState(false);
    const [jsonb, setJsonb] = React.useState({});
    const [id, setId] = React.useState(props.match.params.id);
    const [name, setName] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [templates, setTemplates] = React.useState('');
    const [type, setType] = React.useState(0);

    useEffect(() => {
        if (context && context.userIsAuthenticated()) {
            console.log('jsonb', jsonb)
            if (id) {
                getRule()
                log && console.log('view/edit')
            } else {
                getTemplates()
                log && console.log('create')
            }
        }
    }, [])
    const handleChange = obj => {
        // event && event.target && event.target.value ?
        if (log) console.log(obj, obj.jsObject)
        setJsonb(obj.jsObject)
    }
    const handleTemplateSelect = event => {
        setId(event.target.id)
        setJsonb(event.target.value);
    };

    const getRule = () => context.apolloClient.query({
        query: GET_RULE,
        variables: { id: props.match.params.id }
    }).then(res => {
        if (log) console.log("GET_RULE -- response.data:", res.data);
        console.log('query')
        let rule = res.data.businessrules[0]
        setId(rule.id)
        setName(rule.name)
        setType(rule.type)
        setDescription(rule.description)
        setJsonb(rule.jsonb)
    }).catch(err => {
        context.handleNotifications(true, "error", `Failed to retrieve customer data from Hasura: ${err.toString()}`);
    })

    const getTemplates = () => context.apolloClient.query({
        query: GET_TEMPLATES,
    }).then(res => {
        if (log) console.log("GET_RULE -- response.data:", res.data);
        console.log('query')
        var bizRules = res.data.businessrules; console.log('rules', bizRules)
        setCreateForm(true)
        setTemplates(bizRules)
    }).catch(err => {
        context.handleNotifications(true, "error", `Failed to retrieve customer data from Hasura: ${err.toString()}`);
    })

    const updateRule = async (id, name, description, ruleJson) => {
        let validJson = await validateJson(ruleJson)
        if (validJson) {
            context.apolloClient.mutate({
                mutation: UPDATE_RULE,
                variables: { id: id, name: name, description: description, jsonb: ruleJson, type: type },
            }).then(res => {
                if (res.data.update_businessrules) {
                    if (log) console.log("updateRule - UPDATE_RULE response (res.data):", res.data);
                    context.handleNotifications(true, "success", `Rule Updated`);
                } else {
                    context.handleNotifications(true, "error", "Failed to update rule. " + res.data.error);

                }
            }).catch(err => {
                console.log("hasura Error" + err.toString())
            })
        }
    }

    const saveRule = async (name, description, ruleJson, type) => {
        let validJson = await validateJson(ruleJson)
        console.log('valid json', validJson)
        if (validJson) {
            context.apolloClient.mutate({
                mutation: SAVE_RULE,
                variables: { name: name, description: description, jsonb: ruleJson, type: type },
            }).then(res => {
                if (res.data.insert_businessrules) {
                    if (log) console.log("saveRule - SAVE_RULE response (res.data):", res.data);
                    context.handleNotifications(true, "success", `Rule Saved`);
                    setName("")
                    setDescription("")
                    setJsonb({})
                } else {
                    context.handleNotifications(true, "error", "Failed to save rule. " + res.data.error);
                }
            }).catch(err => {
                console.log("hasura Error" + err.toString())
            })
        }
    }

    const validateJson = async (jsonObj) => {
        var valid = validate(jsonObj);
        if (valid) {
            return true
        } else {
            if(type === 2 && validatePromo(jsonObj)){
                return true
            }
            console.log("VALIDATE ERRORS", validate.errors[0].message)
            context.handleNotifications(true, "error", "rule "+validate.errors[0].message)
            return false
        }
    }

    return (
        <div className={classes.root}>
            <Container maxWidth="xl">
                {createForm ?
                    <Typography
                        style={{ marginLeft: "50px" }}
                        variant={'h6'}
                    >
                        Create Rule
                </Typography>
                    :
                    <Typography
                        style={{ marginLeft: "50px" }}
                        variant={'h6'}
                    >
                        Edit Rule
                </Typography>}
                <Grid container direction="row">
                    <Grid container item direction="column" md={6} sm={12}>
                        {/* only loads if adding a new move */}
                        { context && context.userIsAuthenticated() && (
                            <Query
                                query={GET_RULETYPES}
                                // onError={err => context.handleNotifications(true, "error", "Query failed to retrieve rule types: " + err.toString())}
                            >
                                {({ loading, error, data }) => {
                                    if (loading) return "Loading...";
                                    if (error) return `Error! ${error.message}`;
                                    if (data) {
                                        const typesArr = data.businessruletypes;
                                        return (
                                            <FormControl variant="outlined" className={classes.formControl}>
                                                <InputLabel>Type</InputLabel>
                                                <Select
                                                    // labelId="demo-simple-select-outlined-label"
                                                    id="demo-simple-select-outlined"
                                                    value={type}
                                                    onChange={(event) => setType(event.target.value)}
                                                    label="Type"
                                                >
                                                    {typesArr.map(type =>
                                                        <MenuItem key={type.id + 'typeMenuItem'} value={type.id}>{type.name}</MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        )
                                    }
                                }}
                            </Query>)
                        }
                        {/* add name and description fields */}
                        <TextField
                            name="nameField"
                            label="Name"
                            style={{
                                marginLeft: "45px",
                                width: "500px",
                            }}
                            value={name || ''}
                            onChange={(event) => setName(event.target.value)}
                            margin="normal"
                            variant="outlined"
                        />
                        <TextField
                            name="descriptionField"
                            label="Description"
                            style={{
                                marginLeft: "45px",
                                width: "500px",
                            }}
                            multiline
                            value={description || ''}
                            onChange={(event) => setDescription(event.target.value)}
                            margin="normal"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid container item direction="column" md={6} sm={12}>
                        {createForm && templates &&
                            (<FormControl variant="outlined" style={{ width: '500px', paddingBottom: '6px' }}>
                                <InputLabel>Template</InputLabel>
                                <Select
                                    // labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={jsonb}
                                    onChange={handleTemplateSelect}
                                    label="Template"
                                >
                                    <MenuItem value={{}}>
                                        <em>None</em>
                                    </MenuItem>
                                    {templates.map(br =>
                                        <MenuItem key={br.id + 'menuItem'} value={br.jsonb}>{br.name}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>)}
                        <JSONInput
                            key={id + 'JSONInput'}
                            id={id}
                            placeholder={jsonb}
                            onChange={handleChange}
                            // colors={darktheme}
                            locale={locale}
                            height='550px'
                            width='500px'
                        />
                    </Grid>
                    {createForm ?
                        <Button
                            id='saveRuleButton'
                            disabled={!(name && description && jsonb && type)}
                            onClick={() => { saveRule(name, description, jsonb, type) }}
                            color='primary'
                            size='medium'
                            variant='outlined'
                        >
                            Save Rule
                        </Button>
                        :
                        <Button
                            id='updateRuleButton'
                            disabled={!(id && name && description && jsonb && type)}
                            onClick={() => { updateRule(id, name, description, jsonb, type) }}
                            color='primary'
                            size='medium'
                            variant='outlined'
                        >
                            Update Rule
                        </Button>}
                </Grid>
            </Container>
        </div>
    );
}

const GET_RULE = gql`
  query lookup_rule($id: bigint!) {
    businessrules(where: { id: { _eq: $id } }, order_by: { id: asc }) {
        id
        type
        name
        description
        jsonb
    }
  }
`;

const UPDATE_RULE = gql`
mutation update_rule($id: bigint!, $name: String!, $description: String! $jsonb: jsonb!, $type: Int!) {
    __typename
    update_businessrules(where: {id: {_eq: $id}}, _set: {jsonb: $jsonb, name: $name, description: $description, type: $type}) {
      affected_rows
    }
  }
`;

const SAVE_RULE = gql`
mutation save_rule($name: String!, $description: String! $jsonb: jsonb!, $type: Int!) {
    __typename
    insert_businessrules(objects: {active: true, name: $name, description: $description, jsonb: $jsonb, type: $type}) {
      affected_rows
    }
  }
`;

const GET_RULETYPES = gql`
query get_ruletypes {
    businessruletypes(where:{active:{_eq:true}, id:{_neq:3}}) {
        id
        name
      }
    }
`;
const GET_TEMPLATES = gql`
query get_templates {
    businessrules(where: {active: {_eq: true}, type: {_eq: 3}}) {
        id
        name
        jsonb
        ruletype{
            id
            name
        }
    }
  }
`;