import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    padding: theme.spacing(2),
    border: `1px solid #ddd`,
    borderRadius: `8px`,
    margin: theme.spacing(1),
    background: '#f4f4f4',
    "&:hover": {
      border: `1px solid ${theme.palette.primary.light}`,
      backgroundColor: theme.palette.primary.light,
      color: '#fff',
    },
    transition: '0.1s',
    [theme.breakpoints.down('sm')]: {
      width: '160px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '132px',
    },
  },
  boxDisabled: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    padding: theme.spacing(2),
    border: `1px solid #ddd`,
    borderRadius: `8px`,
    margin: theme.spacing(1),
    background: '#f4f4f4',
    transition: '0.1s',
    cursor: 'not-allowed',
    [theme.breakpoints.down('sm')]: {
      width: '160px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '132px',
    },
    color: '#ddd',
  },
  boxActive: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'center',
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: `8px`,
    margin: theme.spacing(1),
    background: theme.palette.primary.main,
    color: '#fff',
    transition: '0.1s',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '160px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '132px',
    },
  },
  key: {
    marginBottom: theme.spacing(1.25),
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1),
      fontSize: '21px',
    },
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(0.75),
      fontSize: '18px',
    },
  },
  val: {
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
}));

export default function BigButton({ id, title, subtitle, selected = false, disabled = false, orientation = 'horizontal', width = '', onChange }) {
  const cls = useStyles()

  const handleClick = () => {
    if (onChange) onChange(id, title)
  }

  return (<>
    <div className={selected ? cls.boxActive : disabled ? cls.boxDisabled : cls.box} style={{width: width}} onClick={!disabled ? handleClick : () => {}}>
        <Typography className={cls.key}>{title}</Typography>
        {subtitle &&
          <Typography className={cls.val}>{subtitle}</Typography>
        }
    </div>
  </>)
}