import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { GlobalContext } from "../../global-context";

const styles = theme => ({
  root: {
    display: 'flex',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  card: {
    maxWidth: 500,
  },
  media: {
    objectFit: "cover",
  },
  inlineTxt: {
    display: "inline-flex",
    marginLeft: "10px",
  },
});

function ProfilePage(props) {
  const { classes } = props;
  return (
    <GlobalContext.Consumer>
      {global => (
        <div className={classes.root}>
        <Grid container justify="center" alignItems="center">
          <Card className={classes.card}>
            {/* <CardActionArea> */}
              <CardMedia
                component="img"
                alt="User profile picture"
                className={classes.media}
                height="200"
                image={
                  global.userProfile.picture ||
                  "https://i.imgur.com/yTnNTDZ.jpg"
                }
                title="Contemplative Reptile"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {global.userProfile.name ||
                    global.userProfile.email ||
                    "HopDrive Dispatch User"}
                </Typography>
                  <Typography
                    color="textSecondary"
                    style={{position: "relative", top: "-18px"}}
                    // className={classes.inlineTxt}
                    variant="h6"
                    component="h6"
                  >
                    {!global.userIsAuthenticated()
                      ? "Unregistered"
                      : "HopDrive"}
                  </Typography>
                <Typography color="textSecondary">
                  Role:
                  <Typography className={classes.inlineTxt}>
                    {global.userProfile["https://hasura.io/jwt/claims"]
                      ? global.userProfile["https://hasura.io/jwt/claims"][
                          "x-hasura-default-role"
                        ]
                          .charAt(0)
                          .toUpperCase() +
                        global.userProfile["https://hasura.io/jwt/claims"][
                          "x-hasura-default-role"
                        ].slice(1)
                      : "Unregistered user"}
                  </Typography>
                </Typography>
                <Typography color="textSecondary">
                  Email:
                  <Typography className={classes.inlineTxt}>
                    <a
                      className="btn btn-link"
                      href={
                        global.userProfile.email
                          ? `mailto:${global.userProfile.email}`
                          : null
                      }
                    >
                      {global.userProfile.email || "No email on record"}
                    </a>
                  </Typography>
                </Typography>
              </CardContent>
            {/* </CardActionArea> */}
            <CardActions>
              <Button size="small" color="primary">
                {global.userIsAuthenticated() ? "Edit Profile" : "Register"}
              </Button>
            </CardActions>
          </Card>
        </Grid>
        </div>
      )}
    </GlobalContext.Consumer>
  );
}

ProfilePage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProfilePage);
