import React, { useContext, useState, useEffect } from 'react';
import { GlobalContext } from "../../../global-context";
import { makeStyles } from '@material-ui/core/styles';
import gql from "graphql-tag";
import DateFnsUtils from '@date-io/date-fns';
import { Grid, Select, MenuItem, OutlinedInput, FormControl, InputLabel, Typography} from '@material-ui/core';
import Loading from '../../utils/Loading';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

let log = false

export default function DriverScheduleHeader(props) {
    const cls = useStyles();
    const ctx = useContext(GlobalContext);

    const [regions, setRegions] = useState([])
    const [selectedRegionId, setSelectedRegionId] = useState('')

    const { selectedRegionObj, setSelectedRegionObj } = props

    useEffect(() => {
        if(regions.length < 1){
        ctx.apolloClient.query({
            query: GET_REGIONS
        }).then(res=>{
            setRegions(res.data.regions)
        })}
    }, [])

    // Match the id selected to a Tookan region obj and set to state
    const handleRegionSelect = event => {
        const id = event.target.value
        log && console.log(id, selectedRegionId)
        if (id === null || id === '' || !id) {
            setSelectedRegionId('')
        } else {
            const foundRegion = regions.find(o => o.id === id)
            setSelectedRegionId(id)
            log && console.log("FoundRegion", foundRegion, selectedRegionObj)
            setSelectedRegionObj(foundRegion)
        }
    }
    return (
        <div className={cls.root}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-evenly" alignItems="center" style={{ width: "100%" }}>
                <Typography className={cls.head}>Driver Schedule Manager</Typography>
                    <FormControl variant="outlined" className={cls.formControl}>
                        <InputLabel htmlFor="region-select-dropdown">
                            Region
                        </InputLabel>
                        {regions.length > 1 ?
                            <Select
                                className={cls.dropdown}
                                margin="none"
                                value={selectedRegionId}
                                onChange={handleRegionSelect}
                                input={<OutlinedInput labelWidth={44} name="region_id" id="region-select-dropdown" />}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {regions.map(region =>
                                    <MenuItem key={region.id + 'key'} value={region.id}>{region.name}</MenuItem>
                                )}
                            </Select>
                            :
                            <Loading />}
                    </FormControl>
                </Grid>
            </MuiPickersUtilsProvider>
        </div>
    )
}

DriverScheduleHeader.propTypes = {

}
let GET_REGIONS=gql`
    query getRegions {
        regions {
        accounting_class_num
        createdat
        description
        email
        id
        last_synced
        name
        region_id
        team_id
        updatedat
        }
    }`

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        paddingTop: theme.spacing(4),
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(3),
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: theme.spacing(2),
        },
    },
    head: {
        verticalAlign: "top",
        display: "inline-block",
        lineHeight: 1,
        fontSize: "24px",
        fontWeight: 600,
        [theme.breakpoints.down("sm")]: {
          fontSize: "21px",
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: "18px",
        },
      },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    dropdown: {
        minWidth: 200
    },
}))