import React, { useState, useContext } from 'react';
import DriverPayReviewPage from './driverPayReview/DriverPayReviewPage';
import moment from 'moment';
import gql from "graphql-tag";
import fragments from '../utils/graphQL/fragments';
import { Query } from 'react-apollo';
import Loading from '../utils/Loading';
import { GlobalContext } from '../../global-context';

const unpaidAppayments = gql`
  query unpaidAppayments{
    appayments(
      order_by: {id: desc},
      where: {
        status: {_eq: "unpaid"}
      }) {
      ...APPayment
      move {
        ...Move
      }
      accessorial {
        ...Accessorials
      }
    }
  }
  ${fragments.move}
  ${fragments.appayment}
  ${fragments.accessorials}
`;

const DriverPayReview = () => {
  const context = useContext(GlobalContext);
  // const [startDate, setStartDate] = useState(moment().startOf('week').subtract(1, 'week'));
  // const [endDate, setEndDate] = useState(moment().startOf('week').subtract(1, 'day'));
  const [driver, setDriver] = useState('');

  const handlePayableDrivers = appayments => appayments.filter((val, i, self) => self.map(appayment => appayment.driver_id).indexOf(val.driver_id) === i).map(appayment => { return { id: appayment.driver_id, name: appayment.move.driver_name } });

  return (
    // Run Query to fetch unpaid drivers here
    // Pass results as a prop to the Review page
    <>
      { context && context.userIsAuthenticated() && (
        <Query
          query={unpaidAppayments}
          // variables={{ startDate: moment(startDate).format(), endDate: moment(endDate).add(1, "day").format() }}
          //Was causing infinited reload on error
          // onError={err => context.handleNotifications(true, "error", "Query failed to retrieve moves data: " + err.toString())}
          >
          {({ refetch, ...result }) => {
            if (result.loading) return <Loading />;
            if (result.error) return `Error! ${result.error.message}`;
            return <DriverPayReviewPage
              appayments={Array.from(result.data.appayments || [])}
              refetch={refetch}
              // startDate={startDate}
              // endDate={endDate}
              // setStartDate={setStartDate}
              // setEndDate={setEndDate}
              drivers={handlePayableDrivers(Array.from(result.data.appayments || []))}
              driver={driver}
              setDriver={setDriver}
            />
          }}
        </Query>)
      }
    </>
  )
}

export default DriverPayReview;