import React from 'react';
import { GlobalContext } from '../../../../global-context';
import { makeStyles, Grid, Typography, Icon, IconButton, Collapse, Tooltip } from '@material-ui/core/';
import { TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@material-ui/lab/';

import moment from 'moment';

export default function MoveDetailsTimelineItem(props) {
  const { theme } = React.useContext(GlobalContext);
  const cls = useStyles();

  const { eventLog } = props;

  const [expanded, setExpanded] = React.useState(false);

  // Get the event log code to use
  const getLogCode = () => {
    if (eventLog && eventLog.actiontype && eventLog.actiontype.name) return eventLog.actiontype.name;
    else return null;
  };
  const logCode = getLogCode();

  // Get the event log name to render
  const getLogName = () => {
    if (logCode) {
      let strArray = logCode.split(`.`);
      strArray = strArray.map(item => item.charAt(0).toUpperCase() + item.slice(1));
      return strArray.join(` `);
    } else return `Unknown Log`;
  };
  const logName = getLogName();

  // Get the event log time to render
  const getLogTime = () => {
    if (eventLog && eventLog.event_time_utc) return moment.utc(eventLog.event_time_utc).format('LLLL');
    else return `Unknown Time`;
  };
  const logTime = getLogTime();

  // Get the event log role to render
  const getLogRole = () => {
    if (eventLog && eventLog.roletype && eventLog.roletype.name) return eventLog.roletype.name;
    else return `unknown`;
  };
  const logRole = getLogRole();

  // Get the event log icon to render
  const getLogIcon = () => {
    let icon = `info_outlined`;

    if (logCode && logCode.includes(`.created`)) icon = `add`;
    if (logCode && logCode.includes(`.updated`)) icon = `update`;
    if (logCode && logCode.includes(`.deleted`)) icon = `close`;
    if (logCode && logCode.includes(`.failed`)) icon = `error_outline`;

    return icon;
  };
  const logIcon = getLogIcon();

  // Get the event log color to use
  const getLogColor = () => {
    let color = theme.palette.text.primary;

    if (logCode && logCode.includes(`.created`)) color = theme.palette.info.main;
    if (logCode && logCode.includes(`move.created`)) color = theme.palette.success.main;
    if (logCode && logCode.includes(`.updated`)) color = theme.palette.text.disabled;
    if (logCode && logCode.includes(`.deleted`)) color = theme.palette.error.main;
    if (logCode && logCode.includes(`.failed`)) color = theme.palette.error.light;

    return color;
  };
  const logColor = getLogColor();

  // Get metadata from the event log
  const metadata = eventLog && eventLog.metadata ? eventLog.metadata : null;

  return (
    <>
      {eventLog ? (
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot className={cls.timelineDot} style={{ background: logColor }}>
              <Icon>{logIcon}</Icon>
            </TimelineDot>
            {logCode !== `move.created` ? <TimelineConnector /> : null}
          </TimelineSeparator>
          <TimelineContent>
            <div className={cls.paper} onClick={() => setExpanded(!expanded)}>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs>
                  <Typography className={cls.timeText}>
                    {logTime} - <strong>by {logRole}</strong>
                  </Typography>
                  <Typography className={cls.title}>{logName}</Typography>
                </Grid>
                {metadata ? (
                  <Grid item>
                    <Tooltip placement='top' title='View details'>
                      <IconButton className={cls.iconButton} onClick={() => setExpanded(!expanded)}>
                        <Icon>{expanded ? `expand_less` : `expand_more`}</Icon>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                ) : null}
              </Grid>

              {metadata ? (
                <Collapse in={expanded}>
                  <div className={cls.collapse}>
                    <pre className={cls.json}>{JSON.stringify(metadata, null, 2)}</pre>
                  </div>
                </Collapse>
              ) : null}
            </div>
          </TimelineContent>
        </TimelineItem>
      ) : null}
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
    '&:hover': {
      background: theme.palette.background.default,
    },
    cursor: 'pointer',
    transition: '0.15s',
  },
  timelineDot: {
    boxShadow: `none`,
  },
  title: {
    lineHeight: 1.25,
    fontSize: 18,
    fontWeight: 700,
  },
  timeText: {
    marginBottom: theme.spacing(0.5),
    lineHeight: 1.25,
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  iconButton: {
    padding: `8px !important`,
  },
  collapse: {
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    marginTop: theme.spacing(2),
    background: theme.palette.background.dark,
  },
  json: {
    padding: 0,
    margin: 0,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    color: theme.palette.text.primary,
  },
}));
