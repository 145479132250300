import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { makeStyles, Grid, Typography, Tooltip } from '@material-ui/core';

////////// COMPONENT //////////
export default function MoveDetailsLyft(props) {
  const cls = useStyles();
  const { move, lyft } = props;

  const [selected, setSelected] = useState(null);
  const [attempts, setAttempts] = useState(
    move && move.lyftrides.length > 0 && move.lyftrides[0].attempts.length > 0 ? move.lyftrides[0].attempts : []
  );

  useEffect(() => {
    if (move && move.lyftrides.length > 0 && move.lyftrides[0].attempts.length > 0)
      setAttempts(move.lyftrides[0].attempts);
    else setAttempts([]);
  }, [move]);

  useEffect(() => {
    if (attempts && attempts.length > 0) setSelected(attempts[0]);
    else setSelected(null);
  }, [attempts]);

  const handleActiveAttempt = attempt => {
    setSelected(attempt);
  };

  const getStatus = attempt => {
    if (attempt.status === `accepted`) return `Accepted`;
    else if (attempt.status === `canceled`) return `Cancelled`;
    else if (attempt.status === `droppedOff`) return `Dropped Off`;
    else if (attempt.status === `pending`) return `Pending`;
    else if (attempt.status === `pickedUp`) return `Picked Up`;
    else return attempt.status;
  };
  const getTime = attempt => {
    return moment(attempt.requested_at || attempt.createdat).format(`MM/DD/YYYY LT`);
  };
  const getCost = attempt => {
    if (attempt.status === `droppedOff` && attempt.ride_cost) return `$${attempt.ride_cost.toFixed(2)}`;
    else if (attempt.estimated_ride_cost) return `$${attempt.estimated_ride_cost.toFixed(2)}`;
    else return `-`;
  };
  const getDuration = attempt => {
    if (attempt.ride_duration) return `${(attempt.ride_duration / 60).toFixed(1)}\xa0min`;
    else return `-`;
  };
  const getDistance = attempt => {
    if (attempt.ride_distance) return `${attempt.ride_distance.toFixed(1)}\xa0mi`;
    else return `-`;
  };

  return (
    <>
      <div className={cls.main}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            {selected ? (
              <>
                <div className={cls.paper}>
                  <Grid container spacing={0} alignItems='flex-start' wrap='nowrap'>
                    <Grid item xs>
                      <Tooltip placement='top-start' title='Lyft Move ID'>
                        <Typography className={cls.headTxtL}>#{lyft.id}</Typography>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip placement='top-end' title='Time of Lyft Call'>
                        <Typography className={cls.headTxtR}>{getTime(selected)}</Typography>
                      </Tooltip>
                    </Grid>
                  </Grid>

                  <div className={cls.break} />

                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Status:&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>{getStatus(selected)}</Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Ride&nbsp;Cost:&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>{getCost(selected)}</Typography>
                      </div>
                    </Grid>

                    <div className={cls.hiddenLineBreak} />

                    <Grid item sm={6} xs={12}>
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Ride&nbsp;Duration:&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>{getDuration(selected)}</Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Ride&nbsp;Distance:&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>{getDistance(selected)}</Typography>
                      </div>
                    </Grid>
                  </Grid>
                </div>

                <div className={cls.break} />

                <div className={cls.paper}>
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Driver&nbsp;Name:&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>{selected.driver_first_name || `-`}</Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Driver&nbsp;Rating:&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>{selected.driver_rating || `-`}</Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Driver&nbsp;Phone:&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>{selected.driver_phone || `-`}</Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>License&nbsp;Plate:&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>{selected.driver_vehicle_license_plate || `-`}</Typography>
                      </div>
                    </Grid>

                    <div className={cls.hiddenLineBreak} />

                    <Grid item sm={6} xs={12}>
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Make:&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>{selected.driver_vehicle_make || `-`}</Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Model:&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>{selected.driver_vehicle_model || `-`}</Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Year:&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>{selected.driver_vehicle_year || `-`}</Typography>
                      </div>
                      <div className={cls.lineBreak} />
                      <div className={cls.listItem}>
                        <Typography className={cls.keyTxt}>Color:&nbsp;&nbsp;</Typography>
                        <Typography className={cls.valTxt}>{selected.driver_vehicle_color || `-`}</Typography>
                      </div>
                    </Grid>
                  </Grid>
                </div>

                {lyft.movesByLyftTriggerId && lyft.movesByLyftTriggerId.length > 1 ? (
                  <>
                    <div className={cls.break} />
                    <div className={cls.paper}>
                      <Typography className={cls.sharedTxt}>Lyft&nbsp;Shared&nbsp;With:&nbsp;&nbsp;</Typography>
                      {lyft.movesByLyftTriggerId.map(share => (
                        <Typography className={cls.sharedItemTxt}>{share.driver_name || `HopDriver`}</Typography>
                      ))}
                    </div>
                  </>
                ) : null}
              </>
            ) : null}
          </Grid>

          <Grid item md={6} xs={12}>
            {attempts && attempts.length > 0 ? (
              <>
                <div className={cls.paper}>
                  <Typography className={cls.headTxtL}>Lyft Attempts</Typography>
                  {attempts.map(attempt => (
                    <div key={attempt.id} className={cls.attempt} onClick={() => handleActiveAttempt(attempt)}>
                      <Grid container spacing={1} wrap='nowrap'>
                        <Grid item>
                          <Typography className={cls.attemptIdTxt}>#{attempt.id}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography className={cls.attemptStatusTxt}>{getStatus(attempt)}</Typography>
                        </Grid>
                        <Grid item xs>
                          <Typography className={cls.attemptTimeTxt}>{getTime(attempt)}</Typography>
                        </Grid>
                        <Grid item>
                          <Typography className={cls.attemptCostTxt}>{getCost(attempt)}</Typography>
                        </Grid>
                      </Grid>
                    </div>
                  ))}
                </div>
              </>
            ) : null}
          </Grid>
        </Grid>
      </div>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  headTxtL: {
    lineHeight: 1.25,
    fontSize: 21,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
    cursor: 'default',
  },
  headTxtR: {
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
    cursor: 'default',
  },
  list: {},
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  attempt: {
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: '4px',
    marginTop: theme.spacing(1),
    background: theme.palette.action.hover,
    '&:hover': {
      background: theme.palette.primary.main,
      color: '#fff',
    },
    cursor: 'pointer',
    transition: '0.1s',
  },
  attemptIdTxt: {
    verticalAlign: 'top',
    textAlign: 'left',
    lineHeight: 1,
    fontSize: 14,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  attemptStatusTxt: {
    verticalAlign: 'top',
    textAlign: 'center',
    lineHeight: 1,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  attemptTimeTxt: {
    verticalAlign: 'top',
    textAlign: 'left',
    lineHeight: 1,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  attemptCostTxt: {
    verticalAlign: 'top',
    textAlign: 'right',
    lineHeight: 1,
    fontSize: 14,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 13,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
    },
  },
  sharedTxt: {
    float: 'left',
    verticalAlign: 'top',
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  sharedItemTxt: {
    verticalAlign: 'top',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: 16,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    marginTop: '6px',
    marginBottom: '8px',
    background: '#eee',
  },
  hiddenLineBreak: {
    display: 'none',
    width: '100%',
    height: '1px',
    marginTop: '-2px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    background: '#eee',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    },
  },
}));
