import React, { useContext } from "react";
import PromosTableView from './promos/PromosTableView.js'
import gql from "graphql-tag";
import { Query } from "react-apollo";
import { makeStyles } from '@material-ui/core/styles';
import Loading from '../utils/Loading';
import { Container } from '@material-ui/core';
import { GlobalContext } from "../../global-context";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
}));

export default function Promos(props) {
  const context = useContext(GlobalContext);
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
      { context && context.userIsAuthenticated() && (
          <Query
            query={GET_ALL_PROMOS}
            key={props.location.key}
            // variables={{ startDate: moment(startDate).format(), endDate: moment(endDate).add(1, "day").format() }}
            // onError={err => context.handleNotifications(true, "error", "Query failed to retrieve moves data: " + err.toString())}
            fetchPolicy="no-cache">
            {({ ...result }) => {
              if (result.loading) return <Loading />;
              if (result.error) return `Error! ${result.error.message}`;
              return (
                <PromosTableView promos={result.data.promos} />
              );
            }}
          </Query>)
        }
      </Container>
    </div>
  )
}

const GET_ALL_PROMOS = gql`
query get_promos {
    promos(where: {active: {_eq: true}}) {
        id
        customer{
          name
        }
        region{
          name
        }
        rule{
            id
            name
            description
            jsonb
        }
        expiration_date
    }
  }
`;