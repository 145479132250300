import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { GlobalContext } from '../../../global-context';

import {
  makeStyles,
  Grid,
  Typography,
  IconButton,
  Icon,
  Tooltip,
  TextField,
  InputAdornment,
  Button,
} from '@material-ui/core';
import gql from 'graphql-tag';
import fragments from '../../utils/graphQL/fragments';

import Loading from '../../utils/Loading';
import LocationSelect from './LocationSelect';
import LaneAddEditModal from './LaneAddEditModal';

const capFirst = str => {
  if (str) return str.charAt(0).toUpperCase() + str.slice(1);
};

////////// COMPONENT //////////
export default function MapLane(props) {
  const cls = useStyles();
  const ctx = useContext(GlobalContext);

  const { allLocations, lane, origin, destination, setLane, setOrigin, setDestination } = props;

  const {
    duration_sec,
    distance_miles,
    average_drive_speed_mph, // Route Info
    driver_time_pay,
    driver_drive_pay,
    driver_return_pay,
    driver_base_pay, // Driver Pay
    dealer_base_rate_type,
    dealer_base_rate,
    dealer_base_price,
    dealer_stranded_rate_type,
    dealer_stranded_rate,
    dealer_stranded_price, // Customer Price
    estimated_rideshare_return_cost,
    insurance_cost_per_mile,
    insurance_cost, // HD Cost
  } = lane;

  const [rateRules, setRateRules] = useState([]);
  const [payRateGroups, setPayRateGroups] = useState([]);

  const [payMinute, setPayMinute] = useState(0);
  const [payMile, setPayMile] = useState(0);
  const [driveDiscount, setDriveDiscount] = useState(`0.00`);
  const [returnDiscount, setReturnDiscount] = useState(`0.00`);
  const [timePay, setTimePay] = useState(`0.00`);
  const [drivePay, setDrivePay] = useState(`0.00`);
  const [returnPay, setReturnPay] = useState(`0.00`);
  const [baseDiscount, setBaseDiscount] = useState(`0.00`);
  const [strandedDiscount, setStrandedDiscount] = useState(`0.00`);
  const [tolls, setTolls] = useState(`0.00`);

  const [loading, setLoading] = useState(false);

  const handleInputChange = setHandler => event => {
    if (setHandler) setHandler(event.target.value);
  };
  const handleRecalc = async event => {
    const response = await axios({
      method: 'POST',
      url: '/.netlify/functions/calculate-lane',
      data: {
        type: 'forDriver',
        lane: lane,
        payMinute: Number(payMinute),
        payMile: Number(payMile),
        driveDiscount: Number(driveDiscount),
        returnDiscount: Number(returnDiscount),
        rake: null,
        tolls: Number(tolls),
      },
    });
    let newLane = response.data;
    // let newLane = await laneHelper.calculateDriverDetails(lane, Number(payMinute), Number(payMile), Number(driveDiscount), Number(returnDiscount), null, Number(tolls))
    setLane(newLane);
    setTimePay(newLane.driver_time_pay);
    setDrivePay(newLane.driver_drive_pay);
    setReturnPay(newLane.driver_return_pay);
    setTolls(newLane.tolls);
  };
  const handleSwap = (o, d) => {
    setOrigin(d);
    setDestination(o);
  };
  const getBand = laneDistance => {
    let currentRule = rateRules.find(rule => rule.distance_start < laneDistance && laneDistance < rule.distance_end);
    if (currentRule) return `${currentRule.distance_start}-\u2060${currentRule.distance_end}\xa0Miles`;
    else return `Unknown\xa0Band`;
  };
  const getPayRates = laneDistance => {
    var group = null;
    let customerGroup = payRateGroups.find(group => group.customer_id === lane.customer_id);
    //Get customer group if exists
    if (customerGroup) {
      console.log(`customergroup`);
      group = customerGroup;
    } else {
      var regionGroup = payRateGroups.find(group => group.region_id === origin.region_id);
      //Get region group if exists
      if (regionGroup) {
        console.log(`regiongroup`);
        group = regionGroup;
      } else {
        var defaultGroup = payRateGroups.find(group => group.name === `Company Defaults`);
        //Set pay group as default if no more specific pay group exists
        if (defaultGroup) {
          console.log(`defaultgroup`);
          group = defaultGroup;
        }
      }
    }
    //Find the specific payraterules that match the lane
    if (group) {
      console.log(`PAYRATE GROUP:`, group);
      let currentPayRule = group.payraterules.find(
        rule => rule.distance_start < laneDistance && laneDistance < rule.distance_end
      );
      if (!currentPayRule) {
        return [`N/A`, `N/A`];
      } else {
        return [
          `$${Number(currentPayRule.per_minute_rate).toFixed(2)}`,
          `$${Number(currentPayRule.per_mile_rate).toFixed(2)}`,
        ];
      }
    } else {
      return [`N/A`, `N/A`];
    }
  };

  // Create object to fill in the lane values
  let ln = {};

  ln.id = () => {
    if (lane && lane.id) return `Lane #${lane.id}`;
    else return `New Lane`;
  };
  ln.description = () => {
    if (lane && lane.description) return lane.description;
    else if (lane && lane.pickup && lane.delivery) return `${lane.pickup.name} to ${lane.delivery.name}`;
    else return `Unknown origin or destination`;
  };

  ln.totalTime = Number(duration_sec) / 60;
  ln.driveTime = Number(duration_sec) / 60;
  ln.distance = Number(distance_miles);
  ln.average_drive_speed_mph = Number(average_drive_speed_mph);

  ln.driver_time_pay = Number(timePay);
  ln.driver_drive_pay = Number(drivePay);
  ln.driver_return_pay = Number(returnPay);
  ln.totalBasePay = Number(driver_base_pay) - Number(driveDiscount);
  ln.totalStrandedPay = ln.totalBasePay + Number(returnPay) - Number(returnDiscount);
  ln.payRates = getPayRates(Number(distance_miles));

  ln.baseRateType = capFirst(dealer_base_rate_type);
  ln.baseMilageBand = getBand(Number(distance_miles));
  ln.baseRate = Number(dealer_base_rate);
  ln.totalBaseRate = Number(dealer_base_rate) - Number(baseDiscount);
  ln.basePrice = Number(dealer_base_price);
  ln.totalBasePrice = Number(dealer_base_price) - Number(baseDiscount) + Number(tolls);
  ln.strandedRateType = capFirst(dealer_stranded_rate_type);
  ln.strandedMilageBand = getBand(Number(distance_miles));
  ln.strandedRate = Number(dealer_stranded_rate);
  ln.totalStrandedRate = Number(dealer_stranded_rate) - Number(strandedDiscount);
  ln.strandedPrice = Number(dealer_stranded_price);
  ln.totalStrandedPrice = Number(dealer_stranded_price) - Number(strandedDiscount) + Number(tolls);

  ln.insuranceCostMile = Number(insurance_cost_per_mile);
  ln.totalInsuranceCost = Number(insurance_cost);
  ln.commission = 1.55 + 0.024 * Number(dealer_base_price);
  ln.returnRideEstimate = Number(estimated_rideshare_return_cost);
  ln.baseCost = ln.totalBasePay + ln.totalInsuranceCost + ln.commission;
  ln.totalBaseCost = ln.baseCost + Number(baseDiscount) + Number(tolls);
  ln.strandedCost =
    ln.totalStrandedPay + ln.totalInsuranceCost + ln.commission + Number(estimated_rideshare_return_cost);
  ln.totalStrandedCost = ln.strandedCost + Number(strandedDiscount) + Number(tolls);
  ln.totalBaseProfit = () => {
    if (dealer_base_rate_type === `flat`) return ln.basePrice - ln.baseCost - Number(baseDiscount) - Number(tolls);
    else if (dealer_base_rate_type === `per`)
      return ln.baseRate * ln.distance - ln.baseCost - Number(baseDiscount) - Number(tolls);
    else return 0;
  };
  ln.totalStrandedProfit = () => {
    if (dealer_stranded_rate_type === `flat`)
      return ln.strandedPrice - ln.strandedCost - Number(strandedDiscount) - Number(tolls);
    else if (dealer_stranded_rate_type === `per`)
      return ln.strandedRate * ln.distance - ln.strandedCost - Number(strandedDiscount) - Number(tolls);
    else return 0;
  };

  useEffect(() => {
    console.log('GETLANE Effect', lane, origin, destination);
    if (lane && origin && destination) {
      //get or create lane, get rateruls and pay rate rules
      console.log('GETLANE Effect 2');
      getLaneByLocationsPlusRateRules(ctx.apolloClient, origin, destination, origin.customer_id, origin.region_id);
    }
  }, [origin, destination]);

  useEffect(() => {
    if (lane) {
      setPayMinute(Number(lane.driver_pay_per_minute).toFixed(2) || (0).toFixed(2));
      setPayMile(Number(lane.driver_pay_per_mile).toFixed(2) || (0).toFixed(2));
      setDriveDiscount(Number(lane.driver_base_pay_discount).toFixed(2) || (0).toFixed(2));
      setReturnDiscount(Number(lane.driver_return_pay_discount).toFixed(2) || (0).toFixed(2));
      setTimePay(Number(lane.driver_time_pay).toFixed(2) || (0).toFixed(2));
      setDrivePay(Number(lane.driver_drive_pay).toFixed(2) || (0).toFixed(2));
      setReturnPay(Number(lane.driver_return_pay).toFixed(2) || (0).toFixed(2));
      setBaseDiscount(Number(lane.dealer_base_discount).toFixed(2) || (0).toFixed(2));
      setStrandedDiscount(Number(lane.dealer_stranded_discount).toFixed(2) || (0).toFixed(2));
      setTolls(Number(lane.tolls).toFixed(2) || (0).toFixed(2));
    }
  }, [lane]);

  const getLaneByLocationsPlusRateRules = async (client, pickup, delivery, customerId, regionId) => {
    console.log('GETLANE');
    setLoading(true);
    const res = await client.query({
      query: GET_LANES_AND_RATES,
      variables: { pickup_id: pickup.id, delivery_id: delivery.id, customer_id: customerId, region_id: regionId },
      fetchPolicy: 'network-only', //bypasses cache and checks db directly
    });
    console.log('RES', res);
    if (res.data.raterules) {
      console.log('RATE_RULES', res.data.raterules);
      setRateRules(res.data.raterules);
    } else {
      //handle error
    }
    if (res.data.payrategroups) {
      console.log('PAY_RATE_RULES', res.data.payrategroups);
      setPayRateGroups(res.data.payrategroups);
    } else {
      //handle error
    }
    if (res.data.lanes[0]) {
      //LANE EXISTS
      console.log('getLanebyLocation', res.data.lanes[0].id);
      setLane(res.data.lanes[0]);
    } else {
      //BUILD LANE BY LOCATION
      // netlify function to create lane
      //TDOD: ADD VALIDATION OF LANE OBJECT TO PREVENT CRASHES
      const response = await axios({
        method: 'POST',
        url: '/.netlify/functions/build-lanes',
        data: {
          type: 'fromLocations',
          customer_id: customerId,
          pickup: { name: pickup.name, address: pickup.address, id: pickup.id },
          delivery: { name: delivery.name, address: delivery.address, id: delivery.id },
        },
      });
      let newLanes = response.data;
      console.log('Newly built lanes: ', newLanes);
      setLane(newLanes[0]);
    }
    setLoading(false);
  };

  const getLaneObject = () => {
    const formLane = {
      distance_miles: ln.distance,
      average_drive_speed_mph: ln.average_drive_speed_mph,
      driver_time_pay: ln.driver_time_pay,
      driver_drive_pay: ln.driver_drive_pay,
      driver_return_pay: ln.driver_return_pay,
      dealer_base_rate: ln.baseRate,
      dealer_base_price: ln.basePrice,
      dealer_stranded_rate: ln.strandedRate,
      dealer_stranded_price: ln.strandedPrice,
      insurance_cost: ln.totalInsuranceCost,
      estimated_rideshare_return_cost: ln.returnRideEstimate,

      driver_pay_per_minute: payMinute,
      driver_pay_per_mile: payMile,
      driver_base_pay_discount: driveDiscount,
      driver_return_pay_discount: returnDiscount,
      dealer_base_discount: baseDiscount,
      dealer_stranded_discount: strandedDiscount,
      tolls: tolls,
    };

    const upsertableLane = Object.assign({}, lane, formLane);
    delete upsertableLane.customer;
    delete upsertableLane.delivery;
    delete upsertableLane.pickup;
    delete upsertableLane.__typename;

    return upsertableLane;
  };

  const [modal, setModal] = useState({ open: false });
  const handleLaneAddEditModalOpen = (input = null) => {
    console.log('INPUT', input);
    setModal({ open: true, input: input });
  };
  const handleLaneAddEditModalClose = (output = null) => {
    setModal({ ...modal, open: false });
    if (output) setLane(output);
  };

  return (
    <>
      <LaneAddEditModal open={modal.open} onClose={handleLaneAddEditModalClose} laneInput={modal.input} />

      <div className={cls.lane}>
        {/* Lane Selection Section */}
        <div className={cls.section} style={{ background: ctx.theme.palette.text.primary }}>
          <div className={cls.flex}>
            <LocationSelect
              allLocations={allLocations}
              loading={loading}
              location={origin}
              onChange={setOrigin}
              placeholder='Select origin...'
              icon='gps_not_fixed'
              iconTip='Origin Select'
            />
            <Tooltip placement='top' title='Swap Origin & Destination'>
              <IconButton onClick={() => handleSwap(origin, destination)} className={cls.laneBtn}>
                <Icon style={{ color: '#fff' }}>swap_vert</Icon>
              </IconButton>
            </Tooltip>
          </div>

          <div className={cls.break} />

          <div className={cls.flex}>
            <LocationSelect
              allLocations={allLocations}
              loading={loading}
              location={destination}
              onChange={setDestination}
              placeholder='Select destination...'
              icon='gps_fixed'
              iconTip='Destination Select'
            />
            <Tooltip placement='top' title='Clear Lane'>
              <IconButton className={cls.laneBtn}>
                <Icon style={{ color: '#fff' }}>replay</Icon>
              </IconButton>
            </Tooltip>
          </div>
        </div>

        {loading ? (
          <>
            {/* Loading Lane Section */}
            <div className={cls.section}>
              <div className={cls.bigBreak} />
              <div className={cls.bigBreak} />
              <Loading />
              <div className={cls.bigBreak} />
              <div className={cls.bigBreak} />
            </div>
          </>
        ) : (
          <>
            {/* Basic Info Section */}
            <div className={cls.section}>
              <Typography className={cls.descriptionTxt1}>{ln.id()}</Typography>
              <Typography className={cls.descriptionTxt2}>{ln.description()}</Typography>
              {/* <IconButton className={cls.historyBtn} onClick={() => { }}><Icon>history</Icon></IconButton> */}
            </div>

            <div className={cls.lineBreak} />

            {/* Route Info Section */}
            <div className={cls.section}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography className={cls.headerTxtL}>Route Information</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Tooltip placement='top' title='Time / Distance'>
                    <Typography className={cls.headerTxtR}>{`${ln.totalTime.toFixed(1)}\xa0min / ${ln.distance.toFixed(
                      1
                    )}\xa0mi`}</Typography>
                  </Tooltip>
                </Grid>

                <div className={cls.smallBreak} />

                <Grid item md={6} xs={12}>
                  <div className={cls.flex}>
                    <Typography className={cls.flexTxtL}>Total&nbsp;Time</Typography>
                    <div className={cls.flexLine} />
                    <Typography className={cls.flexTxtR}>{ln.totalTime.toFixed(1)}&nbsp;min</Typography>
                  </div>
                </Grid>
                <Grid item md={6} xs={12}>
                  <div className={cls.flex}>
                    <Typography className={cls.flexTxtL}>Drive&nbsp;Time</Typography>
                    <div className={cls.flexLine} />
                    <Typography className={cls.flexTxtR}>{ln.driveTime.toFixed(1)}&nbsp;min</Typography>
                  </div>
                </Grid>
                <Grid item md={6} xs={12}>
                  <div className={cls.flex}>
                    <Typography className={cls.flexTxtL}>Distance</Typography>
                    <div className={cls.flexLine} />
                    <Typography className={cls.flexTxtR}>{ln.distance.toFixed(1)}&nbsp;mi</Typography>
                  </div>
                </Grid>
                <Grid item md={6} xs={12}>
                  <div className={cls.flex}>
                    <Typography className={cls.flexTxtL}>Average&nbsp;Speed</Typography>
                    <div className={cls.flexLine} />
                    <Typography className={cls.flexTxtR}>{ln.average_drive_speed_mph.toFixed(1)}&nbsp;mph</Typography>
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className={cls.lineBreak} />

            {/* Driver Pay Section */}
            <div className={cls.section}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography className={cls.headerTxtL}>Driver Pay</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Tooltip placement='top' title='Total Base Pay / Total Stranded Pay'>
                    <Typography className={cls.headerTxtR}>{`$${ln.totalBasePay.toFixed(
                      2
                    )} / $${ln.totalStrandedPay.toFixed(2)}`}</Typography>
                  </Tooltip>
                </Grid>

                <div className={cls.smallBreak} />

                <Grid item sm={6} xs={12}>
                  <TextField
                    fullWidth
                    label='Pay/Min Override'
                    placeholder='Enter pay per minute...'
                    variant='outlined'
                    margin='dense'
                    value={payMinute}
                    onChange={handleInputChange(setPayMinute)}
                    className={cls.input}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                          <Icon color='disabled' fontSize='small'>
                            attach_money
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    fullWidth
                    label='Pay/Mile Override'
                    placeholder='Enter pay per mile...'
                    variant='outlined'
                    margin='dense'
                    value={payMile}
                    onChange={handleInputChange(setPayMile)}
                    className={cls.input}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                          <Icon color='disabled' fontSize='small'>
                            attach_money
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    fullWidth
                    label='Drive Discount'
                    placeholder='Enter drive discount...'
                    variant='outlined'
                    margin='dense'
                    value={driveDiscount}
                    onChange={handleInputChange(setDriveDiscount)}
                    className={cls.input}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                          <Icon color='disabled' fontSize='small'>
                            attach_money
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    fullWidth
                    label='Return Discount'
                    placeholder='Enter return discount...'
                    variant='outlined'
                    margin='dense'
                    value={returnDiscount}
                    onChange={handleInputChange(setReturnDiscount)}
                    className={cls.input}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                          <Icon color='disabled' fontSize='small'>
                            attach_money
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <div className={cls.smallBreak} />

                <Grid item xs={12}>
                  <Typography className={cls.subheaderTxt}>Factors</Typography>
                </Grid>

                <Grid item md={6} xs={12}>
                  <div className={cls.flex}>
                    <Typography className={cls.flexTxtL}>Standard&nbsp;Pay/Min</Typography>
                    <div className={cls.flexLine} />
                    <Typography className={cls.flexTxtR}>{ln.payRates[0]}</Typography>
                  </div>
                </Grid>
                <Grid item md={6} xs={12}>
                  <div className={cls.flex}>
                    <Typography className={cls.flexTxtL}>Standard&nbsp;Pay/Mile</Typography>
                    <div className={cls.flexLine} />
                    <Typography className={cls.flexTxtR}>{ln.payRates[1]}</Typography>
                  </div>
                </Grid>
                <Grid item md={6} xs={12}>
                  <div className={cls.flex}>
                    <Typography className={cls.flexTxtL}>Time&nbsp;Pay</Typography>
                    <div className={cls.flexLine} />
                    <Typography className={cls.flexTxtR}>${ln.driver_time_pay.toFixed(2)}</Typography>
                  </div>
                </Grid>
                <Grid item md={6} xs={12}>
                  <div className={cls.flex}>
                    <Typography className={cls.flexTxtL}>Distance&nbsp;Pay</Typography>
                    <div className={cls.flexLine} />
                    <Typography className={cls.flexTxtR}>${ln.driver_drive_pay.toFixed(2)}</Typography>
                  </div>
                </Grid>
                <Grid item md={6} xs={12}>
                  <div className={cls.flex}>
                    <Typography className={cls.flexTxtL}>Return&nbsp;Pay</Typography>
                    <div className={cls.flexLine} />
                    <Typography className={cls.flexTxtR}>${ln.driver_return_pay.toFixed(2)}</Typography>
                  </div>
                </Grid>

                <div className={cls.smallBreak} />

                <Grid item xs={12}>
                  <Typography className={cls.subheaderTxt}>Totals</Typography>
                </Grid>

                <Grid item md={6} xs={12}>
                  <div className={cls.flex}>
                    <Typography className={cls.flexTxtL}>Total&nbsp;Base&nbsp;Pay</Typography>
                    <div className={cls.flexLine} />
                    <Typography className={cls.flexTxtR}>${ln.totalBasePay.toFixed(2)}</Typography>
                  </div>
                </Grid>
                <Grid item md={6} xs={12}>
                  <div className={cls.flex}>
                    <Typography className={cls.flexTxtL}>Total&nbsp;Stranded&nbsp;Pay</Typography>
                    <div className={cls.flexLine} />
                    <Typography className={cls.flexTxtR}>${ln.totalStrandedPay.toFixed(2)}</Typography>
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className={cls.lineBreak} />

            {/* Customer Price Section */}
            <div className={cls.section}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography className={cls.headerTxtL}>Customer Price</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Tooltip placement='top' title='Total Base Price / Total Stranded Price'>
                    <Typography className={cls.headerTxtR}>{`$${ln.totalBasePrice.toFixed(
                      2
                    )} / $${ln.totalStrandedPrice.toFixed(2)}`}</Typography>
                  </Tooltip>
                </Grid>

                <div className={cls.smallBreak} />

                <Grid item sm={6} xs={12}>
                  <TextField
                    fullWidth
                    margin='dense'
                    variant='outlined'
                    label='Base Discount'
                    placeholder='Enter base discount...'
                    value={baseDiscount}
                    onChange={handleInputChange(setBaseDiscount)}
                    className={cls.input}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                          <Icon color='disabled' fontSize='small'>
                            attach_money
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    fullWidth
                    margin='dense'
                    variant='outlined'
                    label='Stranded Discount'
                    placeholder='Enter stranded discount...'
                    value={strandedDiscount}
                    onChange={handleInputChange(setStrandedDiscount)}
                    className={cls.input}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                          <Icon color='disabled' fontSize='small'>
                            attach_money
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    margin='dense'
                    variant='outlined'
                    label='Tolls'
                    placeholder='Enter tolls...'
                    value={tolls}
                    onChange={handleInputChange(setTolls)}
                    className={cls.input}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                          <Icon color='disabled' fontSize='small'>
                            attach_money
                          </Icon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <div className={cls.smallBreak} />

                <Grid item xs={12}>
                  <Typography className={cls.subheaderTxt}>Base</Typography>
                </Grid>

                <Grid item md={6} xs={12}>
                  <div className={cls.flex}>
                    <Typography className={cls.flexTxtL}>Rate&nbsp;Type</Typography>
                    <div className={cls.flexLine} />
                    <Typography className={cls.flexTxtR}>{ln.baseRateType}</Typography>
                  </div>
                </Grid>
                <Grid item md={6} xs={12}>
                  <div className={cls.flex}>
                    <Typography className={cls.flexTxtL}>Milage&nbsp;Band</Typography>
                    <div className={cls.flexLine} />
                    <Typography className={cls.flexTxtR}>{ln.baseMilageBand}</Typography>
                  </div>
                </Grid>
                <Grid item md={6} xs={12}>
                  <div className={cls.flex}>
                    <Typography className={cls.flexTxtL}>Total&nbsp;Base&nbsp;Rate</Typography>
                    <div className={cls.flexLine} />
                    <Typography className={cls.flexTxtR}>${ln.totalBaseRate.toFixed(2)}</Typography>
                  </div>
                </Grid>
                <Grid item md={6} xs={12}>
                  <div className={cls.flex}>
                    <Typography className={cls.flexTxtL}>Total&nbsp;Base&nbsp;Price</Typography>
                    <div className={cls.flexLine} />
                    <Typography className={cls.flexTxtR}>${ln.totalBasePrice.toFixed(2)}</Typography>
                  </div>
                </Grid>

                <div className={cls.smallBreak} />

                <Grid item xs={12}>
                  <Typography className={cls.subheaderTxt}>Stranded</Typography>
                </Grid>

                <Grid item md={6} xs={12}>
                  <div className={cls.flex}>
                    <Typography className={cls.flexTxtL}>Rate&nbsp;Type</Typography>
                    <div className={cls.flexLine} />
                    <Typography className={cls.flexTxtR}>{ln.strandedRateType}</Typography>
                  </div>
                </Grid>
                <Grid item md={6} xs={12}>
                  <div className={cls.flex}>
                    <Typography className={cls.flexTxtL}>Milage&nbsp;Band</Typography>
                    <div className={cls.flexLine} />
                    <Typography className={cls.flexTxtR}>{ln.strandedMilageBand}</Typography>
                  </div>
                </Grid>
                <Grid item md={6} xs={12}>
                  <div className={cls.flex}>
                    <Typography className={cls.flexTxtL}>Total&nbsp;Stranded&nbsp;Rate</Typography>
                    <div className={cls.flexLine} />
                    <Typography className={cls.flexTxtR}>${ln.totalStrandedRate.toFixed(2)}</Typography>
                  </div>
                </Grid>
                <Grid item md={6} xs={12}>
                  <div className={cls.flex}>
                    <Typography className={cls.flexTxtL}>Total&nbsp;Stranded&nbsp;Price</Typography>
                    <div className={cls.flexLine} />
                    <Typography className={cls.flexTxtR}>${ln.totalStrandedPrice.toFixed(2)}</Typography>
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className={cls.lineBreak} />

            {/* Profit/Cost Section */}
            <div className={cls.section}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography className={cls.headerTxtL}>Profit & Cost</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Tooltip placement='top' title='Total Base Profit / Total Stranded Profit'>
                    <Typography className={cls.headerTxtR}>{`$${ln.totalBaseProfit().toFixed(2)} / $${ln
                      .totalStrandedProfit()
                      .toFixed(2)}`}</Typography>
                  </Tooltip>
                </Grid>

                <div className={cls.smallBreak} />

                <Grid item xs={12}>
                  <Typography className={cls.subheaderTxt}>Factors</Typography>
                </Grid>

                <Grid item md={6} xs={12}>
                  <div className={cls.flex}>
                    <Typography className={cls.flexTxtL}>Insurance&nbsp;Cost/Mile</Typography>
                    <div className={cls.flexLine} />
                    <Typography className={cls.flexTxtR}>${ln.insuranceCostMile.toFixed(2)}</Typography>
                  </div>
                </Grid>
                <Grid item md={6} xs={12}>
                  <div className={cls.flex}>
                    <Typography className={cls.flexTxtL}>Total&nbsp;Insurance&nbsp;Cost</Typography>
                    <div className={cls.flexLine} />
                    <Typography className={cls.flexTxtR}>${ln.totalInsuranceCost.toFixed(2)}</Typography>
                  </div>
                </Grid>
                <Grid item md={6} xs={12}>
                  <div className={cls.flex}>
                    <Typography className={cls.flexTxtL}>Commission</Typography>
                    <div className={cls.flexLine} />
                    <Typography className={cls.flexTxtR}>${ln.commission.toFixed(2)}</Typography>
                  </div>
                </Grid>
                <Grid item md={6} xs={12}>
                  <div className={cls.flex}>
                    <Typography className={cls.flexTxtL}>Return&nbsp;Ride&nbsp;Estimate</Typography>
                    <div className={cls.flexLine} />
                    <Typography className={cls.flexTxtR}>${ln.returnRideEstimate.toFixed(2)}</Typography>
                  </div>
                </Grid>
                <Grid item md={6} xs={12}>
                  <div className={cls.flex}>
                    <Typography className={cls.flexTxtL}>Total&nbsp;Base&nbsp;Cost</Typography>
                    <div className={cls.flexLine} />
                    <Typography className={cls.flexTxtR}>${ln.totalBaseCost.toFixed(2)}</Typography>
                  </div>
                </Grid>
                <Grid item md={6} xs={12}>
                  <div className={cls.flex}>
                    <Typography className={cls.flexTxtL}>Total&nbsp;Stranded&nbsp;Cost</Typography>
                    <div className={cls.flexLine} />
                    <Typography className={cls.flexTxtR}>${ln.totalStrandedCost.toFixed(2)}</Typography>
                  </div>
                </Grid>

                <div className={cls.smallBreak} />

                <Grid item xs={12}>
                  <Typography className={cls.subheaderTxt}>Totals</Typography>
                </Grid>

                <Grid item md={6} xs={12}>
                  <div className={cls.flex}>
                    <Typography className={cls.flexTxtL}>Total&nbsp;Base&nbsp;Profit</Typography>
                    <div className={cls.flexLine} />
                    <Typography className={cls.flexTxtR}>${ln.totalBaseProfit().toFixed(2)}</Typography>
                  </div>
                </Grid>
                <Grid item md={6} xs={12}>
                  <div className={cls.flex}>
                    <Typography className={cls.flexTxtL}>Total&nbsp;Stranded&nbsp;Profit</Typography>
                    <div className={cls.flexLine} />
                    <Typography className={cls.flexTxtR}>${ln.totalStrandedProfit().toFixed(2)}</Typography>
                  </div>
                </Grid>
              </Grid>

              <div className={cls.bigBreak} />

              <Button
                onClick={async () => {
                  handleRecalc();
                }}
                fullWidth
                size='large'
                color='primary'
                variant='outlined'
              >
                Recalculate
              </Button>
              <div className={cls.break} />
              <Button
                onClick={() => handleLaneAddEditModalOpen(getLaneObject())}
                fullWidth
                size='large'
                color='primary'
                variant='outlined'
                className={cls.saveBtn}
              >
                {lane && lane.id ? `Save Changes` : `Create Lane`}
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  lane: {
    width: '100%',
  },
  section: {
    position: 'relative',
    width: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  descriptionTxt1: {
    marginBottom: theme.spacing(0.25),
    lineHeight: 1,
    fontSize: '21px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
    },
  },
  descriptionTxt2: {
    lineHeight: 1.2,
    fontSize: '18px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  historyBtn: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  headerTxtL: {
    verticalAlign: 'top',
    lineHeight: 1,
    fontSize: '18px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  headerTxtR: {
    verticalAlign: 'top',
    textAlign: 'right',
    lineHeight: 1,
    fontSize: '18px',
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  subheaderTxt: {
    verticalAlign: 'top',
    lineHeight: 1,
    fontSize: '14px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  input: {
    verticalAlign: 'top',
    margin: 0,
  },
  laneBtn: {
    verticalAlign: 'top',
    padding: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  saveBtn: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  flex: {
    display: 'flex',
    flexWrap: 'nowrap',
    verticalAlign: 'top',
    width: '100%',
  },
  flexLine: {
    width: '100%',
    height: '1px',
    marginTop: '5.5px',
    marginBottom: '5.5px',
    background: '#eee',
  },
  flexTxtL: {
    verticalAlign: 'top',
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary,
    lineHeight: 1,
    fontSize: '12px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  flexTxtR: {
    verticalAlign: 'top',
    marginLeft: theme.spacing(1),
    lineHeight: 1,
    fontSize: '12px',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },
  smallBreak: {
    width: '100%',
    height: theme.spacing(1),
  },
  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  bigBreak: {
    width: '100%',
    height: theme.spacing(3),
  },
  lineBreak: {
    width: '100%',
    height: '1px',
    background: '#ddd',
  },
}));

////////// GRAPHQL //////////
const GET_LANES_AND_RATES = gql`
  query get_lanes_and_rates($delivery_id: bigint, $pickup_id: bigint, $customer_id: bigint, $region_id: bigint) {
    lanes(where: { destination_location_id: { _eq: $delivery_id }, origin_location_id: { _eq: $pickup_id } }) {
      ...Lane
    }
    raterules(where: { customer_id: { _eq: $customer_id } }, order_by: { distance_end: asc }) {
      ...Raterules
    }
    payrategroups(
      where: {
        end_date: { _gte: "now()" }
        active: { _eq: true }
        _or: [
          { customer_id: { _eq: $customer_id } }
          { _and: [{ customer_id: { _is_null: true } }, { region_id: { _eq: $region_id } }] }
          { _and: [{ customer_id: { _is_null: true } }, { region_id: { _is_null: true } }] }
        ]
      }
    ) {
      name
      description
      begin_date
      end_date
      payraterules {
        id
        distance_start
        distance_end
        per_mile_rate
        per_minute_rate
      }
    }
  }
  ${fragments.lane}
  ${fragments.raterules}
`;
