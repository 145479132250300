import React from "react";
import moment from "moment";
import { withStyles } from '@material-ui/core/styles';
import { Grid, Divider, Typography, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(5),
  },
  grid: {
    width: '100%',
    padding: theme.spacing(2),
  },
  earningsHeader: {
    textAlign: 'right',
    fontWeight: "bold",
    fontSize: "180%"
  },
  reducedFont: {
    fontSize: "100%",
    '@media print': {
      fontSize: "90%"
    },
  },
  detailItem: {
    fontSize: "100%",
    '@media print': {
      fontSize: "90%"
    },
    fontWeight: "bold",
    textAlign: 'left',
  },
  detailValue: {
    fontSize: "100%",
    '@media print': {
      fontSize: "90%"
    },
    textAlign: 'left',
    float: 'right',
  },
  horLine: {
    margin: theme.spacing(0.3),
    backgroundColor: "black"
  },
  earningsTotal: {
    marginLeft: theme.spacing(5),
    fontSize: "225%",
    textAlign: 'left',
    fontWeight: "bold",
  },
  earningsSum: {
    marginRight: theme.spacing(5),
    fontSize: "225%",
    textAlign: 'left',
    float: 'right',
    fontWeight: "bold",
  },
  tableHeader: {
    fontSize: "100%",
    '@media print': {
      fontSize: "70%"
    },
    fontWeight: "bold",
    color: "black"
  },
  tableCell: {
    fontSize: "100%",
    '@media print': {
      fontSize: "60%"
    },
  },
  tableRow: {
    height: 'auto',
    overflow: 'auto',
  }
})

class DriverPayLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container direction="row" justify="space-between" spacing={4}>
          <Grid item xs={6}>
            <img
              src="https://socialautotransport.com/logo-264px.png"
              alt="logo"
            />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.earningsHeader}>EARNINGS</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography className={classes.reducedFont} style={{ fontWeight: "bold" }} variant="h6">FROM</Typography>
            <Typography className={classes.reducedFont}>1717 East Cary St</Typography>
            <Typography className={classes.reducedFont}>Suite 2118</Typography>
            <Typography className={classes.reducedFont}>Richmond, VA 23223</Typography>
            <Typography className={classes.reducedFont}>804-239-4505</Typography>
            <Typography className={classes.reducedFont}><a href="https://www.hopdrive.com">hopdrive.com</a></Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography display="inline" className={classes.detailItem}>Total Moves</Typography>
            <Typography display="inline" className={classes.detailValue}> {this.props.moves.length} </Typography>
            <br />
            <Typography display="inline" className={classes.detailItem}>Total Miles</Typography>
            <Typography display="inline" className={classes.detailValue}> {this.props.distanceSum.toFixed(2)} </Typography>
            <br />
            <Typography display="inline" className={classes.detailItem}>Total Time</Typography>
            <Typography display="inline" className={classes.detailValue}> {this.props.durationSumFormatted} </Typography>
            <br />
            <Typography display="inline" className={classes.detailItem}>Average Hourly Rate</Typography>
            <Typography display="inline" className={classes.detailValue}> ${this.props.payPerHour.toFixed(2)} per hour</Typography>
            <br />
            <Typography display="inline" className={classes.detailItem}>Average Per Mile Rate</Typography>
            <Typography display="inline" className={classes.detailValue}> ${this.props.payPerMile.toFixed(2)} per mile</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography display="inline" className={classes.detailItem}>Earnings Summary #</Typography>
            <Typography display="inline" className={classes.detailValue}> {this.props.earningsSummaryNumber} </Typography>
            <br />
            <Typography display="inline" className={classes.detailItem}>Earnings Date Range</Typography>
            <Typography display="inline" className={classes.detailValue}> {this.props.startDate.format("MM/DD/YYYY")}{" "}-{" "}{this.props.endDate.format("MM/DD/YYYY")} </Typography>
            <br />
            <Typography display="inline" className={classes.detailItem}>Driver Name</Typography>
            <Typography display="inline" className={classes.detailValue}> {this.props.driverName} </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider className={classes.horLine} />
            <Typography display="inline" className={classes.earningsTotal}>EARNINGS TOTAL</Typography>
            <Typography display="inline" className={classes.earningsSum}> ${this.props.paySum.toFixed(2)} </Typography>
            <Divider className={classes.horLine} />
          </Grid>
        </Grid>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableHeader}>SAT ID</TableCell>
              <TableCell className={classes.tableHeader} align="left">Pickup Date</TableCell>
              <TableCell className={classes.tableHeader} align="left">Pickup Time</TableCell>
              <TableCell className={classes.tableHeader} align="left">Lane</TableCell>
              <TableCell className={classes.tableHeader} align="left">Type</TableCell>
              <TableCell className={classes.tableHeader} align="left">Distance</TableCell>
              <TableCell className={classes.tableHeader} align="left">Drive Time</TableCell>
              <TableCell className={classes.tableHeader} align="left">Move Pay</TableCell>
              <TableCell className={classes.tableHeader} align="left">Tolls</TableCell>
              <TableCell className={classes.tableHeader} align="left">Adjustments</TableCell>
              <TableCell className={classes.tableHeader} align="left">Total Pay</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.moves.map((move, i) => {
              // let inspectionTime = move.lane.pickup_inspection_sec + move.lane.delivery_inspection_sec;
              let moveDateStr = "";
              let moveTimeStr = "";
              let accessorials = 0;
              try {
                if (move.accessorials) {
                  if (move.accessorials.length > 0) {
                    move.accessorials.map(accessorial => {
                      accessorials += Number(accessorial.ap_amount);
                    })
                  }
                }
              } catch (err) {
                accessorials = 0;
              }
              try {
                moveDateStr = moment(move.pickup_time.substr(0, 10), "YYYY-MM-DD").format("MM/DD/YYYY")
                moveTimeStr = moment(move.pickup_time, "YYYY-MM-DD hh:mm a").format("hh:mm a")
              } catch (e) { };
              return (
                <TableRow className={classes.tableRow} key={"sat-move-" + move.id}>
                  <TableCell className={classes.tableCell} component="th" scope="row">{move.id}</TableCell>
                  <TableCell className={classes.tableCell} align="left">{moveDateStr}</TableCell>
                  <TableCell className={classes.tableCell} align="left">{moveTimeStr}</TableCell>
                  <TableCell className={classes.tableCell} align="left">{move.lane.description}</TableCell>
                  <TableCell className={classes.tableCell} align="left">{move.move_type}</TableCell>
                  <TableCell className={classes.tableCell} align="left">{Math.round(move.lane.distance_miles, 2) + " mi"}</TableCell>
                  <TableCell className={classes.tableCell} align="left">{}</TableCell>
                  <TableCell className={classes.tableCell} align="left">${(move.status !== 'canceled' && move.cancel_status !== 'started' ? (move.move_type === 'drive') ? Number(move.lane.driver_base_pay) : Number(move.lane.driver_return_pay) : 0).toFixed(2)}</TableCell>
                  <TableCell className={classes.tableCell} align="left">{move.status !== 'canceled' && move.cancel_status !== 'started' ? (((move.move_type === 'drive') && (Number(move.lane.tolls) > 0)) ? '$' + Number(move.lane.tolls).toFixed(2) : '') : ''}</TableCell>
                  <TableCell className={classes.tableCell} align="left">{accessorials ? `$${Number(accessorials).toFixed(2)}` : ''}</TableCell>
                  <TableCell className={classes.tableCell} align="left">${Number(move.status !== 'canceled' && move.cancel_status !== 'started' ? ((move.move_type === 'drive') ? move.lane.driver_base_pay + move.lane.tolls + accessorials : move.lane.driver_return_pay + accessorials) : accessorials).toFixed(2)}</TableCell>
                </TableRow>
              )
            }
            )}
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default withStyles(styles)(DriverPayLayout);
