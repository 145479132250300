export const PromoArraySchema ={
    "type": "array",
    "title": "Promo Rule",
    "description": "Promos are definied based on a schema with configurable values.",
}
// [{
// 	"name": "maximum_mileage",
// 	"type": "number",
// 	"default": 20,
// 	"description": "maximum length in miles that move can be and still have promotion apply"
// }, {
// 	"name": "minimum_milage",
// 	"type": "number",
// 	"default": 5,
// 	"description": "minimum length in miles that move can be and still have promotion apply"
// }, {
// 	"name": "move_count",
// 	"type": "number",
// 	"default": 5,
// 	"description": "Number of moves that will recieve promotional discount"
// }, {
// 	"name": "move_threshold",
// 	"type": "number",
// 	"default": 20,
// 	"description": "Number of moves that need to be completed before promotion will be applied"
// }, {
// 	"name": "discount",
// 	"type": "number",
// 	"default": 0.1,
// 	"description": "Percentage that the move cost will be discounted"
// }, {
// 	"name": "consumer_pickup",
// 	"type": "boolean",
// 	"default": "true",
// 	"description": "If true, promo will only be applied to concierge moves"
// }]