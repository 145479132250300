import gql from "graphql-tag";
import fragments from './fragments';

let range = new Date();
range.setMonth(range.getMonth() - 3);
range = range.toUTCString();

const queries = {
  findMostRecentMoveByStock: vehicle_stock => gql`query {
    moves(where: {vehicle_stock: {_eq: ${vehicle_stock.trim()}}}, order_by: {delivery_successful: desc}, limit: 1) {
      ...Move
    }
  }
  ${fragments.move}
  `,
  //returns all moves with pickup_time within range OR if no pickup_time, with ready_by within range
  movesByRange: gql`
    query movesByDate(
      $startDate: timestamptz!
      $endDate: timestamptz!
      ) {
        moves(order_by: {id: desc},
          where: {active: {_eq: 1},
                move_type: {_ilike: "drive"},
            _or: [
              {pickup_time: {_gte: $startDate, _lte: $endDate}},
              {_and: [{ready_by: {_gte: $startDate, _lte: $endDate}},
                {pickup_time: {_is_null: true}}]},
            ]
        }
        ) {
          ...Move
          accessorials {
            ...Accessorials
          }
        }
      }
      ${fragments.move}
      ${fragments.accessorials}
    `,
  allMovesByRange: gql`
    query movesByDate(
      $startDate: timestamptz!
      $endDate: timestamptz!
      ) {
        moves(order_by: {id: desc},
          where: {active: {_eq: 1},
            _or: [
              {pickup_time: {_gte: $startDate, _lte: $endDate}},
              {_and: [{ready_by: {_gte: $startDate, _lte: $endDate}},
                {pickup_time: {_is_null: true}}]},
            ]
        }
        ) {
          ...Move
          accessorials {
            ...Accessorials
          }
        }
      }
      ${fragments.move}
      ${fragments.accessorials}
    `,
  allCustomerLocationsAndLanes: gql`
    query {
      favoritelocations {
        ...FavoriteLocation
      }
      favoritelanes {
        ...FavoriteLane
      }
      locations(
        order_by: {name: asc},
      ) {
       ...Location
      }
      lanes(
        order_by: {description: asc},
      ) {
        ...Lane
      }
    }
    ${fragments.favoriteLocation}
    ${fragments.favoriteLane}
    ${fragments.location}
    ${fragments.lane}
    `,
  favLanes: gql`
    query {
      favoritelanes {
        ...FavoriteLane
      }
    }
    ${fragments.favoriteLane}
  `,
  getMoveById: gql`
    query lookup_move($id: bigint!) {
      moves(where: {id: {_eq: $id}}, order_by: {delivery_successful: desc}, limit: 1) {
        ...Move
      }
    }
    ${fragments.move}
  `,
  getStockAndVin: gql`
    query {
      moves(
        distinct_on: vehicle_stock,
        where: {vehicle_stock: {_nin: ["0", ""]},
          _and: {vehicle_vin: {_nin: ["0", ""]},
          ready_by: {_gte: "${range}"}
        }},
        order_by: {
          vehicle_stock: asc
        }
      ) {
        vehicle_stock
        createdat
        ready_by
        vehicle_vin
      }
    }
  `,
  getAllMakes: gql`
    query {
      vehiclemakes {
        id
        name
      }
    }
  `,
  getModelsByMakeID: gql`
  query (
    $makeID: Int!
    ){		
    vehiclemodels(where: {make_id: {_eq:$makeID}}) {
      id
      name
      type
      model_year
    }
  }	
  `
  ,
  getModelsByMakeName: gql`
  query (
    $makeName: String!
    ){		
      vehiclemodels(where: {vehiclemake: {name: {_ilike: $makeName}}}) {
      id
      name
      type
      model_year
    }
  }	
  `
  ,
}

export default queries;