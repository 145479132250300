import React from 'react'
import { withStyles, Grid, Typography } from '@material-ui/core';



const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  text: {
    textAlign: 'center',
  },
  headers: {
    fontSize: '150%',
  },
  subheader: {
    fontSize: '115%'
  }
});

class VehicleReview extends React.Component {

  render() {
    const { classes, state } = this.props;

    return (
      <React.Fragment>
        {state.vehicles.length > 0 ? state.vehicles.map(vehicle => {
          return <React.Fragment key={vehicle.stock+'Fragment'}>
            <Grid item xs={12}  key={vehicle.stock+'FirstGridItem'}>
              <Typography key={vehicle.stock+'Description'} className={classes.subheader}> {vehicle.year} {vehicle.color} {vehicle.make} {vehicle.model} </Typography>
              <Typography key={vehicle.stock+'ReferenceLabel'} inline="true" color="textSecondary">Ref #: </Typography>
              <Typography key={vehicle.stock+'ReferenceText'} inline="true" color="textSecondary"> {vehicle.reference_num ? vehicle.reference_num : "none"} </Typography>
            </Grid>
            <Grid item xs={12} key={vehicle.stock+'SecondGridItem'}>
              <Typography key={vehicle.stock+'StockLabel'} inline="true" color="textSecondary">Stock: </Typography>
              <Typography key={vehicle.stock+'StockNumber'} inline="true" color="textSecondary"> {vehicle.stock} </Typography>
              <Typography key={vehicle.stock+'VinLabel'} inline="true" color="textSecondary">VIN: </Typography>
              <Typography key={vehicle.stock+'VinNumber'} inline="true" color="textSecondary"> {vehicle.vin ? vehicle.vin : 'none'} </Typography>
            </Grid>

            <Grid item xs={12} key={vehicle.stock+'ThirdGridItem'}>
              <Typography key={vehicle.stock+'InstructionsLabel'} inline="true" color="textSecondary">Special Instructions: </Typography>
              <Typography key={vehicle.stock+'InstructionsText'} inline="true" color="textSecondary"> {vehicle.instructions ? vehicle.instructions : "none"} </Typography>
            </Grid>
            <br />
          </React.Fragment>
        })
          : <React.Fragment />
        }
      </React.Fragment>
    )
  }
}

export default withStyles(styles, { withTheme: true })(VehicleReview);