import React, { useEffect, useState, useContext } from "react";
import moment from "moment";
import gql from "graphql-tag";
import { makeStyles } from "@material-ui/core/styles";
import { GlobalContext } from "../../../global-context";

let log = false;

//Driver name + Driver schedule for the week
export default function DriverScheduleRow(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();
  const { driver, className } = props;

  let scheduleObj = [
    { morning: false, afternoon: false, name: "sun", num: 0 },
    { morning: false, afternoon: false, name: "mon", num: 1 },
    { morning: false, afternoon: false, name: "tue", num: 2 },
    { morning: false, afternoon: false, name: "wed", num: 3 },
    { morning: false, afternoon: false, name: "thu", num: 4 },
    { morning: false, afternoon: false, name: "fri", num: 5 },
    { morning: false, afternoon: false, name: "sat", num: 6 },
  ];

  //One pay period is two weeks, and therefore two schedules
  const [weekOne, setWeekOne] = useState(scheduleObj);
  const [weekTwo, setWeekTwo] = useState(scheduleObj);
  const [scheduleIdOne, setScheduleIdOne] = useState(null);
  const [scheduleIdTwo, setScheduleIdTwo] = useState(null);

  const [driverSchedule, setDriverSchedule] = useState([]);

  //Set driver's schedule in state
  useEffect(() => {
    log && console.log("updating driver");
    setDriverSchedule(driver.schedule);
  }, [driver.schedule]);

  //Create week object from schedule that can be mapped as react components
  useEffect(() => {
    if (driverSchedule.length > 0) {
      log && console.log("updating schedule");
      driverSchedule.forEach((schedule) => {
        if (schedule.type === "even") {
          setScheduleIdOne(schedule.id);
          let weekOneSchedule = JSON.parse(JSON.stringify(scheduleObj));
          schedule.details.forEach((detail) => {
            if (detail.block_start_time === 7) weekOneSchedule[detail.day].morning = true;
            if (detail.block_start_time === 13) weekOneSchedule[detail.day].afternoon = true;
          });
          log && console.log("WeekOne", weekOneSchedule);
          setWeekOne(weekOneSchedule);
        }
        else if (schedule.type === "odd") {
          setScheduleIdTwo(schedule.id);
          let weekTwoSchedule = JSON.parse(JSON.stringify(scheduleObj));
          schedule.details.forEach((detail) => {
            if (detail.block_start_time === 7) weekTwoSchedule[detail.day].morning = true;
            if (detail.block_start_time === 13) weekTwoSchedule[detail.day].afternoon = true;
          });
          log && console.log("WeekTwo", weekTwoSchedule);
          setWeekTwo(weekTwoSchedule);
        }
      });
    }
  }, [driverSchedule, driver.schedule]);

  //Shiftblock represents either an AM or PM shift (one half of a workday)
  let shiftBlock = (day, shift, type) => {
    //Active controls the class of the shift block
    const [active, setActive] = useState(day[shift]);

    useEffect(() => {
      log && console.log("setActive");
      setActive(day[shift]);
    }, [day[shift]]);
    // useEffect(() => {
    //   console.log("Active Changed");
    // }, [active]);

    //Schedule a shift and set shiftblock to "active"
    let addAvailability = (shift, dayNum, setActiveCallback) => {
      let start = 7;
      let end = 13;
      if (shift === "afternoon") {
        start = 13;
        end = 21;
      }
      let scheduleId = type === "even" ? scheduleIdOne : scheduleIdTwo;
      log && console.log("add availability");
      ctx.apolloClient
        .mutate({
          mutation: ADD_SCHEDULE_DETAIL,
          variables: {
            start: start,
            end: end,
            day: dayNum,
            schedule_id: scheduleId,
          },
        })
        .then((res, err) => {
          if (err) {
            console.err(err);
          }
          if (res.data) {
            setActiveCallback(true)
            props.refetch();
            console.log("Add Detail - Hasura response (res.data):", res.data);
          } else {
            console.log("Database response", res);
            ctx.handleNotifications(true, "error", "Database error updating schedule");
            setActiveCallback(false); //reset button if mutation fails
          }
        })
        .catch((err) => {
          console.log("Update Detail Error:", err);
          ctx.handleNotifications(true, "error", "Error updating schedule");
          setActiveCallback(false); //reset button if mutation fails
        });
    };

    //Schedule a shift, set shiftblock to "active",
    // and create a new schedule for the driver for one week
    let addScheduleAndAvailability = (type, shift, dayNum, setActiveCallback) => {
      let start = 7;
      let end = 13;
      if (shift === "afternoon") {
        start = 13;
        end = 21;
      }
      log && console.log("add schedule");
      ctx.apolloClient
        .mutate({
          mutation: CREATE_SCHEDULE_AND_ADD_DETAIL,
          variables: {
            driver_id: driver.fleet_id,
            type: type,
            expiration_date: moment().startOf("week").add(2, "weeks").format("YYYY-MM-DDTHH:mm:ssZ"),
            detail: {
              data: {
                block_start_time: start,
                block_end_time: end,
                day: dayNum,
              },
              on_conflict: {
                constraint: "driverscheduledetails_driver_schedule_id_day_block_start_ti_key",
                update_columns:["updatedat"]
              }
            },
          },
        })
        .then((res, err) => {
          console.log('RES', res)
          if (err) {
            console.err(err);
          }
          if (res.data) {
            console.log("Create Schedule - Hasura response (res.data):", res.data.insert_driverschedules.returning[0]);
            let newScheduleId = res.data.insert_driverschedules.returning[0].id;
            console.log('newScheduleId', newScheduleId)
            // props.refetch(); //schedule data has been altered and must be refetched
            if (type === "even") {
              setScheduleIdOne(newScheduleId);
            } else {
              setScheduleIdTwo(newScheduleId);
            }
          } else {
            console.log("Database response", res);
            ctx.handleNotifications(true, "error", "Database error adding schedule");
            setActiveCallback(false); //reset button if mutation fails
          }
        })
        .catch((err) => {
          console.log("Add Schedule Error:", err);
          ctx.handleNotifications(true, "error", "Error adding schedule");
          setActiveCallback(false); //reset button if mutation fails
        });
    };

    //Remove a shift from the driver's schedule
    let removeAvailability = (shift, dayNum, setActiveCallback) => {
      let start = 7;
      let end = 13;
      if (shift === "afternoon") {
        start = 13;
        end = 21;
      }
      let scheduleId = type === "even" ? scheduleIdOne : scheduleIdTwo;
      log && console.log("remove availability");
      ctx.apolloClient
        .mutate({
          mutation: DELETE_SCHEDULE_DETAIL,
          variables: {
            start: start,
            end: end,
            day: dayNum,
            schedule_id: scheduleId,
          },
        })
        .then((res, err) => {
          if (err) {
            console.err(err);
          }
          if (res.data) {
            setActiveCallback(false)
            props.refetch();
            console.log("Delete Detail - Hasura response (res.data):", res.data);
          } else {
            console.log("Database response", res);
            ctx.handleNotifications(true, "error", "Database error updating schedule");
            setActiveCallback(true); //reset button if mutation fails
          }
        })
        .catch((err) => {
          console.log("Delete Detail Error:", err);
          ctx.handleNotifications(true, "error", "Error updating schedule");
          setActiveCallback(true); //reset button if mutation fails
        });
    };

    //Schedule or unschedule a shift and set shiftblock as active or inactive
    let handleClick = () => {
      if (active === true) {
        //request shift to be removed from driver's schedule
        removeAvailability(shift, day.num, setActive);
        setActive(false);
      } else {
        let scheduleId = type === "even" ? scheduleIdOne : scheduleIdTwo;
        if (scheduleId) {
          //request shift to be added to driver's schedule
          addAvailability(shift, day.num, setActive);
          setActive(true);
        } else {
          //request schedule to be added and shift to be added to that schedule
          addScheduleAndAvailability(type, shift, day.num, setActive);
          setActive(true);
        }
      }
    };

    //Render shiftblock component
    return (
      <div onClick={handleClick} className={active === true ? cls.shiftActive : cls.shiftInactive}>
        {shift === "morning" ? "AM" : "PM"}
      </div>
    );
  };

  //Render Row
  return (
    <div className={className}>
      <div className={cls.driver}>{driver.username}</div>
      {weekOne.map((day) => {
        return (
          <div key={"availabilityOne" + day.name} className={cls.day}>
            {shiftBlock(day, "morning", "even")}
            {shiftBlock(day, "afternoon", "even")}
          </div>
        );
      })}
      {weekTwo.map((day) => {
        return (
          <div key={"availabilityTwo" + day.name} className={cls.day}>
            {shiftBlock(day, "morning", "odd")}
            {shiftBlock(day, "afternoon", "odd")}
          </div>
        );
      })}
    </div>
  );
}

const DELETE_SCHEDULE_DETAIL = gql`
  mutation DELETE_DETAIL($start: Int!, $end: Int!, $day: Int!, $schedule_id: bigint!) {
    delete_driverscheduledetails(
      where: { block_start_time: { _eq: $start }, block_end_time: { _eq: $end }, day: { _eq: $day }, driver_schedule_id: { _eq: $schedule_id } }
    ) {
      affected_rows
      returning {
        block_end_time
        block_start_time
        day
        driver_schedule_id
      }
    }
  }
`;

const ADD_SCHEDULE_DETAIL = gql`
  mutation ADD_DETAIL($start: Int!, $end: Int!, $day: Int!, $schedule_id: bigint!) {
    insert_driverscheduledetails(
      objects: { block_end_time: $end, block_start_time: $start, createdat: "now()", day: $day, driver_schedule_id: $schedule_id, updatedat: "now()" }
      on_conflict: { constraint: driverscheduledetails_driver_schedule_id_day_block_start_ti_key, update_columns: [updatedat] }
    ) {
      affected_rows
      returning {
        block_end_time
        block_start_time
        createdat
        day
        driver_schedule_id
        id
        updatedat
        driverschedule{
          id
          type
          driver_id
        }
      }
    }
  }
`;

const CREATE_SCHEDULE_AND_ADD_DETAIL = gql`
  mutation CREATE_SCHEDULE_AND_ADD_DETAIL(
    $driver_id: Int!
    $expiration_date: timestamptz!
    $type: String!
    $detail: driverscheduledetails_arr_rel_insert_input!
  ) {
    insert_driverschedules(
      objects: { createdat: "now()", driver_id: $driver_id, expiration_date: $expiration_date, type: $type, updatedat: "now()", details: $detail }
      on_conflict: { constraint: driverschedules_driver_id_type_key, update_columns: [expiration_date, updatedat]}
      ) {
      affected_rows
      returning {
        id
        driver_id
        expiration_date
        type
        updatedat
        createdat
        details {
          block_end_time
          block_start_time
          day
          driver_schedule_id
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  driver: {
    backgroundColor: theme.palette.background.main,
    fontSize: 18,
    overflow: "hidden",
    textOverflow: "ellipsis",
    gridColumnStart: 1,
    gridColumnEnd: "span 1",
    placeSelf: "center",
    textAlign: "center",
    height: "60px",
    width: "100%",
    padding: theme.spacing(2),
  },
  shiftInactive: {
    height: "56px",
    width: "50%",
    textAlign: "center",
    padding: "18px 0",
    color: theme.palette.text.secondary,
    cursor: "pointer",
    // backgroundColor: theme.palette.background.main
  },
  shiftActive: {
    height: "56px",
    width: "50%",
    textAlign: "center",
    padding: "18px 0",
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    cursor: "pointer",
    // backgroundColor: theme.palette.background.main
  },
  day: {
    // height: "100%",
    width: "100%",
    gridColumnEnd: "span 1",
    placeSelf: "center",
    borderWidth: "2px",
    borderColor: theme.palette.background.main,
    borderStyle: "solid",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  lgView: {
    display: "inline-block",
  },
  smView: {
    display: "none",
  },
}));
