import React, { useState, useEffect } from "react";
import { Tooltip, IconButton, Badge } from '@material-ui/core';
import CancelNotifications from '@material-ui/icons/Notifications';



export default function PendingCancelIcon(props) {
  const [moves, setMoves] = useState(props.moves);
  useEffect(()=>{props.subscribeToMore()})
  useEffect(() => {setMoves(props.moves)}, [props.moves])
  return (
    <Tooltip title={'Moves with Pending Cancellation'}>
      <IconButton aria-label="go to plan view" color="inherit">
        <Badge badgeContent={moves && moves.length} color="error">
          <CancelNotifications />
        </Badge>
      </IconButton>
    </Tooltip>
  )
}

