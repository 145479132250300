import React, { Component } from "react"
import { Redirect } from 'react-router'
import { GlobalContext } from "../../global-context"
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Container } from "@material-ui/core";
// import Login from '../utils/Login'

const styles = theme => ({
    root: {
      display: 'flex',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
      },
    },
  });

class LoginPage extends Component {

    componentDidMount = () => {
        //Handle redirect to saved private route which triggered login
        if (localStorage.hasOwnProperty("privateRedirectPath")) {
            this.context.redirectPath = localStorage.getItem("privateRedirectPath")
            localStorage.removeItem("privateRedirectPath")
            console.log('LoginPage.js applying redirect path: ', this.context.redirectPath)
        }
    }
    render() {
        const { classes } = this.props;
        console.log('LoginPage.js redirect path from context is: ' + this.context.redirectPath)
        if (this.context.redirectPath != null) {
            //console.log('Redirecting to '+this.context.redirectPath)
            return (
                <div className={classes.root}>
                <Container maxWidth="lg">
                    <Redirect to={this.context.redirectPath} />
                </Container>
                </div>
            )
        } else {
            return (
                <div className={classes.root}>
                <Container maxWidth="lg">
                    <div>Waiting for redirect...</div>
                </Container>
                </div>
            )
        }
    }
}

LoginPage.contextType = GlobalContext

LoginPage.propTypes = {
    classes: PropTypes.object.isRequired,
};
  
export default withStyles(styles)(LoginPage);