import React from 'react';
import { DialogTitle, DialogContent, DialogContentText, Dialog, DialogActions, Button } from '@material-ui/core';

let log = false;

export default function ChangeConfirmationModal({ open = false, onAgree = () => {}, onDisagree = () => {} }) {
  return (
    <Dialog open={open} aria-labelledby='change-confirmation-modal'>
      <DialogTitle id='change-confirmation-modal'>Are you sure?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          If you agree all pending changes to the current rate rule group will be lost.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDisagree} color='primary'>
          Disagree
        </Button>
        <Button onClick={onAgree} color='primary' autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
}
