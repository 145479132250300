import React, { useContext, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  FormControl,
  DialogActions,
  Button,
  Typography,
  Grid,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';
import { GlobalContext } from '../../../global-context';
import axios from 'axios';
import { gql } from 'apollo-boost';
import { makeStyles } from '@material-ui/core/styles';
import fragments from '../../utils/graphQL/fragments';
import Loading from "../../utils/Loading";
import { Subscription } from 'react-apollo';
import GoogleStaticMapURL from '../../utils/GoogleStaticMapUrl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLyft } from '@fortawesome/free-brands-svg-icons';
import SmsIcon from '@material-ui/icons/Sms';
import CancelIcon from '@material-ui/icons/Cancel';
import moment from 'moment';

let log = true;


const getLyftRide = gql`
  subscription getLyftRides($id: bigint!) {
    lyftrides(where: {move_id: {_eq: $id}}) {
      ...Lyftride
    }
  }
  ${fragments.lyftrides}
`;

const INSERT_NEW_LYFT_RIDE = gql`
  mutation newLyftRide(
    $id: bigint!
    ) {
    insert_lyftrides(objects: {
      move_id: $id
    } on_conflict: {
      constraint: lyftrides_move_id_key, update_columns: last_attempt
    }) {
      returning {
        move_id
        last_attempt
      }
    }
  }
`;

const UPDATE_ACTIVE_ATTEMPT_FAILED = gql`
mutation updateActiveAttemptFailed($move_id: bigint!, $attempt_id: bigint!) {
  update_lyftrideattempts(where: {id: {_eq: $attempt_id}}, _set: {status: "failed"}) {
    affected_rows
    returning {
      id
      move_id
      status
    }
  }
  update_lyftrides(where: {move_id: {_eq: $move_id}}, _set: {active_attempt: null, updatedat: "now()"}) {
    affected_rows
    returning {
      active_attempt
    }
  }
}
`;

const useStyles = makeStyles(theme => ({
  noWrap: {
    whiteSpace: 'nowrap',
  },
  map: {
    width: '100%',
  },
  lyft: {
    color: '#ea0b8c',
    marginRight: theme.spacing(2),
    transform: "scale(2.5)"
  },
  icon: {
    marginRight: theme.spacing(0.5),
  },
  btn: {
    margin: theme.spacing(2),
    textTransform: 'none',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  table: {
    maxWidth: '800px',
  }
}));


export default function LyftRideModal(props) {

  const context = useContext(GlobalContext)
  const classes = useStyles();

  const [loading, setLoading] = useState(false)
  const [lyftride, setLyftride] = useState(null)
  const [drivers, setDrivers] = useState(initDrivers())
  const [showAttempts, setShowAttempts] = useState(false)
  const [cancelFailed, setCancelFailed] = useState(false)

  function initDrivers() {
    let driverObj = {};
    props.move.movesByLyftTriggerId.map(move => {
      Object.assign(driverObj, { [move.driver_id]: true })
    })
    return driverObj;
  }

  function staticMapUrl(lyftride) {
    return GoogleStaticMapURL.build({
      key: context.userProfile["https://api_keys.io/jwt/claims"]["GoogleMapsKey"],
      markers: [
        {
          icon: 'https://s3.ap-south-1.amazonaws.com/social-auto/acknowledgement_images/fkpH1532077213498-faviconsavein32x32.png',
          shadow: true,
          lat: lyftride.latitude && lyftride.longitude ? lyftride.latitude : props.move.lane.pickup.latitude,
          lon: lyftride.longitude && lyftride.latitude ? lyftride.longitude : props.move.lane.pickup.longitude,
        }
      ],
      center: 'auto',
      zoom: '18',
      scale: false,
      size: '350x200',
      maptype: 'roadmap',
      format: 'png',
      visual_refresh: true
    })
  }

  function handleActionButtons(status = null) {
    switch (status) {
      case null:
        return (
          <>
            <Button className={classes.btn} disabled={!Object.values(drivers).includes(true)} onClick={() => resendLyftText()}>
              <SmsIcon className={classes.icon} color={!Object.values(drivers).includes(true) ? "disabled" : "action"} />
              Resend Arrival Text
            </Button>
            <Button className={classes.btn} onClick={() => handleLyftCall()}>
              <FontAwesomeIcon className={classes.lyft} icon={faLyft} />
              Call Lyft Now
            </Button>
          </>
        )
      case 'canceled-redispatching':
        return (
          <>
            { 
              !cancelFailed ?
                <Button className={classes.btn} onClick={() => handleCancelLyft()}>
                  <CancelIcon className={classes.icon} color="error" />
                    Cancel Lyft
                </Button>
              : 
                <Button className={classes.btn} onClick={() => handleFailedLyft()}>
                  <CancelIcon className={classes.icon} color="error" />
                    Mark Lyft Failed
                </Button>
            }
          </>
        )
      case 'canceled':
        return (
          <>
            <Button className={classes.btn} disabled={!Object.values(drivers).includes(true)} onClick={() => resendLyftText()}>
              <SmsIcon className={classes.icon} color={!Object.values(drivers).includes(true) ? "disabled" : "action"} />
              Resend Arrival Text
            </Button>
            <Button className={classes.btn} onClick={() => handleLyftCall()}>
              <FontAwesomeIcon className={classes.lyft} icon={faLyft} />
              Call New Lyft Now
            </Button>
          </>
        )
      case 'failed':
        return (
          <>
            <Button className={classes.btn} disabled={!Object.values(drivers).includes(true)} onClick={() => resendLyftText()}>
              <SmsIcon className={classes.icon} color={!Object.values(drivers).includes(true) ? "disabled" : "action"} />
              Resend Arrival Text
            </Button>
            <Button className={classes.btn} onClick={() => handleLyftCall()}>
              <FontAwesomeIcon className={classes.lyft} icon={faLyft} />
              Call New Lyft Now
            </Button>
          </>
        )
      case 'accepted':
        return (
          <>
            { 
              !cancelFailed ?
                <Button className={classes.btn} onClick={() => handleCancelLyft()}>
                  <CancelIcon className={classes.icon} color="error" />
                    Cancel Lyft
                </Button>
              : 
                <Button className={classes.btn} onClick={() => handleFailedLyft()}>
                  <CancelIcon className={classes.icon} color="error" />
                    Mark Lyft Failed
                </Button>
            }
          </>
        )
      case 'pending':
        return (
          <>
            { 
              !cancelFailed ?
                <Button className={classes.btn} onClick={() => handleCancelLyft()}>
                  <CancelIcon className={classes.icon} color="error" />
                    Cancel Lyft
                </Button>
              : 
                <Button className={classes.btn} onClick={() => handleFailedLyft()}>
                  <CancelIcon className={classes.icon} color="error" />
                    Mark Lyft Failed
                </Button>
            }
          </>
        )
      case 'arrived':
        return (
          <>
            { 
              !cancelFailed ?
                <Button className={classes.btn} onClick={() => handleCancelLyft()}>
                  <CancelIcon className={classes.icon} color="error" />
                    Cancel Lyft
                </Button>
              : 
                <Button className={classes.btn} onClick={() => handleFailedLyft()}>
                  <CancelIcon className={classes.icon} color="error" />
                    Mark Lyft Failed
                </Button>
            }
          </>
        )
      default:
        return (
          <>
            <Button className={classes.btn} onClick={() => handleFailedLyft()}>
              <CancelIcon className={classes.icon} color="error" />
                Mark Lyft Failed
              </Button>
          </>
        )
    }
  }

  function handleSelect(event) {
    setDrivers({ ...drivers, [parseInt(event.target.value)]: !drivers[parseInt(event.target.value)] })
  }

  async function handleLyftCall() {
    setLoading(true);
    try {
      context.apolloClient.mutate({
        mutation: INSERT_NEW_LYFT_RIDE,
        variables: { id: props.move.lyft_trigger_id },
      })
        .then(res => {
          setLoading(false)
          // handleEventlog('update');
        })
        .catch(err => {
          console.log("Failed to insert Lyft ride:", err)
          context.handleNotifications(true, "error", `Failed to insert Lyft ride: ${err.toString()}`);
          // handleEventlog('updated.failed', `Failed to insert Lyft ride: ${err}`);
          setLoading(false)
        })
    } catch (err) {
      console.log("Failed to insert Lyft ride:", err)
      context.handleNotifications(true, "error", `Failed to insert Lyft ride: ${err.toString()}`);
      // handleEventlog('updated.failed', `Failed to insert Lyft ride: ${err.toString()}`);
      setLoading(false)
    }
  };

  async function handleCancelLyft() {
    if (window.confirm("Are you sure you want to cancel this Lyft? This action cannot be undone and may incur a cancellation fee.")) {
      try {
        await axios({
          method: 'POST',
          url: '/.netlify/functions/handleCancelLyft',
          data: {
            ride_id: lyftride.activeAttempt.ride_id,
          },
          headers: {
            'authorization': `Bearer ${context.userToken}`
          }
        }).then(res => {
          handleEventlog('update');
          log && console.log(`Canceled lyft move: ${res.data}`)
        })
      } catch (err) {
        console.error("Failed to cancel Lyft ride:", err);
        // handleEventlog('updated.failed', `Failed to cancel Lyft ride: ${err}`);
        context.handleNotifications(true, "error", "Failed to cancel Lyft: " + err.toString())
        setCancelFailed(true);
      }
    }
  }

  async function handleFailedLyft() {
    if (window.confirm("Are you sure this Lyft has failed to complete? This action cannot be undone and should only be used as a last resort.")) {
      console.log("move_id:", props.move.parent_move.id)
      context.apolloClient.mutate({
        mutation: UPDATE_ACTIVE_ATTEMPT_FAILED,
        variables: { 
          move_id: props.move.parent_move.id, 
          attempt_id: lyftride.activeAttempt.id 
        }
      }).then(res => {
        if (res.data.update_lyftrideattempts) {
          let failedAttempt = res.data.update_lyftrideattempts.returning[0];
          log && console.log(`>> Set Attempt #${failedAttempt.id} to status'${failedAttempt.status}'.`);
          // handleEventlog('update');
          setCancelFailed(false)
        }
      }).catch(err => {
        console.log(`Error setting lyft to failed:`, err);
        // handleEventlog('failed', `Error setting lyft to failed: ${err}`);
        context.handleNotifications(true, "error", "Error setting lyft to failed:" + err.message)
      });
    }
  }

  async function resendLyftText() {
    let driverArr = [];
    let move = props.move;
    for (let [key, value] of Object.entries(drivers)) {
      if (value) {
        let move = props.move.movesByLyftTriggerId.find(o => o.driver_id === parseInt(key)).parent_move.id;
        let phone = props.drivers.find(o => o.fleet_id === parseInt(key)).phone;
        driverArr.push({ driver: parseInt(key), move_id: move, phone: phone })
      }
    }

    console.log(driverArr)
    driverArr.forEach(async driver => {
      try {
        await axios({
          url: `/.netlify/functions/handleLyftURL`,
          method: 'post',
          data: {
            id: driver.move_id,
            phone: driver.phone,
            move: move,
            user: context.userProfile['https://hasura.io/jwt/claims']['x-hasura-user-email'],
            role: context.userProfile['https://hasura.io/jwt/claims']['x-hasura-default-role'],
          }
        }).then(res => {
          if (log) console.log("SMS sent to driver:", res)
          // context.handleNotifications(true, "success", `SMS sent`);
        })
      } catch (err) {
        if (log) console.log("SMS failed to send:", err)
        // handleEventlog('smsFailed', `Failed to send SMS containing Lyft URL : ${err}`)
        context.handleNotifications(true, "error", `Error sending SMS: ${err}`);
      }
    })
  }

  function handleSelectForm() {
    return (
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">Send arrival text to:</FormLabel>
        <FormGroup>
          {props.move.movesByLyftTriggerId.map(move => (
            <FormControlLabel
              key={`driver-select-${move.driver_id}`}
              control={<Checkbox color="primary" checked={drivers[move.driver_id]} onChange={handleSelect} value={move.driver_id} />}
              label={move.driver_name}
            />
          ))}
        </FormGroup>
        <FormHelperText>Each driver will receive their own SMS specific to their return ride</FormHelperText>
      </FormControl>
    )
  }

  async function handleEventlog(type, failure = null){
    let action; 
    const event = failure ? { failure } : { event: lyftride }; 
    if (type === 'update') action = await context.sdk.events.getActionByName('accessorial.updated')
    else if (type === 'smsFailed') action = await context.sdk.events.getActionByName('sms.failed')
    else if (type === 'updated.failed') action = await context.sdk.events.getActionByName('lyftride.updated.failed')

    let eventConfig = {
        action: action && action.data ? action.data[0].id : null,
        user: context.userProfile['https://hasura.io/jwt/claims']['x-hasura-user-email'],
        role: context.userProfile['https://hasura.io/jwt/claims']['x-hasura-default-role'],
        metadata: event,
        move_id: props.move.id,
        lane_id: props.move.lane.id,
        customer_id: props.move.customer_id,
        driver_id: props.move.driver_id,
    };

    let logRes = await context.sdk.events.buildAndCreate(eventConfig);
    console.log("logRes", logRes);
    return { statusCode: 200, body: `Successfully inserted eventlog ${logRes.id}` };
  }

  return (

    <Dialog
      open={props.open}
      onClose={() => {
        setCancelFailed(false);
        props.close(null)
      }}
      aria-labelledby="lyft-ride-modal"
      id="LyftRideDialog"
    >
      <DialogTitle id="lyft-ride-modal">Lyft Ride Management</DialogTitle>
      {loading ? <Loading /> :
        <Subscription
          subscription={getLyftRide}
          variables={{ id: props.move.parent_move.id }}
          onError={err => context.handleNotifications(true, "error", "Query failed to retrieve Lyft ride data: " + err.toString())}>
          {({ ...result }) => {
            if (result.loading) return <Loading />;
            if (result.error) return `Error! ${result.error.message}`;
            if (!result.data.lyftrides) return 'Error - did not find a related Lyft ride.';
            if (!lyftride) setLyftride(result.data.lyftrides[0]);
            else if (JSON.stringify(result.data.lyftrides[0]) !== JSON.stringify(lyftride)) setLyftride(result.data.lyftrides[0]);
            return lyftride && lyftride.activeAttempt ? (
              <>
                <DialogContent>
                  <Typography>Current status: {lyftride.activeAttempt.status.replace(/([A-Z])/g, ' $1').toUpperCase()}</Typography>
                  <br />
                  <Grid container direction="row" spacing={2} justify="center">
                    <Grid item xs={6}>
                      <DialogContentText>Pickup location ({lyftride.pickup_location_type}):</DialogContentText>
                      <img className={classes.map} src={staticMapUrl(lyftride)} alt={`pickup-coords-img`} />
                    </Grid>
                    <Grid item xs={6}>
                      <DialogContentText>{lyftride.activeAttempt.driver_first_name ? "Driver info:" : "No driver assigned"}</DialogContentText>
                      <DialogContentText>Name: {lyftride.activeAttempt.driver_first_name}</DialogContentText>
                      <DialogContentText>Number: {lyftride.activeAttempt.driver_phone}</DialogContentText>
                      <DialogContentText>Vehicle: {lyftride.activeAttempt.driver_vehicle_year} {lyftride.activeAttempt.driver_vehicle_color} {lyftride.activeAttempt.driver_vehicle_make} {lyftride.activeAttempt.driver_vehicle_model}</DialogContentText>
                      <DialogContentText>License #: {lyftride.activeAttempt.driver_vehicle_license_plate}</DialogContentText>
                      <DialogContentText>License State: {lyftride.activeAttempt.driver_vehicle_license_plate_state}</DialogContentText>
                    </Grid>
                    {lyftride.activeAttempt.status === 'canceled' || lyftride.activeAttempt.status === 'failed' ? <Grid item xs={12}>{handleSelectForm()}</Grid> : null}
                    {showAttempts ? (
                      <Grid item xs={12}>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              <TableCell>Ride ID</TableCell>
                              <TableCell align="left">Status</TableCell>
                              <TableCell align="left">Requested At</TableCell>
                              <TableCell align="left">Last Updated</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {lyftride.attempts.map(attempt => (
                              <TableRow key={attempt.ride_id}>
                                <TableCell component="th" scope="row">
                                  {attempt.ride_id}
                                </TableCell>
                                <TableCell align="left">{attempt.status}</TableCell>
                                <TableCell align="left">{moment(attempt.requested_at || attempt.createdat).format("DD/MM hh:mm A")}</TableCell>
                                <TableCell align="left">{moment(attempt.updatedat).format("DD/MM hh:mm A")}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Grid>
                    ) : null}
                  </Grid>
                  <DialogActions>
                    <Button disabled={false} onClick={() => setShowAttempts(!showAttempts)} className={classes.btn} color="default">
                      {showAttempts ? "Hide" : "Show"} all ride attempts
                    </Button>
                    {handleActionButtons(lyftride.activeAttempt.status)}
                    <Button className={classes.btn} onClick={props.close} color="primary">
                      Close
                  </Button>
                  </DialogActions>
                </DialogContent>
              </>
            )
              : (
                <>
                  <DialogContent>
                    <Typography>Current status: {lyftride ? `FAILED` : `NOT CALLED`}</Typography>
                    <br />
                    <DialogContentText>
                      {props.move.movesByLyftTriggerId.length > 1 ? (<>This ride is being shared by <b>{props.move.movesByLyftTriggerId.length}</b> HopDrivers. </>) : null}Resend the arrival text to one or more drivers by selecting them from the list below and clicking the 'Resend Arrival Text' button. The Lyft can be manually dispatched by clicking the 'Call Lyft Now' button. Manually calling the Lyft will dispatch it to the '{props.move.lane.pickup.name}' location. If the driver is not at this location, resend the arrival text and have them call the Lyft from their phone in order to use their current location. Calling the Lyft manually will call the ride for the driver of the parent Lyft trigger move ({props.move.lyft_trigger_move ? props.move.lyft_trigger_move.driver_name : `Unknown Driver`}).
                  </DialogContentText>
                    {handleSelectForm()}
                    <DialogActions>
                      {handleActionButtons()}
                      <Button onClick={props.close} color="primary">
                        Close
                   </Button>
                    </DialogActions>
                  </DialogContent>
                </>
              )
          }}
        </Subscription>
      }
    </Dialog >
  )

}