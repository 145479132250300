import React, { useContext, useState, useEffect } from 'react';
import { makeStyles, Container, Typography, Button, IconButton, Icon, Chip, Avatar } from '@material-ui/core';
import Loading from '../../utils/Loading';
import { GlobalContext } from '../../../global-context';
import { AccordianTable, AccordianRow, TableSort } from '../../reusable/AccordianTable';
import Modal from '../../reusable/Modal';
import axios from 'axios';
import moment from 'moment';
import CustomerUserForm from './CustomerUserForm';

////////// TOOLS //////////
const defaultOrder = `desc`;
const defaultOrderBy = `id`;

////////// COMPONENT //////////
export default function CustomerUsers(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const [search, setSearch] = useState(``);
  const [order, setOrder] = useState(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [tablePage, setTablePage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [expandedRowId, setExpandedRowId] = useState(0);

  const [modalRender, setModalRender] = useState(null);
  const [existingUser, setExistingUser] = useState(null);

  useEffect(() => {
    refetch();
  }, []);

  function refetch() {
    try {
      axios
        .post(`/.netlify/functions/auth0GetUsersByCustomer`, { customer_id: props.customer.id })
        .then(res => {
          setLoading(false);
          if (res && res.status === 200 && res.data && res.data.data) {
            setUsers(res.data.data);
          }
        })
        .catch(err => {
          setLoading(false);
          setUsers([]);
          console.error("Failed to retrieve customer's users:", err);
          ctx.handleNotifications(true, `error`, `Failed to retrieve customer's users`);
        });
    } catch (err) {
      setLoading(false);
      setUsers([]);
      console.error("Unexpected error while retrieving customer's users:", err);
      ctx.handleNotifications(true, `error`, `Failed to retrieve customer's users`);
    }
  }

  function setEmailAsVerified(user) {
    try {
      axios
        .post(`/.netlify/functions/auth0SetEmailVerified`, { user_id: user.id })
        .then(res => {
          if (res && res.status === 200) refetch();
          else {
            console.log('setEmailAsVerified res:', res);
            ctx.handleNotifications(true, `error`, `Failed to update user`);
          }
        })
        .catch(err => {
          console.error('Failed to update user:', err);
          ctx.handleNotifications(true, `error`, `Failed to update user`);
        });
    } catch (err) {
      console.error('Unexpected error while updating user:', err);
      ctx.handleNotifications(true, `error`, `Failed to update user`);
    }
  }

  function handleDeleteUser(user) {
    if (!window.confirm('Are you sure you want to delete this user? This action cannot be undone.')) return;
    else
      try {
        axios
          .post(`/.netlify/functions/auth0DeleteUser`, { user_id: user.id })
          .then(res => {
            if (res && res.status === 200) refetch();
            else {
              console.log('deleteUser res:', res);
              ctx.handleNotifications(true, `error`, `Failed to delete user`);
            }
          })
          .catch(err => {
            console.error('Failed to delete user:', err);
            ctx.handleNotifications(true, `error`, `Failed to delete user`);
          });
      } catch (err) {
        console.error('Unexpected error while deleting user:', err);
        ctx.handleNotifications(true, `error`, `Failed to delete user`);
      }
  }

  function handleUserForm(user = null) {
    setExistingUser(user);
    setModalRender(true);
  }

  const applyFilters = rows => {
    if (!search || search.length < 1) return rows;
    else {
      return rows.filter(
        row =>
          (row.name && row.name.toLocaleLowerCase().includes(search)) ||
          (row.id && (row.id + ``).toLocaleLowerCase().includes(search))
      );
    }
  };

  const getUserTableActions = () => {
    return [{ name: 'add-user', label: `Add\xa0User`, handler: () => setModalRender(true) }];
  };

  const buildContextMenuActions = user => {
    return [
      { name: 'edit-user', label: `Edit\xa0User`, handler: () => handleUserForm(user) },
      {
        name: 'update-user-email',
        label: `Set\xa0Email\xa0as\xa0Verified`,
        handler: () => setEmailAsVerified(user),
        disabled: user.email_verified,
      },
      { name: 'delete-user', label: `Delete\xa0User`, handler: () => handleDeleteUser(user) },
    ];
  };

  function handleRender() {
    if (loading) return <Loading relative />;
    if (users.length > 0) {
      const filteredData = applyFilters(users);
      const headers = [
        { id: `avatar`, alignLeft: true, numeric: false, label: `User` },
        { id: `name`, alignLeft: true, numeric: false, label: `Name` },
        { id: `email`, alignLeft: true, numeric: false, label: `Email` },
        { id: `roles`, alignLeft: true, numeric: false, label: `Role` },
        { id: `last_login`, alignLeft: true, numeric: true, label: `Last\xa0Login` },
      ];
      const rows = filteredData.map(user => {
        return {
          id: user.user_id,
          avatar: <Avatar alt={user.name || 'User picture'} src={user.picture} />,
          name: user.name || '',
          emailChip: user.email ? (
            <>
              {`${user.email}\xa0\xa0\xa0\xa0`}
              <Chip
                variant={user.email_verified ? 'default' : 'outlined'}
                size='small'
                color={user.email_verified ? 'primary' : 'secondary'}
                label={user.email_verified ? 'verified' : 'pending'}
              />
            </>
          ) : (
            `N/A`
          ),
          email: user.email || '',
          roles: user.app_metadata.roles || `N/A`,
          last_login: user.last_login,
          email_verified: user.email_verified,
        };
      });
      return (
        <>
          <div className={cls.rootTable}>
            {users && users.length > 0 ? (
              <>
                <Container className={cls.rootTable} maxWidth='lg'>
                  <AccordianTable
                    title={`${users.length} Users`}
                    size={`small`}
                    headers={headers}
                    rows={rows}
                    actions={getUserTableActions()}
                    search={search}
                    order={order}
                    orderBy={orderBy}
                    tablePage={tablePage}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    setSearch={setSearch}
                    setOrder={setOrder}
                    setOrderBy={setOrderBy}
                    setTablePage={setTablePage}
                    setRowsPerPage={setRowsPerPage}
                    setExpandedRowId={setExpandedRowId}
                    refetch={refetch}
                    refreshPersistAs='users'
                  >
                    {TableSort.stableSort(rows, TableSort.getSorting(order, orderBy))
                      .slice(tablePage * rowsPerPage, tablePage * rowsPerPage + rowsPerPage)
                      .map(row => (
                        <AccordianRow
                          key={`user-${row.id}-row`}
                          rowId={row.id}
                          expandedRowId={expandedRowId}
                          setExpandedRowId={setExpandedRowId}
                          columns={[
                            { align: 'left', value: row.avatar },
                            { align: 'left', value: row.name },
                            { align: 'left', value: row.emailChip },
                            { align: 'left', value: row.roles.toString() },
                            { align: 'left', value: row.last_login ? moment(row.last_login).fromNow() : `N/A` },
                          ]}
                          actions={buildContextMenuActions(row)}
                          // className={expandedRowId === row.id ? cls.rowActive : cls.row}
                          className={cls.row}
                        />
                      ))}
                    <Button
                      variant='contained'
                      color='primary'
                      style={{ marginTop: 20, marginLeft: 20 }}
                      onClick={() => setModalRender(true)}
                    >
                      Add User
                    </Button>
                  </AccordianTable>
                </Container>
              </>
            ) : (
              <Container maxWidth='sm'>
                <div className={cls.notFound}>
                  <Typography component='div' className={cls.notFoundTxt}>
                    NO USERS FOUND
                    <br />
                    <br />
                    <Button className={cls.button} variant='contained' color='primary'>
                      Add User
                    </Button>
                  </Typography>
                </div>
              </Container>
            )}
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className={cls.rootTable}>
            <Container maxWidth='sm'>
              <div className={cls.notFound}>
                <Typography component='div' className={cls.notFoundTxt}>
                  NO USERS FOUND
                  <br />
                  <br />
                  <Button
                    className={cls.button}
                    variant='contained'
                    color='primary'
                    onClick={() => setModalRender(true)}
                  >
                    Add User
                  </Button>
                </Typography>
              </div>
            </Container>
          </div>
        </>
      );
    }
  }

  return (
    <div className={cls.root}>
      {modalRender && (
        <CustomerUserForm
          existingUser={existingUser}
          setExistingUser={setExistingUser}
          customer={props.customer}
          modalRender={modalRender}
          setModalRender={setModalRender}
          refetch={refetch}
        />
      )}
      {handleRender()}
    </div>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  rootTable: {
    verticalAlign: 'top',
    position: 'relative',
    width: '100%',
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
  row: {
    height: 45,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: '#ffffff',
    color: theme.palette.text.primary,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#80c8ff',
      color: '#fff',
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  rowActive: {
    height: 45,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    background: theme.palette.primary.main,
    color: '#fff',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
    },
    transition: '0.1s',
    cursor: 'pointer',
  },
  button: {
    margin: 'auto',
    boxShadow: `none`,
    '&:hover, &:active': {
      boxShadow: `none`,
    },
  },
}));
