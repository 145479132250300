import React, { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../../../global-context";

import gql from "graphql-tag";

import {
  makeStyles,
  Typography,
  TextField,
  InputAdornment,
  Icon,
  MenuItem,
} from "@material-ui/core";
import {
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  ModalAction,
} from "../../reusable/ModalComponents";

import fragments from "../../utils/graphQL/fragments";

import sdk from '@hopdrive/sdk';

const log = false;

////////// COMPONENT //////////
export default function LocationAddEditModal(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const { open, onClose, locationInput } = props;

  const [type, setType] = useState(`customer`);
  const [name, setName] = useState(``);
  const [address, setAddress] = useState(``);
  const [nickname, setNickname] = useState(``);
  const [email, setEmail] = useState(``);
  const [phone, setPhone] = useState(``);

  useEffect(() => {
    if (locationInput) {
      setType(locationInput.type || `customer`);
      setName(locationInput.name || ``);
      setAddress(locationInput.address || ``);
      setNickname(locationInput.nickname || ``);
      setEmail(locationInput.email || ``);
      setPhone(locationInput.phone || ``);
    }
  }, [locationInput]);

  const handleInputChange = (setHandler) => (event) => {
    if (setHandler && event) setHandler(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      const customerId = parseInt(
        ctx.customerOverride ||
          ctx.userProfile[`https://hasura.io/jwt/claims`][
            `x-hasura-customer-id`
          ]
      );
      const variables = {
        customerId: customerId,
        regionId: await getRegionId(),
        placeId: locationInput.place_id,
        type: type,
        name: name,
        nickname: nickname,
        address: address,
        latitude: locationInput.latitude,
        longitude: locationInput.longitude,
        email: email,
        phone: phone,
        // favorite: locationInput.favorite,
      };
      const res = await ctx.apolloClient.mutate({
        mutation: INSERT_LOCATION,
        variables: variables,
      });
      if (res) {
        const newLoc = res.data.insert_locations.returning[0];
        log && console.log(`>> Successfully created location:`, newLoc);
        ctx.handleNotifications(
          true,
          `success`,
          `Successfully created location.`
        );

        handleClose(newLoc);
      }
    } catch (err) {
      console.log(`<< Failed to create location:`, err);
      ctx.handleNotifications(
        true,
        `error`,
        `Failed to create location: ${err}`
      );
    }
  };
  const handleClose = (newLoc = null) => {
    if (onClose) onClose(newLoc);
    setType(`customer`);
    setName(``);
    setAddress(``);
    setNickname(``);
    setEmail(``);
    setPhone(``);
  };

   const getRegionId = async () => {
    var regionId
    // Used to get region_id from Latitude & Longitude
    let regionRes = await sdk.regions.getByCoords([locationInput.longitude, locationInput.latitude]);
    if(regionRes.success && regionRes.data && regionRes.data.length > 0){
      regionId = regionRes.data[0].id
    }else{
      let errorMessage = regionRes.errors && regionRes.errors.length > 0 ? regionRes.message : 'error getting Region'
      let regionError = new Error(errorMessage);
      console.error(regionError)
      regionId = null
    }
    return regionId
  }

  return (
    <>
      <Modal open={open}>
        <ModalHeader handleClose={handleClose}>
          Create & Set Location
        </ModalHeader>

        <ModalContent
          subtitle={`Fill out the form to create and store this location for future use. You may edit the type & name, but the address is updated by Google. You may also provide an optional nickname, email and phone number associated with it. Verify the address is correct before continuing and refrain from duplicating existing\xa0locations.`}
        >
          <Typography className={cls.title}>Required Fields</Typography>

          {/* 02/04/2020
            The below code allowed the dealer to set the type of location.
            We are taking this out to make the location always default to a standard 'customer' type.
            There is code in the move planner to update a location after move creation if it is associated with a consumer address.          
          */}
          
          {/* <div className={cls.break} /> */}

          {/* <TextField
            select
            required
            fullWidth
            label="Type"
            placeholder="Specify a location type..."
            value={type}
            onChange={handleInputChange(setType)}
            margin="dense"
            variant="outlined"
            className={cls.input}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  style={{ verticalAlign: "top" }}
                  position="start"
                >
                  <Icon color="disabled" fontSize="small">
                    label_important
                  </Icon>
                </InputAdornment>
              ),
            }}
          >
            <MenuItem value={`customer`}>Standard</MenuItem>
            <MenuItem value={`consumer business`}>Consumer Business</MenuItem>
            <MenuItem value={`consumer residential`}>
              Consumer Residential
            </MenuItem>
          </TextField> */}

          <div className={cls.break} />

          <TextField
            required
            fullWidth
            label="Name"
            placeholder="Enter a name for the location..."
            value={name}
            onChange={handleInputChange(setName)}
            margin="dense"
            variant="outlined"
            className={cls.input}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  style={{ verticalAlign: "top" }}
                  position="start"
                >
                  <Icon color="disabled" fontSize="small">
                    local_offer
                  </Icon>
                </InputAdornment>
              ),
            }}
          />

          <div className={cls.break} />

          <TextField
            disabled
            required
            fullWidth
            multiline
            label="Address"
            placeholder="Enter an address for the location..."
            value={address}
            onChange={handleInputChange(setAddress)}
            margin="dense"
            variant="outlined"
            className={cls.input}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  style={{ verticalAlign: "top" }}
                  position="start"
                >
                  <Icon color="disabled" fontSize="small">
                    room
                  </Icon>
                </InputAdornment>
              ),
            }}
          />

          <div className={cls.bigBreak} />

          <Typography className={cls.title}>Optional Fields</Typography>

          <div className={cls.break} />

          <TextField
            fullWidth
            label="Nickname"
            placeholder="(Optional) Enter a nickname for the location..."
            value={nickname}
            onChange={handleInputChange(setNickname)}
            margin="dense"
            variant="outlined"
            className={cls.input}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  style={{ verticalAlign: "top" }}
                  position="start"
                >
                  <Icon color="disabled" fontSize="small">
                    loyalty
                  </Icon>
                </InputAdornment>
              ),
            }}
          />

          <div className={cls.break} />

          <TextField
            fullWidth
            type="email"
            label="Email"
            placeholder="(Optional) Enter a related email..."
            value={email}
            onChange={handleInputChange(setEmail)}
            margin="dense"
            variant="outlined"
            className={cls.input}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  style={{ verticalAlign: "top" }}
                  position="start"
                >
                  <Icon color="disabled" fontSize="small">
                    email
                  </Icon>
                </InputAdornment>
              ),
            }}
          />

          <div className={cls.break} />

          <TextField
            fullWidth
            type="phone"
            label="Phone"
            placeholder="(Optional) Enter a related phone number..."
            value={phone}
            onChange={handleInputChange(setPhone)}
            margin="dense"
            variant="outlined"
            className={cls.input}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  style={{ verticalAlign: "top" }}
                  position="start"
                >
                  <Icon color="disabled" fontSize="small">
                    phone_iphone
                  </Icon>
                </InputAdornment>
              ),
            }}
          />
        </ModalContent>

        <ModalFooter>
          <ModalAction
            onClick={() => handleSubmit()}
            disabled={name.length < 1 || address.length < 1}
          >
            Submit
          </ModalAction>
          <ModalAction onClick={() => handleClose()} color="secondary">
            Cancel
          </ModalAction>
        </ModalFooter>
      </Modal>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "21px",
    fontWeight: 500,
  },
  input: {
    margin: 0,
  },
  break: {
    width: "100%",
    height: theme.spacing(2),
  },
  bigBreak: {
    width: "100%",
    height: theme.spacing(3),
  },
}));

////////// GRAPHQL //////////
const INSERT_LOCATION = gql`
  mutation insert_location(
    $customerId: bigint!
    $regionId: bigint
    $placeId: String
    $type: String!
    $name: String!
    $nickname: String
    $address: String!
    $latitude: numeric!
    $longitude: numeric!
    $email: String
    $phone: String
  ) {
    insert_locations(
      objects: {
        active: 1
        customer_id: $customerId
        region_id: $regionId
        place_id: $placeId
        type: $type
        name: $name
        nickname: $nickname
        address: $address
        latitude: $latitude
        longitude: $longitude
        email: $email
        phone: $phone
      }
    ) {
      affected_rows
      returning {
        ...Location
      }
    }
  }
  ${fragments.location}
`;
