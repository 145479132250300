import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography, Tooltip, Icon, IconButton } from "@material-ui/core";
import moment from "moment";
import DriverScheduleRow from "./DriverScheduleRow";

let log = false;

export default function DriverScheduleBody(props) {
  const cls = useStyles();

  const { drivers, schedules } = props;
  const [payPeriodDates, setPayPeriodDates] = useState([]);
  const [driversWithSchedules, setDriversWithSchedules] = useState([]);

  useEffect(() => {
    let dateArr = createDates();
    setPayPeriodDates(dateArr);
  }, []);

  useEffect(() => {
    log && console.log("schedules updated", drivers);
    setDriverSchedules(drivers, schedules);
  }, [drivers, schedules]);

  let setDriverSchedules = (drivers, schedules) => {
    log && console.log("schedules", schedules);
    drivers.forEach((driver) => {
      let driverSchedules = schedules.filter((schedule) => schedule.driver_id === driver.fleet_id);
      let scheduleDetailArr = driverSchedules.map((sched) => {
        return { id: sched.id, type: sched.type, details: sched.details };
      });
      driver.schedule = scheduleDetailArr;
      if (driver.schedule.length > 0) log && console.log("driver with schedule", driver);
    });
    setDriversWithSchedules(drivers);
  };

  //TODO: Add sticky table header
  // React.useEffect(() => {
  //   window.addEventListener(`scroll`, handleScroll);
  // }, []);
  // const handleScroll = () => {
  //   console.log("SCROLLING")
  //   let element = document.getElementById("scheduleHeader");
  //   if (window.pageYOffset < 12) element.classList.add(cls.sticky);
  //   else element.classList.remove(cls.sticky);
  // };

  let createDates = () => {
    let dateArr = [];
    for (var i = 0; i < 14; i++) {
      if (i < 7) {
        dateArr.push(moment(i, "e").format("ddd MM/DD"));
      } else {
        dateArr.push(
          moment(i - 7, "e")
            .add(1, "week")
            .format("ddd MM/DD")
        );
      }
    }
    return dateArr;
  };

  let TableHeader = () => {
    return (
      <div className={cls.headerRow} id="scheduleHeader">
        <Tooltip placement="top" title="Manually refresh">
          <IconButton onClick={() => {props.refetch()}}>
            <Icon>cached</Icon>
          </IconButton>
        </Tooltip>
        {payPeriodDates.map((date) => {
          return (
            <Typography key={"day" + date} className={cls.date}>
              {date}
            </Typography>
          );
        })}
      </div>
    );
  };

  return (
    <Paper>
        <TableHeader refetch={props.refetch} />
      <div className={cls.container}>
        {driversWithSchedules.map((driver) => {
          return (
            <DriverScheduleRow
              className={cls.row}
              key={"Driver" + driver.fleet_id}
              driver={driver}
              refetch={props.refetch}
            />
          );
        })}
      </div>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  container: {
    color: theme.palette.text.primary,
    /* grid container settings */
    display: "grid",
    gridTemplateRows: "auto 1fr auto",
    overflow: "hidden",
    gridTemplateAreas: "header main footer",
  },
  row: {
    height: "60px",
    display: "inline-grid",
    gridTemplateColumns: "16% repeat(14, 6%)",
  },
  //TODO- make header row sticky
  headerRow: {
    position: "sticky",
    top: "64px",
    gridArea: "header",
    height: "100%",
    display: "inline-grid",
    gridTemplateColumns: "16% repeat(14, 6%)",
    gridRowStart: 1,
    gridRowEnd: "span 1",
    backgroundColor: theme.palette.background.main,
    gridColumnStart: 1,
    gridColumnEnd: "span 1",
  },
  // sticky: {
  //   position: "fixed",
  //   top: 0,
  // },
  date: {
    wordBreak: "normal",
    padding: "10px",
    gridColumnEnd: "span 1",
    placeSelf: "center",
    margin: "10px",
  },
}));
