import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Loading(props) {
  return (
    <CircularProgress
      disableShrink
      style={{
        display: 'block',
        margin: 'auto',
        position: props.fixed ? 'fixed' : (props.relative ? 'relative' : 'absolute'),
        top: '0',
        left: '0',
        bottom: '0',
        right: '0',
      }} />
  );
}
