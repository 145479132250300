import React from 'react';
import { withStyles, Grid, Typography } from '@material-ui/core';
import VehicleReview from './VehicleReview'
import moment from 'moment';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  text: {
    textAlign: 'center',
  },
  headers: {
    fontSize: '150%',
  },
  subheader: {
    fontSize: '115%'
  },
  date: {
    fontSize: '80%'
  }
});

class Review extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  };

  render() {
    const { classes, state } = this.props;
    return (
      <React.Fragment>
        <br />
        <br />
        <Grid container justify="center" alignItems="center" spacing={8}>

          <Grid item xs={12} >
            {/* moment.js Calendar Time */}
            <Typography className={classes.headers} inline="true" variant="h6"> Pickup <Typography className={classes.date} inline="true" >{ moment(state.ready_by).format("dddd, MMM Do, Y")} @ {moment(state.ready_by).format("h:mm A")} </Typography> </Typography>
            <hr />
          </Grid>
          <Grid item xs={12}>
            <Typography inline="true" className={classes.subheader}> {state.pickup.name} </Typography>
            <Typography color="textSecondary"> {state.pickup.address} </Typography>
          </Grid>

          <Grid item xs={12} >
            <Typography className={classes.headers} inline="true" variant="h6"> Delivery {state.deliver_by? <Typography className={classes.date} inline="true" >{ moment(state.deliver_by).format("dddd, MMM Do, Y")} @ {moment(state.deliver_by).format("h:mm A")} </Typography>: null} </Typography>
            <hr />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.subheader}> {state.delivery.name} </Typography>
            <Typography color="textSecondary"> {state.delivery.address} </Typography>
          </Grid>

        <Grid item xs={12} >
          <Typography inline="true" variant="h6"> Vehicle(s) </Typography>
          <hr />
          <VehicleReview state={this.props.state} />
        </Grid>

        <Grid item xs={12} >
          <Typography inline="true" color="textPrimary"> Please confirm your move request by clicking 'Finish' below.</Typography>
        </Grid>
        <br />
        <br />
        <br />
        <br />
        </Grid>
      </React.Fragment >
    )
  };
};

export default withStyles(styles)(Review)