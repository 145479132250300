import React from 'react';
import VehicleTable from './VehicleTable';
import VehicleForm from './VechicleForm'

class VehicleSelection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    }
  };

  handleShow = () => {
    this.setState({show: true})
  }

  render() {

    return (
      <React.Fragment>

        <VehicleForm 
          stock={this.props.stock} 
          vin={this.props.vin} 
          year={this.props.year} 
          color={this.props.color} 
          make={this.props.make} 
          model={this.props.model}
          manual={this.props.manual}
          instructions={this.props.instructions}
          // handleShow={this.handleShow} 
          handleCheckChange={this.props.handleCheckChange} 
          handleChange={this.props.handleChange}
          handleSelectChange={this.props.handleSelectChange}
          handleAttributeChange={this.props.handleAttributeChange} 
          updateVehicleData={this.props.updateVehicleData} 
          addVehicle={this.props.addVehicle}
          clearVehicle={this.props.clearVehicle}
          createEdit={false}
          consumer_pickup={this.props.consumer_pickup}
          consumer_name={this.props.consumer_name}
          consumer_number={this.props.consumer_number}
          reference_num={this.props.reference_num}
        />

        <VehicleTable show={this.state.show} vehicles={this.props.vehicles} removeVehicle={this.props.removeVehicle} updateVehicleData={this.props.updateVehicleData}/>

      </React.Fragment>
    )
  }

};

export default VehicleSelection;