import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import gql from "graphql-tag";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ShareIcon from '@material-ui/icons/Share';
import EditIcon from '@material-ui/icons/Edit';
import FilterListIcon from '@material-ui/icons/FilterList';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { GlobalContext } from '../../../global-context';
import DotMenu from '../../reusable/DotMenu';
import { withRouter } from 'react-router';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';



let log = false;

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: 'customer', numeric: true, disablePadding: true, label: 'Customer' },
  { id: 'region', numeric: true, disablePadding: true, label: 'region' },
  { id: 'type', numeric: true, disablePadding: true, label: 'Type' },
  { id: 'description', numeric: true, disablePadding: true, label: 'Description' },
  { id: 'expiration', numeric: true, disablePadding: true, label: 'Expiration Date' },
  { id: 'dot-menu', numeric: false, disablePadding: true, label: '' },
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
              color="primary"
            />
          </TableCell>
          {rows.map(
            row => (
              <TableCell
                key={`cell-${row.id}`}
                align="left"
                padding={row.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  key={`cell-tooltip-${row.id}`}
                  title="Sort"
                  placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    key={`cell-label-${row.id}`}
                    active={orderBy === row.id}
                    direction={order}
                    onClick={row.id ? this.createSortHandler(row.id) : null}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(1),
    marginTop: '-24px',
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.primary.main,
        backgroundColor: lighten(theme.palette.primary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.primary.dark,
      },
  spacer: {
    flex: '1 1 1%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    flex: '0 0 auto',
  },
  actionBtn: {
    // display: "inline-block",
    // float: "right"
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: '#3f51b5',
    color: 'white',
    position: 'relative',
    bottom: 8,
  },
});


let EnhancedTableToolbar = props => {
  const { numSelected, classes } = props;


  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
            <Typography variant="h6" id="tableTitle">
              Promos
              <Button
                style={{ marginLeft: "30px" }}
                color="primary"
                variant="contained"
                component={Link} to={"/promos/add"}
              >
                Create New
            </Button>
            </Typography>
          )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <React.Fragment>
            <Tooltip title="Edit the Selected Promo">
              <IconButton className={classes.actionBtn} aria-label="Edit" disabled={numSelected > 1}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        ) : (
            <Tooltip title="Clear Filter">
              <IconButton aria-label="Clear Filter" onClick={props.clearFilter}>
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 400,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  image: {
    paddingTop: "3px",
    paddingBottom: "3px",
  },
  tableRow: {
    transition: "all .2s ease-in-out",
    '&:hover': {
      transform: 'scale(1.005)'
    }
  }
});

class PromosTableView extends React.Component {
  state = {
    order: 'desc',
    orderBy: 'id',
    selected: [],
    // data: [],
    page: 0,
    rowsPerPage: 100,
    search: "",
    filteredPromos: [],
  };

  componentWillMount = () => this.setState({ filteredPromos: this.props.promos });

  // handleLoadData = () => {
  //   if (!_.isEqual(this.props.lanes, this.state.data))
  //   this.setState({data: this.props.lanes})
  // }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: this.props.promos.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  clearFilter = () => {
    this.setState({ order: 'desc', orderBy: 'id', })
  }

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  // openDetails = (id) => {
  //   let path = `/lanes/edit/${id}`
  //   this.props.history.push(path)
  // }

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  handleInputChange = event => {
    this.setState({
      search: event.target.value,
      filteredPromos: this.handleFilteredLanes(this.props.promos, event.target.value.toLocaleLowerCase())
    })
  }

  handleFilteredLanes = (promos, search) => {
    if (!search || search.length < 1) return promos;
    else return promos.filter(o =>
      (o.name && o.name.toLocaleLowerCase().includes(search)) ||
      (o.rule && o.rule.name && o.rule.name.toLocaleLowerCase().includes(search)) ||
      (o.description && o.description.toLocaleLowerCase().includes(search))
      // (o.delivery && o.delivery.name.toLocaleLowerCase().includes(search)) ||
      // (o.delivery && o.delivery.nickname && o.delivery.nickname.toLocaleLowerCase().includes(search))
    )
  };

  setPromoInactive = async (id) => {
    this.context.apolloClient.mutate({
      mutation: SET_PROMO_INACTIVE,
      variables: { id: id},
    }).then(res => {
      if (log) console.log("setPromoInactive - UPDATE_PROMO response (res.data):", res.data);
    }).catch(err => {
      console.error(err);
      this.context.handleNotifications(true, "error", "Failed to update promo");
    })
  }


  render() {
    // let lanes = this.handleFilteredLanes(this.props.lanes);
    let promos = this.state.filteredPromos;
    const { classes } = this.props;
    const { order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, promos.length - page * rowsPerPage);

    return (
      <Paper className={classes.root}>
        <EnhancedTableToolbar selectedID={this.state.selected[0]} numSelected={selected.length} clearFilter={this.clearFilter} />
        <TextField
          id="outlined-name"
          label="Search Promos"
          style={{
            marginLeft: "20px",
            marginRight: "20px",
            width: "500px"
          }}
          value={this.state.search}
          onChange={this.handleInputChange}
          margin="normal"
          variant="outlined"
          shrink="true"
        />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={promos.length}
            />
            <TableBody>
              {stableSort(promos, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => {
                  if (log) console.log('row: ', row)
                  const isSelected = this.isSelected(row.id);
                  const promoActions = [
                    {
                      label: "View Promo",
                      function: () => this.props.history.push({
                        pathname: `/promos/${row.id}`,
                        // state: { promo: row }
                      }),
                    },
                    {
                      label: "Edit Promo",
                      function: () => this.props.history.push({
                        pathname: `/promos/${row.id}`,
                        // state: {promo: row}
                      }),
                    },
                    {
                      label: "Delete Promo",
                      function: () => this.setPromoInactive(row.id)
                    },
                  ];
                  return (
                    <TableRow
                      hover
                      className={classes.tableRow}
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox color="primary" checked={isSelected}
                          onClick={event => this.handleClick(event, row.id)} />
                      </TableCell>
                      <TableCell align="left" padding="none" >{row.customer ? row.customer.name : 'All Customers'}</TableCell>
                      <TableCell align="left" padding="none" >{row.region ? row.region.name : 'All Regions'}</TableCell>
                      <TableCell align="left" padding="none" >{row.rule.name}</TableCell>
                      <TableCell align="left" padding="none" >{row.rule.description}</TableCell>
                      <TableCell align="left" padding="none" >{row.expiration_date ? moment(row.expiration_date).format("MM/DD/YYYY") : "No Expiration"}</TableCell>
                      <TableCell align="left" padding="none"> <DotMenu actions={promoActions} /></TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={promos.length}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

const SET_PROMO_INACTIVE = gql`
mutation set_promo_inactive($id: bigint!) {
  update_promos(where: {id: {_eq: $id}}, _set: {active: false}) {
    affected_rows
  }
}
`;

PromosTableView.contextType = GlobalContext;

PromosTableView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(PromosTableView));