import React from "react";
import { GlobalContext } from "../../global-context";
import { Query } from "react-apollo";
import LaneSelection from './moveEntry/LaneSelection';
import VehicleForm from './moveEntry/VechicleForm'
import SATDateTimePicker from '../reusable/SATDateTimePicker';
import QRadios from '../reusable/QRadioButtons'
import CustomerSelect from '../reusable/CustomerSelect'
import { withStyles } from '@material-ui/core/styles';
import { Typography, Grid, Paper, Switch, Button, TextField, MenuItem } from '@material-ui/core';
import axios from "axios";
import gql from "graphql-tag";
import fragments from '../utils/graphQL/fragments';
import moment from 'moment';
import { withRouter } from 'react-router'
import ChipInputAutosuggest from '../reusable/ChipInputAutosuggest'
// import ChipInput from 'material-ui-chip-input'
import Tookan from "../utils/Tookan"
import ManageAccessorials from "./driverPayReview/ManageAccessorials";
import sdk from '@hopdrive/sdk';


const queryString = require('query-string');

const GET_MOVE_BY_ID = gql`
query get_move_by_id(
    $id: bigint!
    ) {moves(where:{ id: {_eq: $id} }) 
    {...Move}
}
${ fragments.move}
`;
const UPSERT_MOVE = gql`
mutation updateMultiple($moves: [moves_insert_input!]!, $cols: [moves_update_column!]!) { 
  insert_moves (
    objects: $moves,
    on_conflict: {
      constraint: moves_pkey,
      update_columns: $cols
    }
  ) {
    affected_rows
  }
}`;

const GET_PLAN_BY_PLANDATE = gql`
query get_by_plandate($plan_date: date) {
    plans(where: {plan_date: {_eq: $plan_date}}) {
      id
      driver_name
      driver_id
      plan_date
    }
  }`;

const GET_LANES_AND_LOCATIONS_FOR_CUSTOMER = gql`
query lanes_locations_and_favorites_by_customer (
    $customer_id: bigint!
) {
  favoritelocations (where: {customer_id: {_eq: $customer_id}}) {
    ...FavoriteLocation
  }
  favoritelanes (where: {customer_id: {_eq: $customer_id}}) {
    ...FavoriteLane
  }
  locations(
    where: {customer_id: {_eq: $customer_id}, active: {_eq: 1}},
    order_by: {name: asc},
  ) {
   ...Location
  }
  lanes(
    where: {customer_id: {_eq: $customer_id}, active: {_eq: 1}},
    order_by: {description: asc},
  ) {
    ...Lane
  }
}
${fragments.favoriteLocation}
${fragments.favoriteLane}
${fragments.location}
${fragments.lane}
`;

const SUBSCRIBE_TO_FAVORITELANES = gql`
subscription sub_fave_lanes( $customer_id: bigint! ) {
    favoritelanes (where: {customer_id: {_eq: $customer_id}}) {
    ...FavoriteLane
  }
}
${fragments.favoriteLane}
`;
const SUBSCRIBE_TO_FAVORITELOCATIONS = gql`
subscription sub_fave_locs( $customer_id: bigint! ) {
    favoritelocations (where: {customer_id: {_eq: $customer_id}}) {
    ...FavoriteLocation
  }
}
${fragments.favoriteLocation}
`;
const SUBSCRIBE_TO_LOCATIONS = gql`
subscription sub_locs ( $customer_id: bigint! ) {
  locations(
    where: {customer_id: {_eq: $customer_id}, active: {_eq: 1}},
    order_by: {name: asc},
  ) {
    ...Location
  }
}
${fragments.location}
`;
const SUBSCRIBE_TO_LANES = gql`
subscription sub_lanes ( $customer_id: bigint! ) {
  lanes(
    where: {customer_id: {_eq: $customer_id}, active: {_eq: 1}},
    order_by: {description: asc},
  ) {
    ...Lane
  }
}
${fragments.lane}
`;

const GET_LANE = gql`
query getLaneByLocationIds($origin_id: bigint!, $destination_id: bigint!, $customer_id: bigint!)  {
  lanes(
    where: {
        active: {
          _eq: "1"
        }, 
        origin_location_id: {
          _eq: $origin_id
        },  
        customer_id:{
          _eq: $customer_id
        },
    _and: { 
        destination_location_id: {
            _eq: $destination_id
        }
      }
    }
  ) {
    ...Lane
  }
}
${fragments.lane}
`;
const GET_LOCATION_BY_ID = gql`
query get_location_by_id(
    $id: bigint!
  ){
   locations(
    where: {
        active: {
          _eq: 1
        }
        id: {
          _eq: $id
        }
    }
   ) {
    ...Location
   }
}
${fragments.location}
`;
const INSERT_LANES = gql`  
mutation insert_lanes(
  $laneObjects: [lanes_insert_input!]!){
  insert_lanes(objects: $laneObjects) {
    returning {
      ...Lane
    }
  }
 }
 ${fragments.lane}
 `;

const theme = {
    spacing: [0, 2, 3, 5, 8, 10],
}

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: '10'
    },
    paper: {
        // padding: theme.spacing(2),
        margin: 'auto',
        padding: '1rem'
        // maxWidth: 500,
    },
    title: {
        backgroundColor: `#f44232`,
        color: 'white',
        padding: '1rem'
    },
    bold: {
        fontWeight: 'bold'
    },
    typography: {
        fontSize: '150%',
    },
    switch: {
        position: 'relative',
        bottom: '7px'
    },
    quickSelect: {
        backgroundColor: '#777777',
        // color: 'white',
        margin: '1px',
        border: '2px',
    },
    submitButton: {
        backgroundColor: `#f44232`,
        color: 'white',
    }
});

const AntSwitch = withStyles(theme => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        '&$checked': {
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);

let log = false


class MoveCreateEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // id: "",
            active: 1,
            auto_assign: false,
            class: "",
            customer: "",
            deliver_by: null,
            delivery: null,
            delivery_time: null,
            enableDeliverBy: false,
            enableOverrideSection: false,
            formType: "",
            hasMounted: false,
            lane: null,
            lyft_flag: false,
            manual_flag: false,
            move_details: "",
            move_type: "drive",
            pickup: null,
            pickup_time: null,
            plan_date: "",
            plan_id: null,
            preventClick: false,
            priority: 9,
            ready_by: null,
            ride_type: "",
            rate_class_override: false,
            tags: [],
            synced: false,
            updatedat: "now()",
            vehicle_stock: "",
            vehicle_vin: "",
            vehicle_year: "",
            vehicle_make: "",
            vehicle_model: "",
            vehicle_color: "",
            consumer_pickup: false,
            vehicle_consumer_name: '',
            vehicle_consumer_number: '',
            showAccessorialsModal: false,
            reference_num: '',
            chargeable: true,
            payable: true,
        };
    }

    componentWillMount = () => {
        if (this.context.userProfile["https://hasura.io/jwt/claims"] && this.context.userIsAuthenticated()) {
            // sdk.configure({apollo_client: this.context.apolloClient})

            if (this.props.location.pathname.includes('add')) {
                this.setState({ formType: 'create', hasMounted: true })
                // parse info from URL
                this.handleURLString()
            }
            else if (this.props.location.pathname.includes('edit')) {
                this.setState({
                    formType: 'edit',
                    id: parseInt(this.props.match.params.id)
                });
                this.context.apolloClient.query({
                    query: GET_MOVE_BY_ID,
                    variables: { id: parseInt(this.props.match.params.id) },
                }).then(res => {
                    const foundMove = res.data.moves[0]
                    if (log) console.log("GET_MOVE_BY_ID response (res.data):", res.data);
                    let resObj = {
                        active: foundMove.active,
                        ready_by: foundMove.ready_by,
                        deliver_by: foundMove.deliver_by,
                        customer: foundMove.customer_id,
                        pickup: foundMove.lane.pickup,
                        delivery: foundMove.lane.delivery,
                        lane: foundMove.lane,
                        class: foundMove.class,
                        move_type: foundMove.move_type,
                        ride_type: foundMove.ride_type,
                        auto_assign: foundMove.auto_assign ? true : false,
                        lyft_flag: foundMove.lyft_flag ? true : false,
                        rate_class_override: foundMove.rate_class_override ? true : false,
                        vehicle_stock: foundMove.vehicle_stock,
                        vehicle_vin: foundMove.vehicle_vin,
                        vehicle_year: foundMove.vehicle_year,
                        vehicle_make: foundMove.vehicle_make,
                        vehicle_model: foundMove.vehicle_model,
                        vehicle_color: foundMove.vehicle_color,
                        manual_flag: foundMove.manual_flag ? true : false,
                        move_details: foundMove.move_details,
                        plan_id: foundMove.plan_id,
                        plan_date: foundMove.plan_id ? foundMove.plan.plan_date : null,
                        pickup_time: foundMove.pickup_time ? foundMove.pickup_time : null,
                        delivery_time: foundMove.delivery_time ? foundMove.delivery_time : null,
                        tookan_relationship_id: foundMove.tookan_relationship_id ? foundMove.tookan_relationship_id : null,
                        pickup_stop_id: foundMove.pickup_stop_id ? foundMove.pickup_stop_id : null,
                        delivery_stop_id: foundMove.delivery_stop_id ? foundMove.delivery_stop_id : null,
                        driver_id: foundMove.driver_id,
                        previous_driver_id: foundMove.driver_id + 1,
                        hasMounted: true,
                        reference_num: foundMove.reference_num,
                        chargeable: foundMove.chargeable,
                        payable: foundMove.payable,
                    };
                    let resArr = Object.entries(resObj)
                    resArr.forEach(([key, value]) => {
                        if (value === null && key !== 'ready_by' && key !== 'deliver_by') {
                            value = ''
                            this.setState({ [key]: value });
                        } else {
                            this.setState({ [key]: value });
                        }
                    })
                    this.setState({
                        tags: (foundMove.tags) ? foundMove.tags.split(',') : []
                    })
                }).catch(function (err) {
                    console.error(err);
                    throw err
                });
            }
        }
    };

    handleCloseAccessorialsModal = () => this.setState({ showAccessorialsModal: false })

    handleURLString = () => {
        if (this.props.location.search) {
            let parsed = queryString.parse(this.props.location.search);
            if (log) { console.log('PARSED', parsed) }
            this.updateVehicleData(
                parsed.vv ? parsed.vv : "",
                parsed.vs ? parsed.vs : "",
                parsed.vy ? parsed.vy : "",
                parsed.vc ? parsed.vc : "",
                parsed.vma ? parsed.vma : "",
                parsed.vmo ? parsed.vmo : "",
                parsed.mf ? parsed.mf : ""
            )
            if (parsed.p) {
                this.context.apolloClient.query({
                    query: GET_LOCATION_BY_ID,
                    variables: { id: parseInt(parsed.p) },
                }).then(res => {
                    const pickup = res.data.locations[0];
                    // attempt to avoid creating moves invisible to dealer (with mismatching customer_id fields)
                    this.setState({ customer: pickup.customer_id })
                    this.handleSetLocation('pickup', pickup)
                }).catch(function (err) {
                    console.error(err);
                    throw err
                });
            }
            if (parsed.d) {
                this.context.apolloClient.query({
                    query: GET_LOCATION_BY_ID,
                    variables: { id: parseInt(parsed.d) },
                }).then(res => {
                    const delivery = res.data.locations[0];
                    this.handleSetLocation('delivery', delivery)
                }).catch(function (err) {
                    console.error(err);
                    throw err
                });
            }
        }
    }

    // Submit and cancel functions
    handleSubmit = (e) => {
        e.preventDefault()
        try {
            let newAndAdjustedFields = {
                lane_id: this.state.lane.id,
                customer_id: this.state.customer,
                auto_assign: this.state.auto_assign ? 1 : 0,
                lyft_flag: this.state.lyft_flag ? 1 : 0,
                rate_class_override: this.state.rate_class_override ? 1 : 0,
                chargeable: this.state.chargeable,
                payable: this.state.payable,
                delivery: this.state.enableDeliverBy ? this.state.deliver_by : null,
                synced_with_tookan: this.state.synced ? "now()" : null,
                tags: this.state.tags.join(),
            }
            let stateClone = Object.assign({}, this.state);
            let upsertableMove = Object.assign(stateClone, newAndAdjustedFields);

            if (this.state.consumer_pickup) {
                let message = `Consumer pickup, call before pickup: ${this.state.vehicle_consumer_name} @ ${this.state.vehicle_consumer_number}`;
                if (!upsertableMove.move_details) Object.assign(upsertableMove, { move_details: message })
                else Object.assign(upsertableMove, { move_details: `${message} -- ${upsertableMove.move_details}` })
            }

            if (log) console.log(upsertableMove)
            delete upsertableMove.formType;
            delete upsertableMove.preventClick;
            delete upsertableMove.hasMounted;
            delete upsertableMove.enableDeliverBy;
            delete upsertableMove.enableOverrideSection;
            delete upsertableMove.lane;
            delete upsertableMove.pickup;
            delete upsertableMove.delivery;
            delete upsertableMove.customer;
            delete upsertableMove.plan_date;
            delete upsertableMove.previous_driver_id;
            delete upsertableMove.tookan_relationship_id;
            delete upsertableMove.synced;
            delete upsertableMove.consumer_pickup;
            delete upsertableMove.vehicle_consumer_name;
            delete upsertableMove.vehicle_consumer_number;
            delete upsertableMove.showAccessorialsModal;
            if (upsertableMove.manual_flag === undefined || !upsertableMove.manual_flag || upsertableMove.manual_flag === null) upsertableMove.manual_flag = false;
            if (upsertableMove.plan_id === undefined || upsertableMove.plan_id === "") { upsertableMove.plan_id = null }
            if (upsertableMove.delivery_time === undefined || upsertableMove.delivery_time === "") { upsertableMove.delivery_time = null }
            if (upsertableMove.pickup_time === undefined || upsertableMove.pickup_time === "") { upsertableMove.pickup_time = null }
            if (upsertableMove.delivery_stop_id === undefined || upsertableMove.delivery_stop_id === "") { upsertableMove.delivery_stop_id = null }
            if (upsertableMove.pickup_stop_id === undefined || upsertableMove.pickup_stop_id === "") { upsertableMove.pickup_stop_id = null }
            if (upsertableMove.driver_id === undefined || upsertableMove.driver_id === "") { upsertableMove.driver_id = null }
            // upsertableMove.forEach(ele=>{if (ele === ""){ele = null}}) //React gives errors if these are null in the form- but db needs null not empty strings
            let updateCols = Object.keys(upsertableMove).filter(key => key !== 'id');
            this.context.apolloClient.mutate({
                mutation: UPSERT_MOVE,
                variables: {
                    moves: [upsertableMove],
                    cols: updateCols
                }
            }).then((res, err) => {
                if (log) { console.log("upsertMove response (res.data):", res.data) };
                if (err) { throw err }
                if (res.data.err) { throw res.data.err }
                // insert success snackbar here
                if (this.props.history.length > 1) { this.props.history.goBack() }
                else (this.props.history.push({ pathname: '/moves/' }))
            });
        } catch (err) {
            console.log(err);
            this.context.handleNotifications(true, "error", "Move mutation failed")
        }
    }
    handleCancel = (e) => {
        e.preventDefault()
        if (this.props.history.length > 1) { this.props.history.goBack() }
        else (this.props.history.push({ pathname: '/moves/' }))
    }
    // customer select function

    handleCustomerChange = event => {
        const { value } = event.target;
        this.setState({ customer: value, lane: null, pickup: null, delivery: null });
    };

    // functions for TimeSelection
    enableDeliverBy = () => {
        this.setState({ enableDeliverBy: !this.state.enableDeliverBy })
    }

    handleTimeSubmit = (type, time) => {
        if (type === "pickup") {
            this.setState({ ready_by: time })
        } else if (type === "delivery") {
            this.setState({ deliver_by: time })
        } else if (type === "pickup_time") {
            this.setState({ pickup_time: time, pinnable: true, synced: true })
        } else if (type === "delivery_time") {
            this.setState({ delivery_time: time, pinnable: true, synced: true })
        }
    }

    TimeSection = (props) => {
        if (this.state.hasMounted) {
            return (
                <Grid container direction='row' justify='space-between' alignItems='center'>
                    <Grid item md={6} xs={12}>
                        <Typography align='center' className={props.classes.typography}>Ready By Time:</Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <SATDateTimePicker
                            handleSubmit={this.handleTimeSubmit}
                            type={"pickup"}
                            // set this to var in DidMount, it's always setting to moment() here
                            startTime={this.state.formType === 'edit' ? moment(this.state.ready_by, moment.ISO_8601) : moment()}
                            offset={this.state.formType === 'create' ? { unit: 'minutes', amount: 45 } : { unit: 'minutes', amount: 0 }}
                            buttons={false}
                            quarterHourMode={this.state.formType === 'create' ? true : false}>
                        </SATDateTimePicker>
                        <br />
                    </Grid>
                    <Grid container item direction='row' align='center' xs={12}>
                        <Grid item xs={3}>
                            <Typography align='center' variant='body1' gutterBottom={true} className={props.classes.typography}>Enable Deliver By Time:</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant='body1' gutterBottom={true}>
                                <Switch
                                    color="primary"
                                    checked={this.state.enableDeliverBy}
                                    onChange={this.enableDeliverBy}
                                    value="checkedA"
                                    className={props.classes.switch}
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                    <br />
                    {this.state.enableDeliverBy ?
                        <>
                            <Grid item md={6} xs={12}>
                                <Typography align='center' className={props.classes.typography}>Deliver By Time:</Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <SATDateTimePicker
                                    handleSubmit={this.handleTimeSubmit}
                                    type={"delivery"}
                                    startTime={this.state.formType === 'edit' ? (
                                        this.state.deliver_by ? moment(this.state.deliver_by, moment.ISO_8601) :
                                            (this.state.ready_by ? moment(this.state.ready_by, moment.ISO_8601) : moment())
                                    ) : moment()}
                                    offset={this.state.formType === 'create' ? { unit: 'hours', amount: 1.75 } : { unit: 'minutes', amount: 0 }}
                                    buttons={false}
                                    quarterHourMode={this.state.formType === 'create' ? true : false}>
                                </SATDateTimePicker>
                                <br />
                            </Grid>
                        </>
                        :
                        null
                    }
                    <hr />
                </Grid>
            )
        } else {
            return null
        }
    }
    // methods and components for 'details' section
    OnOffSwitch = (props) => {
        return (
            <Typography gutterBottom={true} align="center" component="div">
                <Grid component="label" container alignItems="center" justify="center" spacing={1}>
                    <Grid item>Off</Grid>
                    <Grid item>
                        <AntSwitch
                            checked={this.state[props.value]}
                            onChange={this.handleCheckChange(props.value)}
                            value={props.value}
                        />
                    </Grid>
                    <Grid item>On</Grid>
                </Grid>
            </Typography>
        )
    }

    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({ [name]: value });
    };
    //methods used in 'details' section and VehicleForm

    handleCheckChange = name => event => {
        if (log) { console.log('checked' + event.target.checked) };
        this.setState({ [name]: event.target.checked });
    };
    // methods for VehicleForm

    handleChange = (name, value) => event => {
        if (name === 'instructions') {
            this.setState({ "move_details": value === undefined ? event.target.value : value });
        }
        else if (name === 'reference_num') {
            this.setState({ "reference_num": value === undefined ? event.target.value : value })
        }
        else if (name !== 'instructions') { this.setState({ ["vehicle_" + name]: value === undefined ? event.target.value : value }); }
    };

    handleManualChange = name => event => {
        this.setState({ manual_flag: event.target.checked });
    };

    handleSelectChange = name => event => {
        if (log) console.log("handleSelectChange event:", event ? event : "no event value");
        this.setState({ ["vehicle_" + name]: event ? event.value : "" })
    };

    handleAttributeChange = name => event => {
        let attribute = "";
        if (name === "stock") attribute = "vehicle_stock";
        if (name === "vin") attribute = "vehicle_vin";
        this.setState({ [attribute]: event ? event.value : "" }, () => {
            if (event && event.value) this.runQuery(attribute, attribute);
        })
    }
    // Query GQL and return the most recent completed move that matches the value of the entered attribute
    runQuery = (name, attribute) => {
        // Do not run the query if the attribute field is blank
        if (!this.state[name] || this.state[name].trim().length < 1) return
        this.context.apolloClient.query({
            // Passes in the attribute prop from the parent component to use as the search criteria
            query: gql`query lookup_move($value: String!) {
                moves(where: {${attribute}: {_eq: $value}}, order_by: {delivery_successful: desc}, limit: 1) {
                    ...Move
                }
            }
            ${fragments.move}
            `,
            onError: err => {console.error(err);this.context.handleNotifications(true, "error", "Query failed to retrieve move data.")},
            variables: { value: this.state[name] } // searches against the value entered in the input field
        }).then((data) => {
            if (data.data.moves.length !== 0) {
                this.updateVehicleData(data.data.moves[0].vehicle_vin, data.data.moves[0].vehicle_stock, data.data.moves[0].vehicle_year,
                    data.data.moves[0].vehicle_color, data.data.moves[0].vehicle_make, data.data.moves[0].vehicle_model, data.data.moves[0].reference_num)
            }
            else {
                return
            }
        })
    }

    updateVehicleData = (vin, stock, year, color, make, model, manual, instructions, reference_num) => {
        if (make !== null && make !== undefined) {
            make = make.toLowerCase()
            make = make.charAt(0).toUpperCase() + make.substring(1)
        }
        this.setState({ vehicle_vin: vin, vehicle_stock: stock, vehicle_year: year, vehicle_color: color, vehicle_make: make, vehicle_model: model, manual_flag: manual, move_details: instructions, reference_num: reference_num })
    }

    clearVehicle = () => {
        this.setState({ vehicle_vin: "", vehicle_stock: "", vehicle_year: "", vehicle_color: "", vehicle_make: "", vehicle_model: "", manual_flag: false, move_details: "", reference_num: '' })
    }

    // methods for LaneSelection

    handlePreventClick = () => {
        this.setState({ preventClick: !this.state.preventClick })
    };
    handleSetLocation = (name, value) => {
        if (log) console.log("Setting location for", name, "to", value ? value : "null")
        this.setState({ [name]: value ? value : null }, () => {
            this.handleLaneByLocations();
        });
    };
    handleFavoriteLane = (pickup, delivery, event) => {
        if (log) console.log("handleFavoriteLane event triggered with preventClick state of ", this.state.preventClick);
        if (log) console.log("  event:", event.target);
        if (this.state.preventClick) return;
        if (log) console.log("Setting lane as:", pickup, "to", delivery);
        this.setState({ pickup: pickup, delivery: delivery }, async () => {
            await this.handleLaneByLocations();
        });
    };
    handleReverseLocations = () => {
        const { pickup, delivery } = this.state;
        this.setState({ pickup: delivery, delivery: pickup }, async () => {
            await this.handleLaneByLocations();
        });
    }
    updateLocationById = (location) => {
        let { pickup, delivery } = this.state;
        if (!location || !pickup || !delivery) return;
        if (pickup.id === location.id) this.setState({ pickup: location });
        if (delivery.id === location.id) this.setState({ delivery: location });
    }

    // Finds the corresponding lane when two locations are selected
    // If no lane is found, a new lane is created along with a reverse lane (not created if it already exists)
    handleLaneByLocations = async () => {
        if (!this.state.pickup || this.state.pickup === null || !this.state.delivery || this.state.delivery === null) {
            // put error snackbar here
            if (log) console.log("Two locations not set.");
            this.setState({ lane: null });
            return;
        };
        const customerID = parseInt(this.state.customer);
        // const laneRes = await this.context.sdk.lanes.get({customer_id: customerID, origin_location_id: this.state.pickup.id, destination_location_id: this.state.delivery.id})
        const laneRes = await sdk.lanes.get({customer_id: customerID, origin_location_id: this.state.pickup.id, destination_location_id: this.state.delivery.id})
        log && console.log('LANERES', laneRes)
        //check for errors
        if(laneRes.success === false && laneRes.errors && laneRes.errors.length > 0) throw laneRes.errors[0]
        const exists = laneRes.success && laneRes.data && laneRes.data.length > 0
        if (log) { console.log('exists: ' + exists) }
        if (exists) {
            this.context.apolloClient.query({
                query: GET_LANE,
                variables: { origin_id: this.state.pickup.id, destination_id: this.state.delivery.id, customer_id: customerID }
            }).then(res => {
                if (log) console.log("query response:", res.data.lanes)
                const returnArray = res.data.lanes;
                const matchingLane = returnArray.find(o => o.origin_location_id === this.state.pickup.id && o.destination_location_id === this.state.delivery.id);
                this.setState({ lane: matchingLane });
            }).catch(function (err) {
                console.error(err);
                throw err
            });
        } else {
            try {
                // netlify function to create lane
                const response = await axios({
                    method: 'POST',
                    url: '/.netlify/functions/build-lanes',
                    data: {
                        type: 'fromLocations',
                        customer_id: customerID,
                        pickup: { name: this.state.pickup.name, address: this.state.pickup.address, id: this.state.pickup.id },
                        delivery: { name: this.state.delivery.name, address: this.state.delivery.address, id: this.state.delivery.id }
                    }
                })
                let newLanes = response.data

                if (log) { console.log('detailed', newLanes) };
                let res = await this.context.apolloClient.mutate(
                    {
                        mutation: INSERT_LANES,
                        variables: { laneObjects: newLanes }
                    })
                // Set state to the newly created lane that matches the user's chosen locations 
                if (log) { console.log("mutation response: ", res.data.insert_lanes.returning) };
                const returnArray = res.data.insert_lanes.returning;
                const matchingLane = returnArray.find(o => o.origin_location_id === this.state.pickup.id && o.destination_location_id === this.state.delivery.id);
                this.setState({ lane: matchingLane });
            } catch (err) {
                console.error(err);
                this.context.handleNotifications(true, "error", "Failed to create new lane")
            }
        }
    }
    LaneSection = () => {
        return (
            <Query
                query={GET_LANES_AND_LOCATIONS_FOR_CUSTOMER}
                variables={{ customer_id: this.state.customer }}
                // onError={err => {console.error(err);this.context.handleNotifications(true, "error", "Query failed to retrieve customer lanes and locations data")}}
            >
                {({ subscribeToMore, ...result }) => {
                    if (log) console.log("result:", result)
                    if (result.loading) return <div />;
                    if (result.error) return `Error! ${result.error.message}`;
                    return (
                        <LaneSelection
                            handlePreventClick={this.handlePreventClick}
                            handleSetLocation={this.handleSetLocation}
                            handleFavoriteLane={this.handleFavoriteLane}
                            pickup={this.state.pickup}
                            delivery={this.state.delivery}
                            lane={this.state.lane}
                            handleReverseLocations={this.handleReverseLocations}
                            updateLocationById={this.updateLocationById}
                            createEdit={true}
                            // Each table queried will need its own subscribeToMore function defined here and prop-drilled to the child component
                            subscribeToFavoriteLanes={() => {
                                if (log) console.log("subscribe to favorite lanes");
                                subscribeToMore({
                                    document: SUBSCRIBE_TO_FAVORITELANES,
                                    variables: { customer_id: this.state.customer },
                                    updateQuery: (prev, { subscriptionData }) => {
                                        if (log) console.log("fav lanes subscriptionData:", subscriptionData);
                                        if (log) console.log("fav lanes prev:", prev);
                                        if (!subscriptionData.data) return prev;
                                        // return Object.assign({}, prev, subscriptionData.data) // does not appear to work correctly with multiple subscribeToMore functions
                                        return { favoritelanes: subscriptionData.data.favoritelanes }
                                    }
                                })
                            }
                            }
                            subscribeToFavoriteLocations={() => {
                                if (log) console.log("subscribe to favorite locations");
                                subscribeToMore({
                                    document: SUBSCRIBE_TO_FAVORITELOCATIONS,
                                    variables: { customer_id: this.state.customer },
                                    updateQuery: (prev, { subscriptionData }) => {
                                        if (log) console.log("fav locations subscriptionData:", subscriptionData);
                                        if (log) console.log("fav locations prev:", prev);
                                        if (!subscriptionData.data) return prev;
                                        return { favoritelocations: subscriptionData.data.favoritelocations }
                                    }
                                })
                            }
                            }
                            subscribeToLanes={() => {
                                if (log) console.log("subscribe to favorite locations");
                                subscribeToMore({
                                    document: SUBSCRIBE_TO_LANES,
                                    variables: { customer_id: this.state.customer },
                                    updateQuery: (prev, { subscriptionData }) => {
                                        if (log) console.log("lanes subscriptionData:", subscriptionData);
                                        if (log) console.log("lanes prev:", prev);
                                        if (!subscriptionData.data) return prev;
                                        return { lanes: subscriptionData.data.lanes }
                                    }
                                })
                            }
                            }
                            subscribeToLocations={() => {
                                if (log) console.log("subscribe to favorite locations");
                                subscribeToMore({
                                    document: SUBSCRIBE_TO_LOCATIONS,
                                    variables: { customer_id: this.state.customer },
                                    updateQuery: (prev, { subscriptionData }) => {
                                        if (log) console.log("locations subscriptionData:", subscriptionData);
                                        if (log) console.log("locations prev:", prev);
                                        if (!subscriptionData.data) return prev;
                                        return { locations: subscriptionData.data.locations }
                                    }
                                })
                            }
                            }
                            queryData={result.data} // passing the entire query response as a prop to access all retrieved data
                            queryParameters={this.props.location.search}
                            selectedID={this.props.match.params.id}
                        />
                    );
                }}
            </Query>)
    }

    handleTagChanges = (tags) => {
        if (log) console.log('Updating tags: ', tags)
        this.setState({
            tags: tags
        })
    }
    handlePlanChange = (event) => {
        event.preventDefault()
        const driverId = this.state.driver_id;
        this.setState({ previous_driver_id: driverId })
        const { value } = event.target;
        const splitValue = value.split('-')
        this.setState({ plan_id: parseInt(splitValue[0]), driver_id: parseInt(splitValue[1]), synced: true });
    }
    //Tookan Section
    OverrideTimelineSection = () => {
        if (this.state.formType === 'edit') {
            return (
                <Grid container>
                    <Grid container item direction='row' align='center' xs={12}>
                        <Grid item xs={3}>
                            <Typography align='center' variant='body1' gutterBottom={true} className={this.props.classes.typography}>Override Timeline:</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant='body1' gutterBottom={true}>
                                <Switch
                                    color="primary"
                                    checked={this.state.enableOverrideSection}
                                    onChange={() => { this.setState({ enableOverrideSection: !this.state.enableOverrideSection }) }}
                                    value="checkedA"
                                    className={this.props.classes.switch}
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                    {this.state.enableOverrideSection &&
                        <>
                            <Grid container alignContent={'center'} justify='center' xs={12}>
                                {/* <Grid item xs={3}>
                                    <Typography style={{bottom:0}}align='center' variant='body1'>Plan and Driver Select:</Typography>
                                </Grid> */}
                                <Grid item xs={10} >
                                    <Query
                                        query={GET_PLAN_BY_PLANDATE}
                                        variables={{ plan_date: this.state.plan_date || moment().format('YYYY-MM-DD') }}
                                        onError={err => {console.error(err);this.context.handleNotifications(true, "error", "Query failed to retrieve plans data")}}
                                    >
                                        {({ loading, error, data }) => {
                                            if (loading) return "Loading...";
                                            if (error) return `Error! ${error.message}`;
                                            const plans = data.plans.concat([{ id: null, driver_id: null, driver_name: 'No Plan' }]);
                                            return (
                                                <TextField
                                                    select
                                                    id='react-select-plan-driver'
                                                    name={"Change Plan"}
                                                    label={'Plan Select'}
                                                    style={{
                                                        width: 'auto',
                                                        minWidth: '300px'
                                                    }}
                                                    value={`${this.state.plan_id}-${this.state.driver_id}`}
                                                    onChange={this.handlePlanChange}
                                                    margin="normal"
                                                    variant="outlined"
                                                >
                                                    {plans.map(plan => (
                                                        <MenuItem key={plan.driver_name + plan.driver_id} value={`${plan.id}-${plan.driver_id}`}>
                                                            {plan.driver_name} - {plan.id}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )
                                        }}
                                    </Query>
                                </Grid>
                            </Grid>
                            {this.state.pickup_time &&
                                <Grid container alignItems={'center'} xs={12}>
                                    <Grid item xs={3}>
                                        <Typography align='center' variant='body1' gutterBottom={true}>
                                            Pickup Time:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {/* <div>Pickup Time:</div> */}
                                        <SATDateTimePicker
                                            // noDate={true}
                                            handleSubmit={this.handleTimeSubmit}
                                            type={"pickup_time"}
                                            startTime={moment(this.state.pickup_time, moment.ISO_8601)}
                                            offset={{ unit: 'minutes', amount: 0 }}
                                            buttons={false}
                                            quarterHourMode={false}>
                                        </SATDateTimePicker>
                                    </Grid>
                                </Grid>
                            }
                            {this.state.delivery_time &&
                                <Grid container alignContent={'center'} xs={12}>
                                    <Grid item xs={3}>
                                        <Typography align='center' variant='body1' gutterBottom={true}>
                                            Delivery Time:
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {/* <div>Delivery Time:</div> */}
                                        <SATDateTimePicker
                                            // noDate={true}
                                            handleSubmit={this.handleTimeSubmit}
                                            type={"delivery_time"}
                                            startTime={moment(this.state.delivery_time, moment.ISO_8601)}
                                            offset={{ unit: 'minutes', amount: 0 }}
                                            buttons={false}
                                            quarterHourMode={false}>
                                        </SATDateTimePicker>
                                    </Grid>
                                </Grid>
                            }
                            <Grid container item xs={12}>
                                <Grid style={{ paddingLeft: '100px' }} xs={3}>
                                    <Button
                                        onClick={() => { Tookan.handleTookanMoves(this.context.userProfile["https://api_keys.io/jwt/claims"]["TookanKey"], this.context.apolloClient, [this.state]) }}
                                        id='tookanSync'
                                        // type='submit'
                                        color='primary'
                                        size='medium'
                                        variant='outlined'
                                    >
                                        Sync with Tookan
                                </Button>
                                </Grid>
                            </Grid>
                        </>}
                </Grid >
            )
        } else {
            return null
        }
    }

    // render form
    render() {
        const { classes } = this.props;
        return (
            <>
                {this.state.formType === 'edit' && < ManageAccessorials
                    open={this.state.showAccessorialsModal}
                    close={this.handleCloseAccessorialsModal}
                    moveId={parseInt(this.props.match.params.id)}
                />}
                { this.context.userProfile["https://hasura.io/jwt/claims"] && this.context.userIsAuthenticated() && (
                    <Paper className={classes.root}>
                        <Grid className={classes.title} container justify='center' direction='column'>
                            <Typography align='center' variant='h4'>{this.state.formType === 'create' ? "Create Move" : "Edit Move"}</Typography>
                        </Grid>
                        <form onSubmit={this.handleSubmit}>
                            <Grid container>
                                <Grid item container justify='center' xs={12}>
                                    <Grid item xs={12}>
                                        <Typography align='center' className={classes.typography}>Customer Select</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CustomerSelect
                                            value={this.state.customer}
                                            onChange={this.handleCustomerChange}
                                            style={{ width: '50%', marginLeft: '25%', marginRight: '25%' }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <hr />
                                    <Typography align='center' className={classes.typography}>Time Select</Typography>
                                    <hr />
                                </Grid>
                                <this.TimeSection
                                    classes={classes}
                                    active={this.state.hasMounted}
                                >

                                </this.TimeSection>

                                <this.OverrideTimelineSection></this.OverrideTimelineSection>

                                <Grid container>
                                    <VehicleForm
                                        createEdit={true}
                                        stock={this.state.vehicle_stock}
                                        vin={this.state.vehicle_vin}
                                        year={this.state.vehicle_year}
                                        color={this.state.vehicle_color}
                                        make={this.state.vehicle_make}
                                        model={this.state.vehicle_model}
                                        consumer_pickup={this.state.consumer_pickup}
                                        vehicle_consumer_name={this.state.vehicle_consumer_name}
                                        vehicle_consumer_number={this.state.vehicle_consumer_number}
                                        manual={this.state.manual_flag}
                                        instructions={this.state.move_details}
                                        handleChange={this.handleChange}
                                        handleCheckChange={this.handleCheckChange}
                                        handleSelectChange={this.handleSelectChange}
                                        handleAttributeChange={this.handleAttributeChange}
                                        updateVehicleData={this.updateVehicleData}
                                        addVehicle={this.addVehicle}
                                        clearVehicle={this.clearVehicle}
                                        reference_num={this.state.reference_num}
                                    ></VehicleForm>
                                </Grid>
                                <Grid item xs={12}>
                                    <hr />
                                    <Typography align='center' className={classes.typography}>Move Attributes Select</Typography>
                                    <hr />
                                </Grid>
                                <Grid container justify='space-evenly' alignItems='center'>

                                    {this.state.formType === 'edit' &&
                                        <Grid container item direction='column' justify='center' xs={12} style={{ padding: "20px" }}>
                                            <Button
                                                style={{ textTransform: "none !important", margin: "auto" }}
                                                size="large"
                                                variant="contained"
                                                onClick={() => this.setState({ showAccessorialsModal: true })}
                                            >
                                                Manage Accessorials
                                            </Button>
                                        </Grid>
                                    }

                                    <Grid container item direction='column' justify='center' xs={6}>
                                        <Typography align='center' className={classes.bold}>Rate Class</Typography>
                                        <QRadios item
                                            id="class"
                                            name="class"
                                            value={this.state.class}
                                            types={["base", "stranded"]}
                                            onChange={this.handleInputChange}
                                        />
                                    </Grid>
                                    <Grid container item direction='column' justify='center' xs={3}>
                                        <Typography align='center' className={classes.bold}>Rate Class Override</Typography>
                                        <this.OnOffSwitch
                                            value={'rate_class_override'}
                                        />
                                    </Grid>
                                    <Grid container item direction='column' justify='center' xs={3}>
                                        <Typography align='center' className={classes.bold}>Chargeable to Customer</Typography>
                                        <this.OnOffSwitch
                                            value={'chargeable'}
                                        />
                                    </Grid>
                                    <Grid container item direction='column' justify='center' xs={6}>
                                        <Typography align='center' className={classes.bold}>Move Type</Typography>
                                        <QRadios item
                                            id="move_type"
                                            name="move_type"
                                            value={this.state.move_type}
                                            types={["drive", "ride"]}
                                            onChange={this.handleInputChange}
                                        />
                                    </Grid>
                                    <Grid container item direction='column' justify='center' xs={3}>
                                        <Typography align='center' className={classes.bold}>Auto-assign Move</Typography>
                                        <this.OnOffSwitch
                                            value={'auto_assign'}
                                        />
                                    </Grid>
                                    <Grid container item direction='column' justify='center' xs={3}>
                                        <Typography align='center' className={classes.bold}>Payable to Driver</Typography>
                                        <this.OnOffSwitch
                                            value={'payable'}
                                        />
                                    </Grid>
                                    <Grid container item direction='column' justify='center' xs={6}>
                                        <Typography align='center' className={classes.bold}>Ride Type</Typography>
                                        <QRadios item
                                            id="rideType"
                                            name="ride_type"
                                            disabled={this.state.move_type === 'drive'}
                                            value={this.state.ride_type}
                                            types={["lyft", "split", "HopDrive"]}
                                            onChange={this.handleInputChange}
                                        />
                                    </Grid>
                                    <Grid container item direction='column' justify='center' xs={3}>
                                        <Typography align='center' className={classes.bold}>Flag Lyft Pickup</Typography>
                                        <this.OnOffSwitch
                                            value={'lyft_flag'}
                                        />
                                    </Grid>
                                    <Grid container item direction='column' justify='center' xs={3} />
                                    <Grid container item direction='column' justify='center' xs={6}>
                                        <Typography align='center' className={classes.bold}>Tags</Typography>
                                        <ChipInputAutosuggest
                                            label='Tags'
                                            placeholder='Add a tag'
                                            fullWidth
                                            handleChanges={this.handleTagChanges}
                                        />

                                    </Grid>
                                    <Grid container item direction='column' alignItems="center" justify='center' xs={3}>
                                        <Typography align='center' className={classes.bold}>Priority</Typography>
                                        <TextField
                                            select
                                            name="priority"
                                            label={"Priority"}
                                            style={{
                                                width: 'auto',
                                                minWidth: "300px",
                                                minHeight: "200px"
                                            }}
                                            value={this.state.priority}
                                            onChange={this.handleInputChange}
                                            margin="normal"
                                            variant="outlined"
                                        >
                                            {
                                                [{ num: 1, name: 'Higest Priority' },
                                                { num: 2 },
                                                { num: 3 },
                                                { num: 4 },
                                                { num: 5, name: 'Medium Prioriy' },
                                                { num: 6 },
                                                { num: 7 },
                                                { num: 8 },
                                                { num: 9, name: 'Low Priority' }].map(priority => (
                                                    <MenuItem key={'priority' + priority.num} value={priority.num}>
                                                        {priority.num}{priority.name ? ' - ' + priority.name : ''}
                                                    </MenuItem>
                                                ))}
                                        </TextField>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <>
                                        <Grid item xs={12}>
                                            <hr />
                                            <Typography align='center' className={classes.typography}>Lane Select</Typography>
                                            <hr />
                                        </Grid>
                                        {this.state.customer ?
                                            <this.LaneSection />
                                            :
                                            <Typography variant='body1'>
                                                Please Choose a Customer Before Selecting a Lane
                                        </Typography>
                                        }
                                    </>
                                </Grid>
                            </Grid>
                            <Grid container justify='flex-end'>
                                <Grid item xs={2} >
                                    <Button
                                        className={classes.submitButton}
                                        type='submit'
                                        size='medium'
                                        variant='outlined'
                                        // TODO- replace this with actual validation and snackbars
                                        disabled={!this.state.customer || !this.state.lane || !this.state.vehicle_stock || !this.state.vehicle_make || !this.state.vehicle_model || !this.state.ready_by || (this.state.consumer_pickup && (this.state.vehicle_consumer_name.toString().trim().length < 1 || this.state.vehicle_consumer_number.toString().trim().length < 1))}>
                                        {this.state.formType === 'create' ? 'Create Move' : 'Save Changes'}
                                    </Button>
                                </Grid>
                                <Grid item xs={2} >
                                    <Button
                                        className={classes.cancelButton}
                                        size='medium'
                                        variant='outlined'
                                        onClick={this.handleCancel}
                                    >
                                        Cancel
                                </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper >)
                }
            </>
        )
    }
}

MoveCreateEdit.contextType = GlobalContext

export default withRouter(withStyles(styles(theme))(MoveCreateEdit));