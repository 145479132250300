import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Grid, MenuItem, Select, TextField } from "@material-ui/core";
import grey from '@material-ui/core/colors/grey';
import moment from 'moment';
import QRadios from './QRadioButtons'

const log = false

// this adds a "round to next quarter hour" method to moment.js
// https://github.com/moment/moment/issues/959#issuecomment-36091660
moment.fn.roundToQuarterHour = function () {
    var intervals = Math.floor(this.minutes() / 15);
    // if (this.minutes() % 15 !== 0)
    //     intervals++;
    if (intervals === 4) {
        this.add('hours', 1);
        intervals = 0;
    }
    this.minutes(intervals * 15);
    this.seconds(0);
    return this;
}

const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-end',
    },
    textField: {
        display: 'inline-block',
        position: 'relative',
        bottom: '2.4px'
    },
    buttonDiv: {
        paddingLeft: '3rem',
        paddingRight: '3rem',
        padding: '.5rem 4rem',
        justify: "center"
    },
    quickSelect: {
        backgroundColor: grey[300],
        margin: '3px',
        border: '2px',
        // padding: '0px',
        fontSize: '1rem'
    },
    colon: {
        fontWeight: 500,
        color: "black",
        fontSize: '200%',
        marginTop: '35px',
        marginLeft: '20px',
        marginRight: '20px',
        // position: 'relative',
        top: '8px'
    }
});

class SATDateTimePicker extends React.Component {
    constructor(props) {
        const startTime =
        (props.quarterHourMode ?
            props.startTime.add(props.offset['amount'], props.offset['unit']).roundToQuarterHour().format('LLL')
            :
            props.startTime.add(props.offset['amount'], props.offset['unit']).format('LLL'))
        super();
        this.state = {
            start: startTime,
            selected_date: moment(startTime, 'LLL').format('YYYY-MM-DD'),
            selected_time: moment(startTime, 'LLL').format('h:mm A'),
            selected_hour: moment(startTime, 'LLL').format('h'),
            selected_minutes: moment(startTime, 'LLL').format('mm'),
            selected_meridiem: moment(startTime, 'LLL').format('A'),
        }
    }
    // defaults to no time entered, no offset, no buttons, full minute range
    static defaultProps = {
        // startTime: null,
        offset: { unit: 'minutes', amount: 0 },
        buttons: false,
        quarterHourMode: false
    }
    componentDidMount = () => {
        // set ready_by to startTime on mount
        this.props.handleSubmit(this.props.type, moment(`${this.state.selected_date} ${this.timeSetter()}`, 'YYYY-MM-DD h:mm A'))
    }
    // submit times to MoveForm on unmount (w)
    // componentWillUnmount = () => {
    //     this.props.handleSubmit(this.props.type, moment(`${this.state.selected_date} ${this.timeSetter()}`, 'YYYY-MM-DD h:mm A'));
    // }
    // TODO: generate hour array via function so that it always begins with start_time hour
    hourArr = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
    quarterHourArr = ['00', '15', '30', '45']
    fullMinArr = () => {
        let arr = ['00'];
        for (var i = 1; i < 60; i++) {
            if (i < 10) {
                arr.push(`0${i}`)
            } else { arr.push(`${i}`) }
        }
        return arr
    }

    dateSelect = (e) => {
        e.persist()
        this.setState({ selected_date: e.target.value })
    }
    // this function sets hours and minutes states when using dropdowns
    timeSelect = (e) => {
        let name = e.target.name
        this.setState({
            [name]: e.target.value
        })
    }
    // AMPM Radio
    handleAMPM = event => {
        let timeString = moment(this.state.selected_time).format('hh:mm').toString();
        let clickedMeridiem = event.target.value;
        let newTimeString = timeString + " " + clickedMeridiem;
        this.setState({
            selected_meridiem: event.target.value,
            selected_time: moment(newTimeString, 'hh:mm A').format('h:mm A')
        });
    }

    generateQuickTimeButtons(startString, endString) {
        var start = moment(startString, 'h:mm A');
        var end = moment(endString, 'h:mm A');
        // round starting minutes up to nearest 15 (12 --> 15, 17 --> 30)
        var result = [];
        var current = start;
        while (current <= end) {
            result.push(current.format('h:mm A'));
            current.add(15, 'minutes');
        }
        return (
            result.map(time => <Button key={time} onClick={this.quickTimeClick} className={this.props.classes.quickSelect}>{time}</Button>)
        )
    }
    // this function handles setting time via quick select button
    quickTimeClick = (e) => {
        e.persist()
        let clickedTime = e.target.innerText
        let hours = moment(clickedTime, 'h:mm A').format('h').toString()
        let minutes = moment(clickedTime, 'h:mm A').format('mm').toString()
        let AMPM = moment(clickedTime, 'h:mm A').format('A')

        this.setState({ selected_time: clickedTime, selected_hour: hours, selected_minutes: minutes, selected_meridiem: AMPM })
    }

    // this function sets selected time based on the selected hours, minutes, and meridiem
    timeSetter = () => {
        let newTimeString = moment(this.state.selected_hour, 'h').format('hh').toString() + ":" + this.state.selected_minutes.toString() + " " + this.state.selected_meridiem.toString();
        if(log)console.log("newtime", newTimeString)
        return moment(newTimeString, 'hh:mm A').format('h:mm A');
    }

    render() {
        const { classes } = this.props;
        return (
            // changes parent's state onBlur
            <div onBlur={() => this.props.handleSubmit(this.props.type, moment(`${this.state.selected_date} ${this.timeSetter()}`, 'YYYY-MM-DD h:mm A'))}>
                <Grid container className={classes.container} justify="center" spacing={4}>
                    {/* Date picker */}
                    {/* TODO: use something besides native date picker */}
                    {!(this.props.noDate) &&
                    <Grid item noValidate>
                        <TextField
                            id="date"
                            type="date"
                            defaultValue={this.state.selected_date}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={this.dateSelect}
                        />
                    </Grid>}
                    {/* Time Picker */}
                    <Grid item>
                        <div className={classes.textField}>
                            <Select
                                value={this.state.selected_hour}
                                onChange={this.timeSelect}
                                inputProps={{
                                    name: "selected_hour",
                                    id: "hourSelector",
                                }}
                            >
                                {this.hourArr.map(hour => {
                                    return <MenuItem key={hour} value={hour}>{hour}</MenuItem>
                                })}

                            </Select>

                            <span className={classes.colon}>:</span>

                            <Select
                                value={this.state.selected_minutes}
                                onChange={this.timeSelect}
                                inputProps={{
                                    name: "selected_minutes",
                                    id: "minuteSelector",
                                }}
                            >

                                {
                                    this.props.quarterHourMode ?
                                        this.quarterHourArr.map(min => {
                                            return <MenuItem key={min} value={min}>{min}</MenuItem>
                                        })
                                        :
                                        this.fullMinArr().map(min => {
                                            return <MenuItem key={min} value={min}>{min}</MenuItem>
                                        })
                                }

                            </Select>
                        </div>
                    </Grid>
                    <QRadios
                        id="meridiemRadio"
                        name="meridiemRadio"
                        label=''
                        types={["AM", "PM"]}
                        value={this.state.selected_meridiem}
                        onChange={this.handleAMPM}
                    // onBlur={this.someCheckFunc}
                    />
                </Grid>
                <br />
                {this.props.buttons ?
                    <Grid container className={classes.buttonDiv} spacing={4}>
                        {this.generateQuickTimeButtons(moment(this.state.start, 'LLL').format('h:mm A'), moment(this.state.start, 'LLL').add(4, 'hours').format('h:mm A'))}
                    </Grid>
                    :
                    null}
            </div>
        );
    }
}

SATDateTimePicker.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SATDateTimePicker);