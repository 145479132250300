import React from 'react';
import { DialogTitle, DialogContent, DialogContentText, FormControl, DialogActions, Button, IconButton, Select, InputLabel, MenuItem, Typography, CircularProgress } from '@material-ui/core';
import SyncIcon from '@material-ui/icons/Sync';
import { GlobalContext } from '../../../global-context';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import moment from 'moment';
import gql from 'graphql-tag';

const styles = theme => ({
  form: {
    margin: theme.spacing(2),
    minWidth: 300,
    display: "block",
    float: "left",
  },
  button: {
    margin: theme.spacing(1),
  },
  boldTxt: {
    fontWeight: "bold"
  },
  subText: {
    textAlign: "right",
    fontWeight: "bold",
    position: "relative",
    top: "-30px",
    marginBottom: theme.spacing(-3)
  },
  select: {
    minWidth: 250,
  },
});

class MarkSelectedPaid extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      ACHTransaction: '',
      suggestions: [],
      loading: false,
      move_ids: [],
      savedPayment: false,
      sentEmail: false,
    }
  }

  componentWillMount = async () => await this.setState({ move_ids: this.props.appayments.map(o => o.move_id) })

  componentDidMount = () => this.handleTransactionSuggestions();

  handleChange = event => this.setState({ ACHTransaction: event.target.value });

  handleTransactionSuggestions = async () => {
    this.setState({ loading: true });
    setTimeout(() => this.setState({ loading: false }), 3000);
    await axios({
      method: 'post',
      url: '/.netlify/functions/handleSuggestedACHTransactions',
      data: { name: this.props.appayments[0].move.driver_name, subtotal: this.props.subtotal, date: moment().subtract(5, "days").format("YYYY-MM-DD") }, // https://stackoverflow.com/questions/9509360/datepicker-date-off-by-one-day
      headers: {
        'authorization': `Bearer ${this.context.userToken}`
      }
    }).then(res => {
      console.log("handleTransactionSuggestions res:", res)
      let suggestions;
      try {
        suggestions = res.data;
      } catch (err) {
        this.context.handleNotifications(true, "error", "Failed to retrieve recent ACH transactions: " + err.toString())
        this.setState({ loading: false, suggestions: [], ACHTransaction: '' });
      }
      if (!suggestions || suggestions.length < 1) {
        this.context.handleNotifications(true, "warning", "No matching transactions found.")
        this.setState({ loading: false, suggestions: [], ACHTransaction: '' });
      } else this.setState({ suggestions: suggestions, ACHTransaction: suggestions[0].transaction, loading: false });
    })
      .catch(err => {
        this.context.handleNotifications(true, "error", "Failed to retrieve recent ACH transactions: " + err.toString())
        this.setState({ loading: false, suggestions: [], ACHTransaction: '' });
      })
  }

  handleSaveAPPayment = () => {
    this.setState({ loading: true });
    try {
      this.context.apolloClient.mutate({
        mutation: gql`
          mutation updateAPPayemnt(
            $move_ids: [bigint!]!
            $txn_id: String!
            ) {
            update_appayments(
              where: {move_id: {_in: $move_ids}}
              _set: {
                status: "paid"
                ach_transaction_id: $txn_id
                updatedat: "now()"
              }
              ) {
                affected_rows
                returning {
                  id
                }
              }
            }
          `,
        variables: {
          move_ids: this.state.move_ids,
          txn_id: this.state.ACHTransaction.toString(),
        },
        // onError: this.handleError("Failed to update AP payment."),
      })
        .then(async res => {
          this.setState({ loading: false });
          if (res.data.update_appayments.affected_rows > 0) {
            let qty = res.data.update_appayments.affected_rows;
            await this.handleUpdateQuickbooksPayment(this.state.ACHTransaction);
            // this.context.handleNotifications(true, "success", `Updated ${qty} AP ${Number(qty) > 1 ? 'payments' : 'payment'}.`);
            this.props.refetch()
            this.props.setShowMarkPaid(false);
            this.setState({savedPayment:true})
            this.props.setSelected({ all: false })
          }
        })
        .catch(err => this.handleError(`Failed to update AP payment: ${err.toString()}`))
    } catch (err) {
      console.error(err);
      this.context.handleNotifications(true, "error", `Failed to update AP payment`);
      this.setState({ loading: false });
    }
  }

  handleError = msg => {
    this.setState({ loading: false });
    this.context.handleNotifications(true, "error", msg)
  }

  handleUpdateQuickbooksPayment = async txn_id => {
    await axios({
      method: 'post',
      url: '/.netlify/functions/handleSuggestedACHTransactions',
      data: { update: true, transaction: txn_id, document: "TEST", appayments: this.props.appayments },
      headers: {
        'authorization': `Bearer ${this.context.userToken}`
      }
    })
  }

  handleSendEmail = () => {
    this.setState({loading: true})
    this.props.sendEmail().then(res=> {
      if (res===true){
        this.setState({sentEmail: true, loading: false})
      }else{
        this.setState({loading: false})
      }
    })
    
  }

  render() {
    const { classes, appayments, subtotal } = this.props
    return (
      appayments && appayments.length > 0 && <>
        <DialogTitle>
          Mark Selected Paid
          <Typography component="div" variant="h6" color="textSecondary" className={classes.subText}>
            ${subtotal}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Mark all <b className={classes.boldTxt}>{appayments.length}</b> of the selected unpaid AP records as paid to <b className={classes.boldTxt}>{appayments[0].move.driver_name}</b> via ACH. Please select the matching transaction from the list of recent driver pay direct deposits found in the accounting system. Use the refresh button to fetch again if you've made the ACH while in this modal
        </DialogContentText>
          {!this.state.loading ? (
            <FormControl className={classes.form}>
              <InputLabel>ACH Transaction</InputLabel>
              <Select
                className={classes.select}
                autoWidth
                value={this.state.ACHTransaction}
                onChange={this.handleChange}
              >
                {
                  Array.from(this.state.suggestions || []).map((suggestion, index) => <MenuItem key={`transaction-${suggestion.transaction}`} value={suggestion.transaction}>{suggestion.name} - ${suggestion.amount} {index === 0 ? "(suggested)" : null} </MenuItem>)
                }
              </Select>
              <IconButton aria-label="Refresh"
                // onClick={() => this.handleUpdateQuickbooksPayment(this.state.ACHTransaction)} // for testing porpoises
                onClick={this.handleTransactionSuggestions}
              >
                <SyncIcon />
              </IconButton>

            </FormControl>
          ) : (
              <CircularProgress
                style={{
                  display: "block",
                  margin: "auto",
                  position: "absolute",
                  left: "0",
                  right: "0",
                }}
              />
            )
          }
        </DialogContent>
        <DialogActions>
          <Button
            disabled={this.state.loading || this.state.sentEmail}
            className={classes.button}
            onClick={() => this.handleSendEmail()}
            size="small"
            color="primary"
            variant="outlined"
          >
            Send Email
          </Button>
          <Button
            disabled={this.state.loading || this.state.savedPayment || !(this.state.ACHTransaction)}
            className={classes.button}
            size="small"
            color="primary"
            variant="outlined"
            onClick={() => this.handleSaveAPPayment()}
          >
            Mark Paid
          </Button>
          <Button
            disabled={this.state.loading}
            className={classes.button}
            size="small"
            color="primary"
            variant="outlined"
            onClick={() => this.props.setShowMarkPaid(false)}
          >
            Close
          </Button>
        </DialogActions>
      </>
    )
  }
}

MarkSelectedPaid.contextType = GlobalContext;

export default withStyles(styles)(MarkSelectedPaid);