import React, { useContext } from 'react';
import { GlobalContext } from '../../../global-context';

import gql from 'graphql-tag';
import fragments from '../../utils/graphQL/fragments';

import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from '../../reusable/ModalComponents';

const log = false;

////////// COMPONENT //////////
export default function LaneAddEditModal(props) {
  const ctx = useContext(GlobalContext);

  const { open, onClose, laneInput } = props;

  const handleSubmit = async () => {
    try {
      let res = await ctx.apolloClient.mutate({
        mutation: UPSERT_LANE,
        variables: { laneObjects: [laneInput] },
      });
      if (res && res.data.insert_lanes.returning.length > 0) {
        const resLane = res.data.insert_lanes.returning[0];
        log && console.log(`>> UPSERTED Lane:`, resLane);
        ctx.handleNotifications(true, `success`, `Lane: '${resLane.description}' was successfully upserted.`);
        handleClose();
      }
    } catch (err) {
      console.log(`Error upserting lane:`, err);
      ctx.handleNotifications(true, `error`, `Failed to upsert lane: ${err.toString()}`);
    }
  };
  const handleClose = () => {
    if (onClose) onClose();
  };

  return (
    <>
      <Modal open={open}>
        <ModalHeader handleClose={handleClose}>Save Lane</ModalHeader>

        <ModalContent subtitle={`Are you sure you want to save this lane?`} />

        <ModalFooter>
          <ModalAction onClick={() => handleSubmit()}>Confirm</ModalAction>
          <ModalAction onClick={() => handleClose()} color='default'>
            Cancel
          </ModalAction>
        </ModalFooter>
      </Modal>
    </>
  );
}

////////// GRAPHQL //////////
const UPSERT_LANE = gql`
  mutation upsert_lanes($laneObjects: [lanes_insert_input!]!) {
    insert_lanes(
      objects: $laneObjects
      on_conflict: {
        constraint: lanes_pkey
        update_columns: [
          average_drive_speed_min_per_mile
          average_drive_speed_mph
          createdat
          dealer_base_discount
          dealer_base_price
          dealer_base_rate
          dealer_base_rate_type
          dealer_stranded_discount
          dealer_stranded_price
          dealer_stranded_rate
          dealer_stranded_rate_type
          delivery_inspection_sec
          description
          destination_location_id
          distance_miles
          driver_base_pay
          driver_base_pay_discount
          driver_drive_pay
          driver_pay_per_kilometer
          driver_pay_per_mile
          driver_pay_per_minute
          driver_rake
          driver_return_pay
          driver_return_pay_discount
          driver_time_pay
          duration_sec
          estimated_rideshare_return_cost
          insurance_cost
          insurance_cost_per_mile
          tolls
          updatedat
        ]
      }
    ) {
      returning {
        ...Lane
      }
    }
  }
  ${fragments.lane}
`;
