import React, { useState, useContext, useEffect } from 'react';
import { GlobalContext } from '../../../global-context';
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
} from '@material-ui/core';
import axios from 'axios';
import gql from 'graphql-tag';
import Loading from '../../utils/Loading';
// import { makeStyles } from '@material-ui/core/styles';
// import { withStyles } from '@material-ui/core/styles'

let log = false;
export default function PostPaymentModal(props) {
  // const useStyles = makeStyles(theme => ({
  //     pad1:{paddingBottom: theme.spacing(4)},
  // }))
  // const classes = useStyles()

  const {
    open,
    armoves,
    manualPaymentMethodId,
    setManualPaymentMethodId,
    selectedInvoice,
    openModal,
    setCheckedItems,
  } = props;
  const context = useContext(GlobalContext);

  const [paymentAmount, setPaymentAmount] = useState(0);
  const [refNum, setRefNum] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (armoves.length > 0) {
      const totalPayment = armoves
        .map(o => o.due_amount - o.discount_amount)
        .reduce((total, currentValue) => total + currentValue)
        .toFixed(2);
      setPaymentAmount(totalPayment);
    }
  }, [armoves]);

  const createManualPaymentMethod = async () => {
    setLoading(true);
    try {
      context.apolloClient
        .mutate({
          mutation: INSERT_MANUAL_PAYMENT_METHOD,
          variables: {
            customer_id: selectedInvoice.customer.id,
            name: selectedInvoice.customer.name + ' Manual Payment Method',
          },
        })
        .then(res => {
          console.log(`Created manual payment method res:`, res);
          if (res.data) {
            //For safety
            let newMethodId = Number(res.data.insert_arpaymentmethods.returning[0].id) || null;
            //success notification
            // context.handleNotifications(true, "success", `Manual Payment Method #${newMethodId} Created!`);
            setManualPaymentMethodId(newMethodId);
            postPayment(newMethodId);
          } else {
            //error notication
            context.handleNotifications(
              true,
              'error',
              `Failed to Create Manual Payment Method: ${JSON.stringify(res)}`
            );
            //close
            setLoading(false);
          }
        })
        .catch(err => {
          console.error(err);
          context.handleNotifications(true, 'error', `Failed to create manual payment method: ${JSON.stringify(err)}`);
          setLoading(false);
          openModal(false);
        });
    } catch (err) {
      console.log(`An error occured while attempting to post payment:`, err);
    }
  };

  const postPayment = async newPaymentMethod => {
    setLoading(true);
    console.log('postPayment', newPaymentMethod, manualPaymentMethodId, paymentAmount, refNum);
    try {
      return await axios({
        method: `POST`,
        url: `/.netlify/functions/postPaymentAR`,
        data: {
          armoves: armoves,
          manualPaymentMethodId: newPaymentMethod || manualPaymentMethodId,
          invoiceId: selectedInvoice.id,
          paymentAmount: paymentAmount,
          refNum: refNum,
        },
        headers: {
          authorization: `Bearer ${context.userToken}`,
        },
      })
        .then(res => {
          console.log(`Post payment response:`, res);
          if (res.status === 200) {
            //success notification
            context.handleNotifications(true, 'success', `Payment posted successfully!`);
            //close
            setLoading(false);
            openModal(false);
            setCheckedItems([]);
          } else {
            //error notication
            context.handleNotifications(true, 'error', `Failed to post payment: ${res.message}`);
            //close
            setLoading(false);
            openModal(false);
          }
        })
        .catch(err => {
          console.error(err);
          context.handleNotifications(true, 'error', `Failed to post payment: ${err.message || JSON.stringify(err)}`);
          setLoading(false);
          openModal(false);
        });
    } catch (err) {
      console.log(`An error occured while attempting to post payment:`, err);
    }
  };
  //COMPONENT
  return (
    <Dialog open={open} aria-labelledby='post-payment-modal'>
      <DialogTitle id='post-payment-modal-title'>Post Payment Confirmation</DialogTitle>
      <DialogContent>
        <Grid container justify='space-between'>
          <Grid item xs={12}>
            <Typography style={{ paddingBottom: '1em' }}>
              You are about to post a payment to this invoice manually. This will generate a payment record in this
              system as well as in the accounting system. Please confirm the total below as well as the list of moves
              being paid for in this payment.
            </Typography>
          </Grid>
          <br />
          <Grid item xs={7}>
            <Typography>
              If one is available, please enter the reference number provided by the payment processor.
            </Typography>
            <form>
              <TextField
                id='outlined-basic'
                variant='outlined'
                onChange={event => {
                  setRefNum(event.target.value);
                }}
                fullWidth={true}
              />
            </form>
          </Grid>
          <Grid item xs={4}>
            <Typography>
              Moves Count: <span style={{ fontWeight: 'bold' }}>{armoves.length}</span>
            </Typography>
            <Typography>
              Total Paid: <span style={{ fontWeight: 'bold' }}>{' $' + paymentAmount}</span>
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            openModal(false);
          }}
          color='primary'
        >
          {' '}
          Cancel{' '}
        </Button>
        <Button
          onClick={async () => {
            !manualPaymentMethodId ? await createManualPaymentMethod() : await postPayment();
          }}
          disabled={loading}
          color='primary'
        >
          {' '}
          Mark as Paid{' '}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
const INSERT_MANUAL_PAYMENT_METHOD = gql`
  mutation insert_manual_payment_method($customer_id: bigint!, $name: String!) {
    insert_arpaymentmethods(
      objects: {
        active: true
        account_number: 0
        createdat: "now()"
        customer_id: $customer_id
        gateway_token: "manual"
        name: $name
        primary: false
        source: "admin site"
        type: "manual"
        updatedat: "now()"
      }
    ) {
      affected_rows
      returning {
        id
        name
        customer {
          id
          name
        }
      }
    }
  }
`;
