import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';


let log = false;

/**
  * Present a single value item for a value list
  * @param {String} label - Text for the left side of the colon
  * @param {String} value - Text for the right side of the colon
  * @param {Boolean} divider - Divider will be rendered above if true
  * @param {Boolean} gray - Background color will be light gray if true
*/
const ValueListItem = ({ label, value, divider = false, gray = false }) => {
  const cls = useStyles()

  return (<>
    {divider && <div className={cls.lineBreak} />}
    <div className={`${cls.listItem} ${gray ? cls.grayRow : ''}`}>
        <Typography className={cls.keyTxt}>{label}:</Typography>
        <Typography className={cls.valTxt}>{value || `-`}</Typography>
    </div>
  </>)
}

export default ValueListItem;

const useStyles = makeStyles(theme => ({
  lineBreak: {
    width: '100%',
    height: '1px',
    background: '#eee',
  },
  listItem: {
    display: 'flex',
    flexWrap: 'nowrap',
    padding: '6px',
  },
  grayRow: {
    backgroundColor: '#fbfbfb'
  },
  keyTxt: {
    color: theme.palette.text.secondary,
    textAlign: 'left',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
    whiteSpace: 'nowrap'
  },
  valTxt: {
    width: '100%',
    textAlign: 'right',
    lineHeight: 1.25,
    fontSize: '14px',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
}));