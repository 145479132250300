import React, { useContext, useEffect, useState } from 'react';
import gql from "graphql-tag";
import fragments from '../utils/graphQL/fragments';
import { GlobalContext } from '../../global-context';
import { makeStyles, Typography, InputLabel, OutlinedInput, InputAdornment, Grid, DialogTitle, DialogContent, DialogContentText, FormControl, FormLabel, FormGroup, FormControlLabel, Tabs, Tab, TabPanel, Dialog, DialogActions, Button, IconButton, Icon, Tooltip, Switch } from '@material-ui/core';
import BigButtonGroup from './BigButtonGroup';
import BigButton from './BigButton';
import ValueListItem from './ValueListItem';
import sdk from "@hopdrive/sdk";

let log = false;

const getAccessorials = (type = 'query') => (gql`
    ${type} getAccessorials($id: bigint!) {
      accessorials(where: { move_id: {_eq: $id} } ) {
          ...Accessorials
          move {
            accountsReceivable {
              status
            }
          }
          appayment {
            ...APPayment
          }
        }
      }
      ${fragments.accessorials}
      ${fragments.appayment}
    `);

/**
  * Add or remove spend authorizations to a move
  * @param {BigInteger} moveId  - Requires a move ID passed in as a prop
  * @param {Boolean} open - Pass in a boolean value of true to render the dialog
  * @param {Function} close - Function to toggle the 'open' value from true to false
*/
const SpendAuthorization = ({ open, close, save, moveId }) => {
  const cls = useStyles();
  const ctx = useContext(GlobalContext)

  const [type, setType] = useState(sdk.accessorials.types.FUEL.code)
  const [maxSpend, setMaxSpend] = useState(0)
  const [isUsingCompanyCard, setIsUsingCompanyCard] = useState(true)
  const [selectedTab, setSelectedTab] = useState(0)
  const [accessorial, setAccessorial] = useState(null)

  useEffect(() => {
    log && console.log("MoveID changed", moveId)
  }, [moveId])

  const handleTypeChange = (id, title) => {
    setType(id)
    console.log(`${title} type selected`)
  }

  useEffect(() => {
    //Build the returning object whenever components of it are updated
    let accessorial

    switch (type) {
      case sdk.accessorials.types.FUEL.code:
        accessorial = sdk.accessorials.buildFuelAccessorial({
          moveId: moveId,
          cost: maxSpend,
          fuelMultiplier: 1.5,
          calcDriverTimePay: true,
          payPerMinute: 0.17,
          durationSeconds: 300,
          costBearer: isUsingCompanyCard ? 'company' : 'driver',
          notes: `Spend auth modal ${isUsingCompanyCard ? 'using company credit card' : 'using driver personal funds'}`
        })
        if (isUsingCompanyCard) {
          accessorial.authorization = {
            data: sdk.authorizations.buildAuthorization({
              maxCharge: maxSpend
            })
          }
        }
        break;
    
      default:
        accessorial = null
        break;
    }

    setAccessorial(accessorial)
  }, [type, maxSpend, isUsingCompanyCard])

  const handleSave = () => {
    console.log('Spend auth modal saved with the following: ', accessorial);
    sdk.configure({ apollo_client: ctx.apolloClient });
    sdk.accessorials.create(accessorial);
    save()
    close()
  }

  return (
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="spend-authorization-modal"
    >
      <DialogTitle id="spend-authorization-modal">Spend Authorization</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>
          Authorize the driver to spend some money on this move then record it as an accessorial.
        </DialogContentText>
        <BigButtonGroup onChange={handleTypeChange} preselect={type}>
          <BigButton id={sdk.accessorials.types.FUEL.code} title="Fuel" subtitle="Put gas in the vehicle being moved" />
          <BigButton disabled id={sdk.accessorials.types.TOW.code} title="Tow" subtitle="Prepay for a tow of the vehicle being moved" />
          <BigButton disabled id={'other'} title="Other" subtitle="Other misc spend type" />
        </BigButtonGroup>
        <FormControl>
          <FormGroup>
            <Grid container spacing={4}>
              <Grid item md={6} xs={12}>
                <Typography variant="subtitle2" display="block" gutterBottom>Who is initially covering the cost of the fuel? </Typography>
                <Typography variant="body2" display="block" gutterBottom>If the driver has a credit card provided by us and you
                intend for them to use it then leave this enabled. If instead the driver will need to use their
                own personal funds and then be reimbursed on their driver pay, then disable this toggle.</Typography>
                <FormControlLabel
                  control={<Switch checked={isUsingCompanyCard} onChange={e => {setIsUsingCompanyCard(e.target.checked)}} name="checkedA" />}
                  label={`Use company fuel card${!isUsingCompanyCard ? ' (Driver will use their personal funds)' : ''}`}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Typography variant="subtitle2" display="block" gutterBottom>What's the max dollar amount you want to authorize? </Typography>
                <Typography variant="body2" display="block" gutterBottom style={{marginBottom: '20px'}}>For swiping at the pump,
                this should work like prepaying inside and automatically stop the pump when it reaches this amount.</Typography>
                <FormControl fullWidth variant="outlined">
                  <InputLabel htmlFor="max-spend">Max Authorized Spend</InputLabel>
                  <OutlinedInput
                    id="max-spend"
                    value={maxSpend}
                    onChange={(e) => setMaxSpend(e.target.value)}
                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    labelWidth={175}
                  />
                </FormControl>
              </Grid>
              <Grid item md={12} xs={12}>
                <Tabs
                  value={selectedTab}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={(e, v) => setSelectedTab(v)}
                  aria-label="Accessorial preview"
                >
                  <Tab label="Preview" />
                  <Tab label="Source" />
                </Tabs>
                {selectedTab == 0 &&
                <>
                  <ValueListItem label="Code" value={accessorial && accessorial.code} />
                  <ValueListItem label="Status" gray value={accessorial && accessorial.status} divider />
                  <ValueListItem label="Cost" value={accessorial && accessorial.cost && '$' + accessorial.cost.toFixed(2)} divider />
                  <ValueListItem label="Payable (driver)" gray value={accessorial && accessorial.ap_amount && '$' + accessorial.ap_amount.toFixed(2)} divider />
                  <ValueListItem label="Chargeable (customer)" value={accessorial && accessorial.ar_amount && '$' + accessorial.ar_amount.toFixed(2)} divider />
                  <ValueListItem label="Notes" gray value={accessorial && accessorial.notes} divider />
                  <ValueListItem label="Card Authorization" value={accessorial && accessorial.cost && '$' + accessorial.cost.toFixed(2)} divider />
                </>}
                {selectedTab == 1 &&
                <>
                  <pre>{JSON.stringify(accessorial, null, 2)}</pre>
                </>}
              </Grid>
            </Grid>
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Cancel
        </Button>
        <Button disabled={maxSpend <= 0} onClick={handleSave} color="primary" autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SpendAuthorization;

const useStyles = makeStyles(theme => ({
  margin: {
    marginBottom: '15px'
  },
}));