import gql from "graphql-tag";
import fragments from './fragments';

const mutations = {
  updateVehicleInfo: gql`
    mutation updateVehicleInfo(
      $id: bigint!
      $color: String!
      $year: String!
      $make: String!
      $model: String!
      $stock: String!
      $vin: String!
      $odometer: String!
    ) {
      update_moves(
        where: { id: { _eq: $id } }
        _set: {
          vehicle_color: $color
          vehicle_year: $year
          vehicle_make: $make
          vehicle_model: $model
          vehicle_stock: $stock
          vehicle_vin: $vin
          vehicle_odometer: $odometer
          updatedat: "now()"
        }
      ) {
        returning {
          ...Move
        }
      }
    }
    ${fragments.move}
  `,
  updateLaneInfo: gql`
    mutation updateLaneInfo(
      $id: bigint!
      $laneId: bigint!
    ) {
      update_moves(
        where: { id: { _eq: $id } }
        _set: {
          lane_id: $laneId
          updatedat: "now()"
        }
      ) {
        returning {
          ...Lane
        }
      }
    }
    ${fragments.lane}
  `,
  addNewMove: gql`
  mutation add_moves(
    $movesObjectArray: [moves_insert_input!]!){
    insert_moves(objects: $movesObjectArray) {
      returning {
        ...Move
      }
    }
   }
   ${fragments.move}
  `,
  addNewLane: gql`
    mutation add_lanes(
      $lanes: [lanes_insert_input!]!){
      insert_lanes(objects: $lanes) {
        returning {
          ...Lane
        }
      }
    }
    ${fragments.lane}
    `,
};

export default mutations;