import React, { useContext } from 'react';
import { GlobalContext } from '../../../global-context';
import { makeStyles, Button } from '@material-ui/core';
import GoogleStaticMapURL from '../../utils/GoogleStaticMapUrl';

////////// COMPONENT //////////
export default function LocationDetailsMap(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const { location } = props;

  const staticMapUrl = location =>
    GoogleStaticMapURL.build({
      key: ctx.userProfile['https://api_keys.io/jwt/claims']['GoogleMapsKey'],
      markers: [
        {
          icon: 'https://s3.ap-south-1.amazonaws.com/social-auto/acknowledgement_images/fkpH1532077213498-faviconsavein32x32.png',
          shadow: true,
          lat: location.latitude,
          lon: location.longitude,
        },
      ],
      center: 'auto',
      zoom: 'auto',
      scale: false,
      size: '574x477',
      maptype: 'roadmap',
      format: 'png',
      visual_refresh: true,
    });

  return (
    <>
      <div className={cls.main}>
        <div className={cls.paper}>
          <img className={cls.map} src={staticMapUrl(location)} alt={`location-img`} />

          <div className={cls.actions}>
            <a href={`https://maps.google.com/?q=${location.address}`} target='_blank' rel='noopener noreferrer'>
              <Button className={cls.action} size='large' variant='outlined' color='secondary'>
                Open In Maps
              </Button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  main: {},
  paper: {
    position: 'relative',
    width: '100%',
    padding: theme.spacing(2),
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  map: {
    display: 'block',
    width: '100%',
    maxWidth: '574px',
    border: theme.border[0],
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  action: {
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));
