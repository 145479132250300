import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
// import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import Fab from '@material-ui/core/Fab';
import CancelIcon from '@material-ui/icons/Cancel';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import Favorite from '@material-ui/icons/Favorite';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { GlobalContext } from '../../global-context';
import gql from "graphql-tag";
import LocationWithNickname from './LocationWithNickname';

let log = false;

const locIcon = require('../../static/location-icon.png');

const styles = theme => ({
  root: {
    flexGrow: 1,
    // display: 'inline-flex',
    width: "100%",
  },
  input: {
    display: 'flex',
    width: "92%",
    padding: 0,
    // float: "left"
  },
  fab: {
    boxShadow: "none !important",
    backgroundColor: "transparent !important",
    // display: "block",
    float: "right",
    top: "-55px",
    alignSelf: "center",
    transition: "all .1s ease-in-out",
    "&:hover": {
      transform: "scale(1.15)"
    },
  },
  valueContainer: {
    display: 'flex',
    // flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  chip: {
    margin: `${theme.spacing(1) / 2}px ${theme.spacing(1) / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing(2),
  },
  selectInput: {
    opacity: "1 !important"
  },
});


class LocationSelect extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      location: null,
    }
  }

  componentWillMount = () => {
    this.setState({ location: this.props.location })
  }

  NoOptionsMessage = (props) => {
    return (
      <Typography
        color="textSecondary"
        className={props.selectProps.classes.noOptionsMessage}
        {...props.innerProps}
      >
        {props.children}
      </Typography>
    );
  }

  inputComponent = ({ inputRef, ...props }) => {
    return <div ref={inputRef} {...props} />;
  }

  Control = (props) => {
    return (
      <TextField
        fullWidth
        style={{minWidth: "10rem"}}
        InputProps={{
          inputComponent: this.inputComponent,
          inputProps: {
            className: props.selectProps.classes.input,
            inputRef: props.innerRef,
            children: props.children,
            ...props.innerProps,
          },
        }}
        {...props.selectProps.textFieldProps}
      />
    );
  }

  Option = (props) => {
    return (
      <MenuItem
        buttonRef={props.innerRef}
        selected={props.isFocused}
        component="div"
        style={{
          fontWeight: props.isSelected ? 500 : 400,
        }}
        {...props.innerProps}
      >
        <img style={{ marginRight: "8px" }} src={locIcon} alt="location pin"></img>
        <LocationWithNickname updateLocationById={this.props.updateLocationById} location={props.data.value} />

      </MenuItem>
    );
  }

  Placeholder = (props) => {
    return (
      <Typography
        color="textSecondary"
        className={props.selectProps.classes.placeholder}
        {...props.innerProps}
      >
        {props.children}
      </Typography>
    );
  }

  SingleValue = (props) => {
    if (log) console.log("log for single value:", props)
    return (
      <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
        {props.children}
      </Typography>
    );
  }

  ValueContainer = (props) => {
    if (log) console.log("value container props:", props);
    if (log) console.log("value container props:", props.children);
    return <Typography component="div" inline="true" className={props.selectProps.classes.valueContainer} style={{ fontSize: "110%" }} color="textPrimary"> {props.children} <Typography inline="true" color="textSecondary" style={{ marginLeft: "5px", marginBottom: "-5px" }}> {this.state.location ? this.state.location.address : null} </Typography> </Typography>;
  }

  MultiValue = (props) => {
    return (
      <Chip
        tabIndex={-1}
        label={props.children}
        className={classNames(props.selectProps.classes.chip, {
          [props.selectProps.classes.chipFocused]: props.isFocused,
        })}
        deleteIcon={<CancelIcon {...props.removeProps} />}
      />
    );
  }

  Menu = (props) => {
    return (
      <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
        {props.children}
      </Paper>
    );
  }

  components = {
    Control: this.Control,
    Menu: this.Menu,
    MultiValue: this.MultiValue,
    NoOptionsMessage: this.NoOptionsMessage,
    Option: this.Option,
    Placeholder: this.Placeholder,
    SingleValue: this.SingleValue,
    ValueContainer: this.ValueContainer,
  };

  componentWillReceiveProps = async props => {
    if (log) console.log("will receive props.location of", props.location)
    if (props.location !== this.state.location) {
      await this.setState({ location: props.location })
    }
  }

  handleInputChange = (event) => {
    if (log) console.log(event ? event.target.value : "no event value");
    this.setState({ location: event.target.value })
  }

  handleSelectChange = name => event => {
    if (log) console.log("handleSelectChange event:", event ? event : "no event value");
    this.setState({ location: event ? event.value : "" }, () => {
      this.props.handleSetLocation(name, event ? event.value : null);
    })
  }

  renderHtml = (name) =>
    <h1 style={{ color: "blue" }}>{name}</h1>;

  addNewFavoriteLocation = (location) => {
    this.context.apolloClient.mutate({
      mutation: gql`
          mutation insertfavoritelocations(
              $customer_id: bigint!
              $location_id: bigint!
            ){
            insert_favoritelocations(
              objects: {customer_id: $customer_id, 
              location_id: $location_id}) 
              {
              returning {
                location_id
                customer_id
              }
            }
          }
        `,
        variables: { customer_id: location.customer_id, location_id: location.id},
    })
  }

  deleteFavoriteLocation = (location) => {
    // this.context.deleteFavLocation(location)
    this.context.apolloClient.mutate({
      mutation: gql`
          mutation deletefavoritelocations(
            $location_id: bigint! 
            ) {
            delete_favoritelocations(
              where: {location_id: {_eq: $location_id}}) {
              returning {
                location_id
              }
            }
          }
        `,
        variables: { location_id: location.id },
    })
  }

  handleFavoriteLocation = (location, classes) => {
    if (!location || !this.props.queryData) return null;
    // this.props.queryData.favoritelocations.length < 1
    for (let favorite of Array.from(this.props.queryData.favoritelocations || [])) {
      if (location.id === favorite.location.id) return <Fab size="small" disableRipple onClick={() => this.deleteFavoriteLocation(this.state.location)} className={classes.fab}>
        <Favorite color="error" />
      </Fab>
    }
    return <Fab size="small" disableRipple onClick={() => this.addNewFavoriteLocation(this.state.location)} className={classes.fab}>
      <FavoriteBorder color="error" />
    </Fab>;
  }



  render() {
    const { classes, theme, formLabel } = this.props;
    const selectStyles = {
      input: base => ({
        ...base,
        color: theme.palette.text.primary,
        '& input': {
          font: 'inherit',
        },
      }),
    },
      locationType = formLabel,
      locations = this.props.queryData ? (this.props.queryData.locations ? this.props.queryData.locations.map(loc => ({
        value: loc,
        label: loc.nickname ? loc.nickname + " (" + loc.name + ")" : loc.name,
      })) : []) : [];

    return (
      <div className={classes.root}>
        {/* <NoSsr> */}
          <Select
            classes={classes}
            styles={selectStyles}
            textFieldProps={{
              onChange: this.handleInputChange,
              value: this.state.location ? this.state.location : "",
              InputLabelProps: {
                shrink: true,
              },
            }}
            inputValue={this.state.location ? (this.state.location.nickname ? this.state.location.nickname + " (" + this.state.location.name + ")" : this.state.location.name) : ""}
            options={locations}
            components={this.components}
            onChange={this.handleSelectChange(locationType)}
            placeholder="Search for a saved location"
            isClearable
            variant="outlined"
            id={`react-select-location`}
          />
          <div className={classes.divider} />
          {this.handleFavoriteLocation(this.state.location, classes)}
        {/* </NoSsr> */}
      </div>
    );
  }
}

LocationSelect.contextType = GlobalContext;

LocationSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(LocationSelect);