import React from 'react';
import { withStyles, Grid } from '@material-ui/core';
import moment from 'moment';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
  },
  date: {
    width: '132px',
  },
  resize: {
    fontSize: '14px',
  },
})

class DateFilter extends React.Component {

  handleDateChange = (name, value) => {
    this.props.handleChange(name, value)
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.root} justify='center'>
        <Grid item xs={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              disabled={this.props.dateRangeLabel !== 'custom range'}
              className={classes.date}
              style={{ marginRight: '6px' }}
              variant="inline"
              format="MM/dd/yyyy"
              margin="dense"
              id="startDate"
              label="Start Date"
              value={moment(this.props.startDate).add('day')}
              onChange={event => event ? this.handleDateChange(`startDate`, moment(event).format('YYYY-MM-DD')) : null}
              InputProps={{
                classes: {
                  input: classes.resize,
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              KeyboardButtonProps={{
                'aria-label': 'change start date',
              }}
            />
            <KeyboardDatePicker
              disableToolbar
              disabled={this.props.dateRangeLabel !== 'custom range'}
              className={classes.date}
              variant="inline"
              format="MM/dd/yyyy"
              margin="dense"
              id="endDate"
              label="End Date"
              value={moment(this.props.endDate).add('day')}
              onChange={event => event ? this.handleDateChange(`endDate`, moment(event).format('YYYY-MM-DD')) : null}
              InputProps={{
                classes: {
                  input: classes.resize,
                },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              KeyboardButtonProps={{
                'aria-label': 'change end date',
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    )
  };

};

export default withStyles(styles)(DateFilter)