import React, { useState, useContext, useEffect } from 'react';
import { GlobalContext } from '../../../global-context';

import gql from 'graphql-tag';
import fragments from '../../utils/graphQL/fragments';

import { makeStyles, Typography, TextField, InputAdornment, Icon, MenuItem } from '@material-ui/core';
import { Modal, ModalHeader, ModalContent, ModalFooter, ModalAction } from '../../reusable/ModalComponents';
import CustomerSelect from '../../reusable/CustomerSelect';

import sdk from '@hopdrive/sdk';

const log = true;

////////// COMPONENT //////////
export default function LocationAddEditModal(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const { open, onClose, locationInput } = props;

  const [type, setType] = useState(``);
  const [customerId, setCustomerId] = useState(``);
  const [name, setName] = useState(``);
  const [address, setAddress] = useState(``);
  const [nickname, setNickname] = useState(``);
  const [email, setEmail] = useState(``);
  const [phone, setPhone] = useState(``);

  useEffect(() => {
    if (locationInput) {
      setType(locationInput.type || ``);
      setCustomerId(locationInput.customer_id || ``);
      setName(locationInput.name || ``);
      setAddress(locationInput.address || ``);
      setNickname(locationInput.nickname || ``);
      setEmail(locationInput.email || ``);
      setPhone(locationInput.phone || ``);
    }
  }, [open, locationInput]);

  const handleInputChange = setHandler => event => {
    if (setHandler && event) setHandler(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      let res = null;
      let variables = {
        type: type,
        customerId: customerId,
        regionId: await getRegionId(),
        placeId: locationInput.place_id,
        name: name,
        nickname: nickname,
        address: address,
        latitude: locationInput.latitude,
        longitude: locationInput.longitude,
        email: email,
        phone: phone,
        favorite: locationInput.favorite || false,
      };

      if (locationInput.id) {
        variables = { ...variables, id: locationInput.id };
        res = await updateLocation(variables);
        if (res) {
          const resLoc = res.data.update_locations.returning[0];
          log && console.log(`>> UPDATED Location:`, resLoc);
          // ctx.handleNotifications(true, `success`, `Location: '${resLoc.name}' was successfully updated.`);
          handleClose(resLoc);
        }
      } else {
        res = await insertLocation(variables);
        if (res) {
          const resLoc = res.data.insert_locations.returning[0];
          log && console.log(`>> INSERTED Location:`, resLoc);
          // ctx.handleNotifications(true, `success`, `Location: '${resLoc.name}' was successfully inserted.`);
          handleClose(resLoc);
        }
      }
    } catch (err) {
      console.log(`Error upserting location:`, err);
      ctx.handleNotifications(true, `error`, `Failed to upsert location: ${err.toString()}`);
    }
  };
  const handleClose = (resLoc = null) => {
    if (onClose) onClose(resLoc);
    setType(``);
    setCustomerId(``);
    setName(``);
    setAddress(``);
    setNickname(``);
    setEmail(``);
    setPhone(``);
  };

  const insertLocation = async variables => {
    return await ctx.apolloClient.mutate({
      mutation: INSERT_LOCATION,
      variables: variables,
    });
  };
  const updateLocation = async variables => {
    return await ctx.apolloClient.mutate({
      mutation: UPDATE_LOCATION,
      variables: variables,
    });
  };

  const getRegionId = async () => {
    var regionId;
    // Used to get region_id from Latitude & Longitude
    let regionRes = await sdk.regions.getByCoords([locationInput.longitude, locationInput.latitude]);
    if (regionRes.success && regionRes.data && regionRes.data.length > 0) {
      regionId = regionRes.data[0].id;
    } else {
      let errorMessage = regionRes.errors && regionRes.errors.length > 0 ? regionRes.message : 'error getting Region';
      let regionError = new Error(errorMessage);
      console.error(regionError);
      regionId = null;
    }
    return regionId;
  };

  return (
    <>
      <Modal open={open}>
        <ModalHeader handleClose={handleClose}>Save Location</ModalHeader>

        <ModalContent
          subtitle={`Save and store your pinned location to use it. You may edit the type, customer ID and name. The address is set by Google. You may also provide an optional nickname, email and phone number associated\xa0with\xa0it.`}
        >
          <Typography className={cls.title}>Required Fields</Typography>
          <div className={cls.break} />
          <TextField
            select
            required
            fullWidth
            label='Type'
            placeholder='Specify a location type...'
            value={type}
            onChange={handleInputChange(setType)}
            margin='dense'
            variant='outlined'
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    label_important
                  </Icon>
                </InputAdornment>
              ),
            }}
            className={cls.field}
          >
            <MenuItem value={`customer`}>Customer</MenuItem>
            <MenuItem value={`consumer business`}>Consumer Business</MenuItem>
            <MenuItem value={`consumer residential`}>Consumer Residential</MenuItem>
          </TextField>
          <div className={cls.break} />
          <CustomerSelect required value={customerId || 0} onChange={handleInputChange(setCustomerId)} />
          <div className={cls.break} />
          <TextField
            required
            fullWidth
            label='Name'
            placeholder='Enter a name for the location...'
            value={name}
            onChange={handleInputChange(setName)}
            margin='dense'
            variant='outlined'
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    local_offer
                  </Icon>
                </InputAdornment>
              ),
            }}
            className={cls.field}
          />
          <div className={cls.break} />
          <TextField
            disabled
            required
            fullWidth
            multiline
            label='Address'
            placeholder='Enter an address for the location...'
            value={address}
            onChange={handleInputChange(setAddress)}
            margin='dense'
            variant='outlined'
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    room
                  </Icon>
                </InputAdornment>
              ),
            }}
            className={cls.field}
          />
          <div className={cls.bigBreak} />
          <Typography className={cls.title}>Optional Fields</Typography>
          <div className={cls.break} />
          <TextField
            fullWidth
            label='Nickname'
            placeholder='(Optional) Enter a nickname for the location...'
            value={nickname}
            onChange={handleInputChange(setNickname)}
            margin='dense'
            variant='outlined'
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    loyalty
                  </Icon>
                </InputAdornment>
              ),
            }}
            className={cls.field}
          />
          <div className={cls.break} />
          <TextField
            fullWidth
            type='email'
            label='Email'
            placeholder='(Optional) Enter a related email...'
            value={email}
            onChange={handleInputChange(setEmail)}
            margin='dense'
            variant='outlined'
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    email
                  </Icon>
                </InputAdornment>
              ),
            }}
            className={cls.field}
          />
          <div className={cls.break} />
          <TextField
            fullWidth
            type='phone'
            label='Phone'
            placeholder='(Optional) Enter a related phone number...'
            value={phone}
            onChange={handleInputChange(setPhone)}
            margin='dense'
            variant='outlined'
            InputProps={{
              startAdornment: (
                <InputAdornment style={{ verticalAlign: 'top' }} position='start'>
                  <Icon color='disabled' fontSize='small'>
                    phone_iphone
                  </Icon>
                </InputAdornment>
              ),
            }}
            className={cls.field}
          />
        </ModalContent>

        <ModalFooter>
          <ModalAction
            onClick={() => handleSubmit()}
            disabled={type.length < 1 || !customerId || name.length < 1 || address.length < 1}
          >
            Save & Set Location
          </ModalAction>
          <ModalAction onClick={() => handleClose()} color='default'>
            Cancel
          </ModalAction>
        </ModalFooter>
      </Modal>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 21,
    fontWeight: 500,
  },
  field: {
    margin: 0,
  },

  break: {
    width: '100%',
    height: theme.spacing(2),
  },
  bigBreak: {
    width: '100%',
    height: theme.spacing(3),
  },
}));

////////// GRAPHQL //////////
const INSERT_LOCATION = gql`
  mutation insert_location(
    $type: String
    $customerId: bigint!
    $regionId: bigint
    $placeId: String
    $name: String!
    $nickname: String
    $address: String!
    $latitude: numeric!
    $longitude: numeric!
    $email: String
    $phone: String
    $favorite: Boolean!
  ) {
    insert_locations(
      objects: {
        active: 1
        type: $type
        customer_id: $customerId
        region_id: $regionId
        place_id: $placeId
        name: $name
        nickname: $nickname
        address: $address
        latitude: $latitude
        longitude: $longitude
        email: $email
        phone: $phone
        favorite: $favorite
      }
    ) {
      affected_rows
      returning {
        ...Location
      }
    }
  }
  ${fragments.location}
`;

const UPDATE_LOCATION = gql`
  mutation update_location(
    $id: bigint!
    $type: String
    $customerId: bigint!
    $regionId: bigint
    $placeId: String
    $name: String!
    $nickname: String
    $address: String!
    $latitude: numeric!
    $longitude: numeric!
    $email: String
    $phone: String
    $favorite: Boolean!
  ) {
    update_locations(
      where: { id: { _eq: $id } }
      _set: {
        active: 1
        type: $type
        customer_id: $customerId
        region_id: $regionId
        place_id: $placeId
        name: $name
        nickname: $nickname
        address: $address
        latitude: $latitude
        longitude: $longitude
        email: $email
        phone: $phone
        favorite: $favorite
      }
    ) {
      affected_rows
      returning {
        ...Location
      }
    }
  }
  ${fragments.location}
`;
