import React from 'react';
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Toolbar,
  Typography,
  TextField,
  Icon,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import '../react-contextmenu.css';
import AutoRefresh from '../AutoRefresh';

export default function AccordianTable(props) {
  const cls = useStyles();
  const {
    title,
    size,
    headers,
    rows,
    actions,
    search,
    setSearch,
    defaultOrder,
    defaultOrderBy,
    order,
    orderBy,
    setOrder,
    setOrderBy,
    tablePage,
    setTablePage,
    rowsPerPage,
    rowsPerPageOptions,
    setRowsPerPage,
    setExpandedRowId,
    button,
    className,
  } = props;

  let contextTrigger = null;

  const handleSearchInput = event => {
    setSearch(event.target.value.toLocaleLowerCase());
  };

  const handleResetTable = () => {
    setSearch(``);
    setOrder(defaultOrder);
    setOrderBy(defaultOrderBy);
    setTablePage(0);
    setRowsPerPage(rowsPerPageOptions[3]);
    if (setExpandedRowId) setExpandedRowId(0);
  };

  const handleSortTable = (event, property) => {
    const isAscending = orderBy === property && order === 'asc';
    setOrder(isAscending ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleSortByProperty = property => event => {
    handleSortTable(event, property);
  };

  const handleChangePage = (event, newPage) => {
    setTablePage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setTablePage(0);
  };

  const handleOptionsOpen = e => {
    if (contextTrigger) {
      contextTrigger.handleContextClick(e);
    }
  };

  const collect = props => {
    return props;
  };

  return (
    <>
      <div className={cls.paper + ' ' + className}>
        <Toolbar className={cls.toolbar}>
          <div className={cls.tableName}>
            <div className={cls.name}>
              <Typography className={cls.titleTxt} style={{ display: 'inline' }}>
                {title}
              </Typography>
            </div>
          </div>

          <div className={cls.tableTools}>
            <div className={cls.search}>
              <TextField
                fullWidth
                name='search'
                label='Search'
                placeholder='Search rows...'
                margin='dense'
                variant='outlined'
                value={search}
                onChange={handleSearchInput}
                className={cls.searchBar}
              />
            </div>

            <div className={cls.icon}>
              <Tooltip title='Reset Table'>
                {
                  // If a refetch prop is provided, a refresh icon will appear next to the title text that retriggers the parent query
                  props.refetch ? (
                    <AutoRefresh
                      refetch={props.refetch}
                      interval={60000}
                      refreshPersistAs={props.refreshPersistAs || 'accordian_table'}
                    />
                  ) : null
                }
              </Tooltip>
            </div>

            <div className={cls.icon}>
              <ContextMenuTrigger
                id={`at-options-cm`}
                source={`at-options-cm`}
                ref={c => (contextTrigger = c)}
                holdToDisplay={1000}
                collect={collect}
                disableIfShiftIsPressed={true}
              >
                <Tooltip title={`Options`}>
                  <IconButton onClick={handleOptionsOpen}>
                    <Icon>settings</Icon>
                  </IconButton>
                </Tooltip>
              </ContextMenuTrigger>
              {actions && actions.length > 0 && (
                <ContextMenu id={`at-options-cm`} style={{ boxShadow: '2px 4px 12px #00000050' }}>
                  {actions.map(
                    action =>
                      !action.hide && (
                        <MenuItem
                          data={{ action: action, ...action.data }}
                          onClick={action.handler}
                          disabled={action.disabled}
                          key={`atr-table-option-${action.name}`}
                        >
                          {action.label}
                        </MenuItem>
                      )
                  )}
                </ContextMenu>
              )}
            </div>
          </div>
        </Toolbar>

        <div className={cls.tableWrapper}>
          <Table className={cls.table} size={size ? size : `medium`}>
            <TableHead>
              <TableRow>
                {headers.map(
                  header =>
                    !header.hide && (
                      <TableCell
                        key={header.id}
                        align={header.alignLeft ? 'left' : 'right'}
                        padding='default'
                        sortDirection={orderBy === header.id ? order : false}
                      >
                        {header.value || (
                          <TableSortLabel
                            className={cls.headTxt}
                            active={orderBy === header.id}
                            direction={order}
                            onClick={handleSortByProperty(header.id)}
                          >
                            {header.label}
                            {orderBy === header.id ? (
                              <span className={cls.visuallyHidden}>
                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                              </span>
                            ) : null}
                          </TableSortLabel>
                        )}
                      </TableCell>
                    )
                )}
              </TableRow>
            </TableHead>
            <TableBody>{props.children}</TableBody>
          </Table>
        </div>

        {button}

        <TablePagination
          component='div'
          count={rows.length}
          page={tablePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          className={cls.pagination}
        />
      </div>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'relative',
    width: '100%',
    borderRadius: theme.shape.paperRadius,
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  table: {
    minWidth: '400px',
  },
  toolbar: {
    position: 'relative',
    minHeight: '80px',
    padding: theme.spacing(2),
    borderRadius: '8px 8px 0 0',
    background: '#fff',
    color: theme.palette.text.primary,
  },
  tableName: {
    left: theme.spacing(2),
    position: 'absolute',
    height: '48px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '-30px',
    },
  },
  name: {
    width: '100%',
    height: '100%',
    paddingRight: theme.spacing(1),
    verticalAlign: 'top',
  },
  titleTxt: {
    margin: '8px 0 0 0',
    fontSize: 20,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      margin: '12px 0 0 0',
      fontSize: 16,
    },
    [theme.breakpoints.down('xs')]: {
      margin: '14px 0 0 0',
      fontSize: 14,
    },
  },
  tableTools: {
    right: theme.spacing(2),
    position: 'absolute',
    height: '48px',
    [theme.breakpoints.down('sm')]: {
      right: theme.spacing(1.25),
    },
    [theme.breakpoints.down('xs')]: {
      right: theme.spacing(0.5),
    },
  },
  search: {
    display: 'inline-block',
    height: '100%',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginRight: '8px',
    verticalAlign: 'top',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  searchBar: {
    width: '320px',
    margin: '4px 0 0 0',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  icon: {
    display: 'inline-block',
    height: '100%',
    verticalAlign: 'top',
  },
  headTxt: {
    fontSize: 12,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  button1: {
    zIndex: 1001,
    position: 'absolute',
    bottom: theme.spacing(1.5),
    left: theme.spacing(1.5),
    width: '160px',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  button2: {
    position: 'relative',
    width: '100%',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  pagination: {
    borderRadius: '0 0 8px 8px',
    background: theme.palette.background.paper,
    fontSize: 12,
  },
  refreshBtn: {
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.secondary.veryLight,
    },
  },
}));
