import React from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton, Paper, Table, TableBody, TableCell, TableRow, Tooltip, Typography, withStyles } from '@material-ui/core';
import { PriorityHigh, EventNote } from '@material-ui/icons';
// import VehicleCard from './VehicleCard'

const styles = theme => ({
  root: {
    width: '100%',
    marginTop:  theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 400,
  },
  addMove: {
    margin: " 20px 50px 20px 50px",
    display: "block",
    float: "right"
  },
  row: {
    transition: "all .2s ease-in-out",
    '&hover': {
      transform: "scale(1.01)",
    }
  }
});

class VehicleTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  render() {

    const { classes } = this.props;

    return this.props.vehicles.length > 0 ? (
      <React.Fragment>
        {/* <VehicleCard vehicles={this.props.vehicles}/> */}
        <Paper className={classes.root} >
          <Table className={classes.table}>
            <TableBody>
              {this.props.vehicles.map((vehicle, index) => (
                <TableRow className={classes.row} hover key={index}>
                  <TableCell align="center" component="th" scope="row">
                    <img src="https://via.placeholder.com/60" alt="Vehicle" />
                  </TableCell>
                  <TableCell align="left">
                    <Typography inline="true" color="textSecondary"> Stock: </Typography>
                    <Typography inline="true" > {vehicle.stock} </Typography>
                    <br />
                    <Typography inline="true" color="textSecondary"> VIN: </Typography>
                    <Typography inline="true" > {vehicle.vin ? vehicle.vin : 'none'} </Typography>
                  </TableCell>
                  <TableCell align="center">
                    <Typography inline="true" > {vehicle.year} </Typography>
                    <Typography inline="true" > {vehicle.make} </Typography>
                    <Typography inline="true" > {vehicle.model} </Typography>
                    <br />
                    <Typography inline="true"> {vehicle.color} </Typography>
                  </TableCell>
                  <TableCell align="center">
                    {vehicle.manual ?
                      <React.Fragment>
                        <Tooltip title="Vehicle has a manual transmission" >
                          <IconButton>
                            <PriorityHigh color="error" />
                          </IconButton>
                        </Tooltip>
                      </React.Fragment>
                      : null}
                    {vehicle.instructions && vehicle.instructions.trim().length > 0 ?
                      <React.Fragment>
                        <Tooltip title={vehicle.instructions} >
                          <IconButton>
                            <EventNote color="primary" />
                          </IconButton>
                        </Tooltip>
                      </React.Fragment>
                      : null}
                  </TableCell>
                  <TableCell>
                    <Button
                      id={index}
                      variant="contained"
                      style={{ float: "right", marginRight: "75px" }}
                      className={classes.button}
                      onClick={() => {
                        this.props.removeVehicle(" ",index);
                        this.props.updateVehicleData(vehicle.vin, vehicle.stock, vehicle.year, vehicle.color, vehicle.make, vehicle.model, vehicle.manual, vehicle.instructions)
                      }}>
                      Update
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button id={index} variant="contained" style={{ float: "right", marginRight: "75px" }} className={classes.button} onClick={this.props.removeVehicle}>
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
        <br />
        <br />
      </React.Fragment>
    ) : null
  }
}

VehicleTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VehicleTable);