import React, { useContext, useState, useEffect } from "react";
import {
  withStyles,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Checkbox,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  CircularProgress,
  Dialog,
  DialogActions,
  Button,
  IconButton,
  Icon,
  Tooltip,
} from "@material-ui/core";
import { GlobalContext } from "../../global-context";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import auth from "../utils/auth0Helper";
import Loading from "../utils/Loading";
import AutoRefresh from "./AutoRefresh";

let log = false;

const GET_REGIONS = gql`
  query getAllowedRegions($allowed_regions: [bigint!]) {
    regions(where: { id: { _in: $allowed_regions } }) {
      id
      name
      team_id
    }
  }
`;

const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(2),
    minWidth: 250,
  },
  button: {
    margin: theme.spacing(2),
    marginLeft: theme.spacing(5),
  },
  refreshBtn: {
    margin: theme.spacing(1),
    "&:hover": {
      backgroundColor: theme.palette.secondary.veryLight,
    },
  },
});

const RegionSelectModal = (props) => {
  const context = useContext(GlobalContext);

  const parseRegions = () => {
    let regions = context.userProfile["https://hasura.io/jwt/claims"]["x-hasura-selected-regions"];
    if (!regions) regions = {};
    else
      regions = JSON.parse(regions.replace("{", "[").replace("}", "]")).reduce((o, key) => ({ ...o, [key]: true }), {});
    return regions;
  };

  const validateRegions = () => {
    // checks if the local state of regions matches the JWT
    let JWT_regions = parseRegions();
    if (JSON.stringify(JWT_regions) === JSON.stringify(regions)) return true;
    else return false;
  };

  const [regions, setRegions] = useState(parseRegions());
  const [appLoad, setLoading] = useState(false);
  const [allowedRegions, setAllowedRegions] = useState(
    JSON.parse(
      context.userProfile["https://hasura.io/jwt/claims"]["x-hasura-allowed-regions"]
        .replace("{", "[")
        .replace("}", "]")
    )
  );

  // Update user's allowed regions on function mount if they have changed
  useEffect(() => {
    if (
      allowedRegions.toString() !==
      JSON.parse(
        context.userProfile["https://hasura.io/jwt/claims"]["x-hasura-allowed-regions"]
          .replace("{", "[")
          .replace("}", "]")
      ).toString()
    )
      setAllowedRegions(
        JSON.parse(
          context.userProfile["https://hasura.io/jwt/claims"]["x-hasura-allowed-regions"]
            .replace("{", "[")
            .replace("}", "]")
        )
      );
  });

  const handleSave = async () => {
    if (!validateRegions()) {
      setLoading(true);
      await auth
        .updateMetadata(context.userProfile.sub, {
          selected_regions: Object.keys(regions).filter((bool) => regions[bool]), // Build an array of region IDs that have a value of true
        })
        .then(async (res) => {
          if (log) console.log("response from updateMetadata function", res);
          if (!res) {
            setLoading(false);
            return;
          }
          if (res.status !== 200) {
            setLoading(false);
            context.handleNotifications(true, "error", "Failed to update region selection.");
            return;
          }
          // Trigger a relog of the user in order to refresh their JWT
          // This is not done automatically as the previous JWT remains valid for the session
          context.auth0.loginWithRedirect()
        })
        .catch((err) => {
          context.handleNotifications(true, "error", "Failed to update region selection. " + err.toString());
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const handleChange = (name) => (event) => {
    setRegions({ ...regions, [name]: event.target.checked });
  };

  return (
    <Query
      query={GET_REGIONS}
      variables={{ allowed_regions: allowedRegions }}
      // onError={(err) => context.handleNotifications(true, "error", "Failed to retrieve regions: " + err.toString())}
    >
      {({ loading, error, data, refetch }) => {
        if (loading) return <Loading />;
        if (error) return `Error! ${error.message}`;
        return (
          <Dialog open={props.open} aria-labelledby="region-select-modal">
            <DialogTitle id="region-select-modal">Region Selection</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Select one or more regions to manage. You will see all moves that start or finish in the selected
                regions as well as all lanes and locations for the regions.
              </DialogContentText>
              {appLoad ? (
                <CircularProgress
                  style={{
                    display: "block",
                    margin: "auto",
                    position: "absolute",
                    // top: "0",
                    left: "0",
                    // bottom: "0",
                    right: "0",
                  }}
                />
              ) : (
                <>
                  <FormControl className={props.classes.formControl}>
                    <FormLabel component="legend">
                      Available Regions:
                      <AutoRefresh refetch={refetch} interval={60000} persistAs="region_select" />
                    </FormLabel>
                    <FormGroup>
                      {data.regions.map((region) => {
                        return (
                          <FormControlLabel
                            key={`form-control-label-${region.id}`}
                            control={
                              <Checkbox
                                checked={regions[region.id.toString()] || false}
                                onChange={handleChange(region.id)}
                                value={region.id.toString()}
                              />
                            }
                            label={region.name}
                          ></FormControlLabel>
                        );
                      })}
                    </FormGroup>
                  </FormControl>
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                className={props.classes.button}
                disabled={validateRegions()}
                onClick={handleSave}
              >
                Accept
              </Button>
              <Button
                color="default"
                variant="contained"
                className={props.classes.button}
                disabled={appLoad}
                onClick={props.toggle}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        );
      }}
    </Query>
  );
};

export default withStyles(styles)(RegionSelectModal);
