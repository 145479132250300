import React from 'react';
import { makeStyles, Dialog } from '@material-ui/core';

////////// COMPONENT //////////
export default function Modal(props) {
  const cls = useStyles();
  const { className, open, width, handleClose } = props;
  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          if (handleClose) handleClose();
        }}
        maxWidth={width || `sm`}
        PaperProps={{ className: cls.root + ' ' + className }}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <div className={cls.main}>{props.children}</div>
      </Dialog>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    margin: theme.spacing(2),
  },
  main: {
    position: 'relative',
  },
}));
