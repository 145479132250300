import 'date-fns';
import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Container } from '@material-ui/core';
import RegionsTableView from './regions/RegionsTableView';
import fragments from '../utils/graphQL/fragments';
import { GlobalContext } from '../../global-context';

const styles = theme => ({
  root: {
    display: 'flex',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
});

const GET_REGIONS = gql`
  {
    regions {
      ...Region
    }
  }
  ${fragments.region}
`;

const REGIONS_SUBSCRIPTION = gql`
  subscription {
    regions {
      ...Region
    }
  }
  ${fragments.region}
`;

class Regions extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Container maxWidth='lg'>
          {this.context && this.context.userIsAuthenticated() && (
            <Query
              query={GET_REGIONS}
              // onError={err => {console.error(err);this.context.handleNotifications(true, "error", "Query failed to retrieve regions data")}}
            >
              {({ subscribeToMore, ...result }) => {
                if (result.loading) return 'Loading...';
                if (result.error) return `Error! ${result.error.message}`;
                return (
                  <RegionsTableView
                    subscribeToRegions={() => {
                      subscribeToMore({
                        document: REGIONS_SUBSCRIPTION,
                        updateQuery: (prev, { subscriptionData }) => {
                          if (!subscriptionData.data) return prev;
                          return { regions: subscriptionData.data.regions };
                        },
                      });
                    }}
                    regions={result.data.regions}
                  />
                );
              }}
            </Query>
          )}
        </Container>
      </div>
    );
  }
}

Regions.propTypes = {
  classes: PropTypes.object.isRequired,
};

Regions.contextType = GlobalContext;

export default withStyles(styles)(Regions);
