import React, { useState, useContext, useEffect } from 'react';
import Script from 'react-load-script';
import { GlobalContext } from '../../../global-context';

import { makeStyles, Typography, TextField, InputAdornment, Icon, Tooltip } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';

import sdk from '@hopdrive/sdk';
import axios from 'axios';
import gql from 'graphql-tag';
import { Subscription } from 'react-apollo';
import * as Sentry from '@sentry/react';

import LocationAddEditModal from './LocationAddEditModal';
import AddressBuilderModal from './AddressBuilderModal';

const locIcon = require('../../../static/location-icon.png');
const log = false;

////////// COMPONENT //////////
export default function LocationSelect(props) {
  const ctx = useContext(GlobalContext);
  const cls = useStyles();

  const { locationData, onChange, label, customerId } = props;

  const [location, setLocation] = useState(locationData ? locationData : null);
  const [input, setInput] = useState(``);
  const [suggestions, setSuggestions] = useState([]);
  const [google, setGoogle] = useState(``);

  const [addressModal, setAddressModal] = useState({ open: false });
  const [locationModal, setLocationModal] = useState({ open: false });

  useEffect(() => {
    if (onChange) onChange(location);
  }, [location]);

  useEffect(() => {
    setLocation(locationData);
  }, [locationData]);

  const handleLocationChange = newLoc => {
    log && console.log(`Selected Location:`, newLoc);
    if (newLoc) {
      if (newLoc.id) {
        setLocation(newLoc);
      }
      // else handleLocationAddEditModalOpen(newLoc);
      else {
        handleNewLocation(newLoc);
      }
    } else setLocation(null);
  };

  const getRegionId = async (lat, lon) => {
    var regionId;
    // Used to get region_id from Latitude & Longitude
    let regionRes = await sdk.regions.getByCoords([lon, lat]);
    if (regionRes.success && regionRes.data && regionRes.data.length > 0) {
      regionId = regionRes.data[0].id;
    } else {
      let errorMessage = regionRes.errors && regionRes.errors.length > 0 ? regionRes.message : 'error getting Region';
      let regionError = new Error(errorMessage);
      console.error(regionError);
      regionId = null;
    }
    return regionId;
  };

  const handleNewLocation = async newLoc => {
    try {
      log && console.log('Google Location.....');
      const locObj = {
        regionId: await getRegionId(newLoc.latitude, newLoc.longitude),
        type: newLoc.type || 'customer',
        name: newLoc.name,
        address: newLoc.address,
        nickname: newLoc.nickname || '',
        email: newLoc.email || '',
        phone: newLoc.phone || '',
        longitude: newLoc.longitude,
        latitude: newLoc.latitude,
      };
      setLocation(locObj);
      log && console.log(locObj);
    } catch (err) {
      console.log('Failed to create location:', err);
      ctx.handleNotifications(true, `error`, `Failed to create location: ${err}`);
    }
  };

  const handleInputChange = (event, value, reason) => {
    if (reason === `input` && value && value !== ``) {
      log && console.log(`Input:`, value);
      handleLocationChange(null);
      handleGoogleSearch(value);
      setInput(value);
    } else {
      setSuggestions([]);
      setInput(null);
    }
  };

  const handleKeyUp = event => {
    if (event.key === `Enter`) {
      if (location && location.id) return;

      // Preventing Address Build - only taking known locations & Google addresses
      // else if (input) handleAddressBuilderModalOpen(input);
    }
  };

  const handleGoogleScript = () => {
    setGoogle(new window.google.maps.places.PlacesService(document.createElement('div')));
  };
  const handleGoogleSearch = input => {
    google.textSearch({ query: input }, res => {
      if (res) {
        log && console.log(`Response from Google:`, res);
        setSuggestions(
          res.map(s => ({
            id: null,
            customer_id: null,
            region_id: null,
            place_id: s.place_id,
            name: s.name,
            address: s.formatted_address,
            input_address: input,
            latitude: s.geometry.location.lat(),
            longitude: s.geometry.location.lng(),
            nickname: null,
            email: null,
            phone: null,
            notes: null,
            favorite: false,
          }))
        );
      }
    });
  };

  const handleAddressBuilderModalOpen = (addressInput = null) => {
    setAddressModal({ ...addressModal, open: true, input: addressInput });
  };
  const handleAddressBuilderModalClose = (addressOutput = null) => {
    if (addressOutput) {
      setAddressModal({ ...addressModal, open: false });
      handleLocationAddEditModalOpen(addressOutput);
    } else setAddressModal({ ...addressModal, open: false });
  };

  const handleLocationAddEditModalOpen = (locationInput = null) => {
    setLocationModal({ ...locationModal, open: true, input: locationInput });
  };
  const handleLocationAddEditModalClose = (locationOutput = null) => {
    if (locationOutput) {
      setLocationModal({ ...locationModal, open: false });
      setLocation(locationOutput);
    } else setLocationModal({ ...locationModal, open: false });
  };

  return (
    <>
      <AddressBuilderModal
        open={addressModal.open}
        onClose={handleAddressBuilderModalClose}
        addressInput={addressModal.input}
      />
      <LocationAddEditModal
        open={locationModal.open}
        onClose={handleLocationAddEditModalClose}
        locationInput={locationModal.input}
      />

      <Subscription subscription={GET_LOCATIONS} variables={{ customerId: customerId }}>
        {({ loading, error, data }) => {
          if (loading) {
            // Return a fake TextField to hide the loading
            return (
              <TextField
                error={props.valid ? !props.valid.toString() : 'false'}
                fullWidth
                label={label ? label : `Location`}
                placeholder='Search for a location...'
                variant='outlined'
                margin='none'
                InputLabelProps={{ shrink: true }}
              />
            );
          }
          if (error) {
            log && console.log(`Failed to retrieve locations:`, error);
            ctx.handleNotifications(true, `error`, `Subscription failed to retrieve locations: ` + error.toString());
          }
          if (data && data.locations) {
            let locs = data.locations.length > 0 ? data.locations : [];

            return (
              <>
                <Script
                  url={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GPLCS}&libraries=places`}
                  onLoad={handleGoogleScript}
                />

                <Autocomplete
                  includeInputInList
                  options={locs.concat(suggestions)}
                  filterOptions={createFilterOptions({
                    stringify: option => `${option.name} ${option.nickname} ${option.address} ${option.input_address}`,
                  })}
                  getOptionLabel={option => (!option.nickname ? option.name : option.nickname || '')}
                  noOptionsText='Unknown Address'
                  value={location}
                  onChange={(event, value) => handleLocationChange(value)}
                  onInputChange={handleInputChange}
                  onKeyUp={handleKeyUp}
                  style={{ width: '100%' }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      error={props.valid ? !props.valid.toString() : 'false'}
                      fullWidth
                      label={label ? label : `Location`}
                      placeholder='Search for a location...'
                      helperText={location && location.address ? location.address : null}
                      variant='outlined'
                      margin='none'
                    />
                  )}
                  renderOption={option => (
                    <>
                      {option.id ? (
                        option.favorite ? (
                          <Tooltip placement='top' title='Favored Location'>
                            <div className={cls.optionIcon}>
                              <Icon className={cls.favoriteIcon}>favorite</Icon>
                            </div>
                          </Tooltip>
                        ) : (
                          <Tooltip placement='top' title='Stored Location'>
                            <div className={cls.optionIcon}>
                              <img className={cls.locIcon} src={locIcon} alt='location pin' />
                            </div>
                          </Tooltip>
                        )
                      ) : (
                        <Tooltip placement='top' title='Google-Suggested Location'>
                          <div className={cls.optionIcon}>
                            <FontAwesomeIcon
                              className={cls.googleIcon}
                              icon={faGoogle}
                              title='Google-Suggested Location'
                            />
                          </div>
                        </Tooltip>
                      )}
                      <div className={cls.option}>
                        <Typography className={cls.optionName}>
                          {!option.nickname ? option.name : option.nickname}
                        </Typography>
                        <Typography className={cls.optionAddress}>{option.address}</Typography>
                      </div>
                    </>
                  )}
                />
              </>
            );
          } else {
            return (
              <>
                <div>Error Finding Locations</div>
              </>
            );
          }
        }}
      </Subscription>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  heart: {
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.error.main,
    },
    transition: '0.2s',
    cursor: 'pointer',
  },
  heartActive: {
    color: theme.palette.error.main,
    '&:hover': {
      color: theme.palette.error.light,
    },
    transition: '0.2s',
    cursor: 'pointer',
  },
  favoriteIcon: {
    color: theme.palette.error.main,
  },
  locIcon: {
    marginLeft: theme.spacing(0.4),
    marginRight: theme.spacing(0.6),
  },
  googleIcon: {
    color: theme.palette.text.secondary,
  },
  option: {
    display: 'block',
  },
  optionName: {
    fontSize: '16px',
    fontWeight: 500,
  },
  optionAddress: {
    color: theme.palette.text.secondary,
    fontSize: '12px',
    fontWeight: 400,
  },
  optionIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '48px',
    minHeight: '48px',
  },
}));

////////// GRAPHQL //////////
const GET_LOCATIONS = gql`
  subscription get_locations($customerId: bigint!) {
    locations(
      where: { customer_id: { _eq: $customerId }, active: { _eq: 1 } }
      order_by: [{ favorite: desc }, { name: asc }]
    ) {
      id
      customer_id
      region_id
      place_id
      name
      address
      latitude
      longitude
      nickname
      email
      phone
      notes
      favorite
      type
    }
  }
`;

const UPDATE_LOCATION_FAVORITE = gql`
  mutation update_location_favorite($id: bigint!, $fav: Boolean!) {
    update_locations(where: { id: { _eq: $id } }, _set: { favorite: $fav }) {
      affected_rows
      returning {
        id
        name
        nickname
        address
        favorite
      }
    }
  }
`;
