import React, { useContext, useState } from 'react';

import { makeStyles, Container, Button, Typography } from '@material-ui/core';

import gql from 'graphql-tag';
import { Query } from 'react-apollo';

import Modal from '../reusable/Modal';
import FaqTables from './faqManager/FaqTables';
import Loading from '../utils/Loading';
import { GlobalContext } from '../../global-context';

const log = false;

////////// COMPONENT //////////
export default function FaqManager() {
  const cls = useStyles();
  const ctx = useContext(GlobalContext);

  const [modalRender, setModalRender] = useState(null);
  const [modalData, setModalData] = useState(null);

  const handleFAQ = () => {
    setModalData(`hi`);
    setModalRender(`create faq`);
  };

  return (
    <>
      <Modal
        open={modalRender !== null}
        data={modalData}
        setData={setModalData}
        render={modalRender}
        setRender={setModalRender}
      />

      <div className={cls.root}>
        <Container maxWidth='lg'>
          <Button className={cls.button} variant='contained' color='primary' onClick={() => handleFAQ()}>
            Create a new FAQ
          </Button>
        </Container>
        <div style={{ width: '100%', height: '16px' }} />
        {ctx && ctx.userIsAuthenticated() && (
          <Query query={GET_FAQS}>
            {({ loading, error, data }) => {
              if (loading) return <Loading />;
              if (error) {
                console.log(`Error getting FAQs:`, error);
                return (
                  <div className={cls.rootTable}>
                    <Container maxWidth='sm'>
                      <div className={cls.notFound}>
                        <Typography className={cls.notFoundTxt}>ERROR GETTING FAQS</Typography>
                      </div>
                    </Container>
                  </div>
                );
              }
              if (data) {
                let faqs = data.faqs;
                log && console.log(`FAQs:`, faqs);
                let all = faqs.filter(f => f.user === null);
                let dealer = faqs.filter(f => f.user === `dealer`);
                let driver = faqs.filter(f => f.user === `driver`);

                return (
                  <>
                    <Container maxWidth='lg'>
                      <FaqTables
                        allFaqs={all}
                        dealerFaqs={dealer}
                        driverFaqs={driver}
                        setModalData={setModalData}
                        setModalRender={setModalRender}
                      />
                    </Container>
                  </>
                );
              } else {
                return (
                  <div className={cls.rootTable}>
                    <Container maxWidth='sm'>
                      <div className={cls.notFound}>
                        <Typography className={cls.notFoundTxt}>NO FAQ DATA FOUND</Typography>
                      </div>
                    </Container>
                  </div>
                );
              }
            }}
          </Query>
        )}
      </div>
    </>
  );
}

////////// STYLES //////////
const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  rootTable: {
    verticalAlign: 'top',
    position: 'relative',
    width: '100%',
  },
  button: {
    boxShadow: `none`,
    '&:hover, &:active': {
      boxShadow: `none`,
    },
  },
  notFound: {
    width: '100%',
    padding: theme.spacing(4),
    borderRadius: theme.shape.paperRadius,
    marginLeft: 'auto',
    marginRight: 'auto',
    background: theme.palette.background.paper,
    boxShadow: theme.shadow.main,
  },
  notFoundTxt: {
    color: theme.palette.text.secondary,
    lineHeight: 1.25,
    textAlign: 'center',
    fontSize: 21,
    fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    },
  },
}));

////////// GRAPHQL //////////
const GET_FAQS = gql`
  query get_faqs {
    faqs(where: { active: { _eq: true } }) {
      id
      active
      user
      question
      answer
      new_flag
    }
  }
`;
