import React from "react";
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import ThumbIcon from '@material-ui/icons/ThumbUpOutlined'
import PlayIcon from '@material-ui/icons/PlayCircleFilled'
import CheckIcon from '@material-ui/icons/CheckCircleRounded'
import CancelIcon from '@material-ui/icons/CancelSharp'
import TookanSyncIcon from '@material-ui/icons/SwapHorizontalCircle'


const useStyles = makeStyles(theme => ({
    accepted: {
        color: "white",
        background: "DarkViolet",
        borderRadius: "25px",
        // width: "fit-content",
        // height: "fit-content"
    },
    inProgress: {
        color: "orange",
        background: "white",
        borderRadius: "25px",
        // width: "fit-content",
        // height: "fit-content"
    },
    completed: {
        color: "#00C853",
        background: "white",
        borderRadius: "25px",
        // width: ".75em",
        // height: ".75em"
    },
    cancelled: {
        color: "red",
        background: "white",
        borderRadius: "25px",
        // width: "fit-content",
        // height: "fit-content"
    },
    pending: {
        color: "yellow",
        background: "grey",
        borderRadius: "25px",
        // width: "fit-content",
        // height: "fit-content"
    },
    sync: {
        color: "red",
        background: "white",
        borderRadius: "25px"
    },
}))

export default function StatusIcon(props) {
    const classes = useStyles();
    const iconType = props.isSynced ? (props.cancelStatus ? props.cancelStatus : props.moveStatus) : 'not synced';

    switch (iconType) {
        case undefined:
        case null:
            return <></>;
        case 'not synced':
            return (
                <Tooltip title={`Not Synced with Tookan`} placement="top">
                    <TookanSyncIcon className={classes.sync}></TookanSyncIcon>
                </Tooltip>);
        case 'pending':
            return (
                <Tooltip title={`Cancel Status: ${props.cancelStatus}`} placement="top">
                    <CancelIcon className={classes.pending}></CancelIcon>
                </Tooltip>);
        case 'seen': case 'cancelled': case 'delivered': case 'returned':
            return (
                <Tooltip title={`Cancel Status: ${props.cancelStatus}`} placement="top">
                    <CancelIcon className={classes.cancelled}></CancelIcon>
                </Tooltip>);
        case 'accepted': //this is not a used status- there is no way to get this status at the moment
            return (
                <Tooltip title={`Move Accepted`} placement="top">
                    <ThumbIcon className={classes.accepted}></ThumbIcon>
                </Tooltip>
            );
        case 'delivery successful':
            return (
                <Tooltip title={`Move Completed`} placement="top">
                    <CheckIcon className={classes.completed}></CheckIcon>
                </Tooltip>);
        default:
            return (
                <Tooltip title={props.moveStatus} placement="top">
                    <PlayIcon className={classes.inProgress}></PlayIcon>
                </Tooltip>);
    }
}