import 'date-fns';
import React, { Component } from "react";
import gql from "graphql-tag";
import { GlobalContext } from '../../../global-context';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Typography, TextField, Button, Paper, Grid, FormControl, Input, InputLabel, FormHelperText } from '@material-ui/core';
import fragments from "../../utils/graphQL/fragments";

import sdk from '@hopdrive/sdk';

let log = true;

// either creates new lane or updates only the details of an existing lane
const UPSERT_LANE = gql`  
mutation upsert_lanes(
  $laneObjects: [lanes_insert_input!]!){
  insert_lanes(
    objects: $laneObjects
    on_conflict: {
      constraint: lanes_pkey
      update_columns: [
        average_drive_speed_min_per_mile
        average_drive_speed_mph
        createdat
        dealer_base_discount
        dealer_base_price
        dealer_base_rate
        dealer_base_rate_type
        dealer_stranded_discount
        dealer_stranded_price
        dealer_stranded_rate
        dealer_stranded_rate_type
        delivery_inspection_sec
        description
        destination_location_id
        distance_miles
        driver_base_pay
        driver_base_pay_discount
        driver_drive_pay
        driver_pay_per_kilometer
        driver_pay_per_mile
        driver_pay_per_minute
        driver_rake
        driver_return_pay
        driver_return_pay_discount
        driver_time_pay
        duration_sec
        estimated_rideshare_return_cost
        insurance_cost
        insurance_cost_per_mile
        tolls
        updatedat
      ]
    }
      ) {
    returning {
      ...Lane
    }
  }
 }
 ${fragments.lane}
 `;

const styles = theme => ({
  grid: {
    width: '60%',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  dlPairs: {
    margin: '1em 0',
    borderBottom: '1px solid rgb(231, 231, 231)',
  }
})

function DetailInputItem(props) {
  return (
    <Grid item>
      <FormControl
        fullWidth={false}
      >
        <InputLabel htmlFor="bootstrap-input">
          {props.label}
        </InputLabel>
        <Input
          disabled={props.disabled === true ? true : false}
          type={props.name && ((props.name).includes("rate_type") || (props.label === "Base Pay")) ? "text" : "number"}
          disableUnderline={true}
          name={props.name}
          // endAdornment={props.units !== '$' ? props.units : null}
          startAdornment={props.units === '$' ? '$' : null}
          value={props.value}
          onChange={props.onChange}
        />
        <FormHelperText>{props.helperText}</FormHelperText>
      </FormControl>
    </Grid>
  )
}

class DetailsSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  };
  /* inputs for calc fields are:
   driver_pay_per_minute
   driver_pay_per_mile
   dealer_base_discount
   dealer_stranded_discount
   driver_base_pay_discount
   driver_return_pay_discount
   driver_rake
   tolls
  */

  componentWillMount() {
    if (this.props.lane) {
      this.setLane(this.props.lane);
    } else {
      if (log) { console.log("LaneEdit: error setting lane") }
    }
  }
  componentDidMount() {
    if (log) { console.log(this.props.primary + "lane ", this.state) }
  }
  //  set state to equal lane
  setLane = (lane) => {
    const laneEntries = Object.entries(lane);
    laneEntries.forEach(([key, value]) => {
      // round numbers to hundredth
      if (typeof value === 'number') {
        value = Math.round(value * 100) / 100;
      }
      // upsert will not work if '_typename' is included
      if (key !== "__typename") {
        this.setState({ [key]: value })
      }
    })
  }
  recalculateLaneDetails = async () => {
    // TODO: switch these to params so that form values can overwrite detail form on click
    let currentLane = this.state;
    let newDetails = this.state;
    let detailedLane = await sdk.lanes.calculateDetails(
      currentLane,
      newDetails.driver_pay_per_minute,
      newDetails.driver_pay_per_mile,
      newDetails.dealer_base_discount,
      newDetails.dealer_stranded_discount,
      newDetails.driver_base_pay_discount,
      newDetails.driver_return_pay_discount,
      newDetails.driver_rake,
      newDetails.tolls);
    this.setLane(detailedLane);
  }

  handleInputChange = event => {
    const { name, value } = event.target;
    // convert minutes back to seconds
    if (name === 'duration_sec') {
      this.setState({ [name]: value * 60 });
    } else { this.setState({ [name]: value }) };
  };

  // submitLane = e => {
  //   e.preventDefault()
  //   this.upsertLane().then(res => {
  //     if (log) console.log("insertMoves .then -- res:", res);
  //     // if (res && res.length > 0) {
  //       // this.context.handleNotifications(true, "success", "Lane successfully created.");

  //       }
  //     // } else {
  //     //   this.context.handleNotifications(true, "warning", "Add Lane response was empty- possible error");
  //     // }
  //   })
  //     .catch(err => {
  //       if (log) console.log("insertMoves .catch -- err:", err)
  //       console.error(err);
  //       this.context.handleNotifications(true, "warning", "Lane data has not been updated");
  //     })
  // }

  upsertLane = async () => {
    this.context.handleNotifications(true, "info", "Sending Lane Data...");
    let lane = this.state;
    delete lane.pickup;
    delete lane.delivery;
    let updatedAt = { updatedat: "now()" }
    let updatedLane = Object.assign({}, lane, updatedAt);
    if (log) console.log("upsertLane - Lane to upsert:", updatedLane);
    this.context.apolloClient.mutate({
      mutation: UPSERT_LANE,
      variables: { laneObjects: updatedLane },
    }).then(res => {
      // validate that mutation was successful
      if (this.state.id) {
        // this.context.handleNotifications(true, "success", "Lane Edited")
      } else {
        // this.context.handleNotifications(true, "success", "Lane Created")
        if (this.props.primary) {
          this.props.handleSetSubmit("submitedPrimaryLane")
        } else {
          this.props.handleSetSubmit("submitedInverseLane")
        }
      };
      if (log) console.log("createlane - UPSERT_LANE response (res.data):", res.data.insert_lanes.returning);
      let resLane = res.data.insert_lanes.returning[0];
      delete resLane.customer;
      delete resLane.pickup;
      delete this.state.pickup;
      delete resLane.delivery;
      delete this.state.delivery;
      delete resLane.__typename;
      this.setLane(resLane);
    })
      .catch(err => {
        if (log) console.log("upsertLanes .catch -- err:", err)
        this.context.handleNotifications(true, "error", "Upsert Failed");
        throw err
      })
  }

  render() {
    // const { classes } = this.props;
    return (
      <Grid container item xs={6} spacing={1}>
        <Grid container item xs={12}>
          <Typography variant='subtitle1' component='h6' color='textPrimary'>
            {this.props.primary ? "Primary Lane: " + this.state.description : "Inverse Lane: " + this.state.description}
          </Typography>
        </Grid>
        {/* calculator */}
        <Grid container item xs={12} spacing={1}>
          <Grid container item xs={12} spacing={1}>
            Lane Detail Calculator
          </Grid>
          <Grid container item xs={12} spacing={1}>
            <Grid item>
              <TextField
                name={"driver_pay_per_minute"}
                label="Driver per Minute Rate"
                value={this.state.driver_pay_per_minute}
                onChange={this.handleInputChange}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <TextField
                name={"driver_pay_per_mile"}
                label="Driver per Mile Rate"
                value={this.state.driver_pay_per_mile}
                onChange={this.handleInputChange}
                margin="normal"
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={1}>
            <Grid item>
              <TextField
                name={"driver_base_pay_discount"}
                label="Driver Base Pay Discount"
                value={this.state.driver_base_pay_discount}
                onChange={this.handleInputChange}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <TextField
                name={"driver_return_pay_discount"}
                label="Driver Return Pay Discount"
                value={this.state.driver_return_pay_discount}
                onChange={this.handleInputChange}
                margin="normal"
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={1}>
            <Grid item>
              <TextField
                name={"dealer_base_discount"}
                label="Dealer Base Discount"
                value={this.state.dealer_base_discount}
                onChange={this.handleInputChange}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <TextField
                name={"dealer_stranded_discount"}
                label="Dealer Stranded Discount"
                value={this.state.dealer_stranded_discount}
                onChange={this.handleInputChange}
                margin="normal"
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={1}>
            <Grid item>
              <TextField
                name={"driver_rake"}
                label="Base Pay Rake"
                value={this.state.driver_rake}
                onChange={this.handleInputChange}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <TextField
                name={"tolls"}
                label="Tolls"
                value={this.state.tolls}
                onChange={this.handleInputChange}
                margin="normal"
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={1}>
            <Button
              onClick={this.recalculateLaneDetails}
              color="primary">
              Recalculate
            </Button>
          </Grid>
        </Grid>
        {/* details */}
        <Grid container item xs={11}>
          <Paper m={1} p={2}>
            <Grid container justify='space-around' item xs={12}>
              <Typography color="textPrimary" gutterBottom>
                <strong>Driving Details</strong>
              </Typography>
              <Typography color="textPrimary" gutterBottom>
                <span className="float-right text-primary">
                  {Math.round(this.state.distance_miles)}{" "}
                  mi/
                    <small className="text-secondary">
                    {Math.round(
                      this.state.duration_sec / 60
                    )}{" "}
                    min
                    </small>
                </span>
              </Typography>
              <Grid m={1} container item xs={12}>
                <Typography color="primary" gutterBottom>Info</Typography>
              </Grid>
              <Grid container item xs={12}>
                <DetailInputItem
                  label="Total Time"
                  name={'duration_sec'}
                  value={Math.round(this.state.duration_sec / 60)}
                  units={'mins'}
                  helperText=" "
                  onChange={this.handleInputChange}
                />
                <DetailInputItem
                  label="Drive Time"
                  value={Math.round((sdk.utilities.getDriveTimeAsSeconds(this.state.distance_miles, this.state.average_drive_speed_mph)) / 60)}
                  units={'mins'}
                  disabled={true}
                  helperText=" "
                  onChange={this.handleInputChange}
                />
                <DetailInputItem
                  label="Distance"
                  name={'distance_miles'}
                  value={this.state.distance_miles}
                  units={'miles'}
                  helperText=" "
                  onChange={this.handleInputChange}
                />
                <DetailInputItem
                  label="Return Ride"
                  name={'estimated_rideshare_return_cost'}
                  units={'$'}
                  value={this.state.estimated_rideshare_return_cost}
                  helperText=" "
                  onChange={this.handleInputChange}
                />
                <DetailInputItem
                  label="Insurance Per Mile"
                  name={'insurance_cost_per_mile'}
                  value={this.state.insurance_cost_per_mile}
                  units={'$'}
                  helperText=" "
                  onChange={this.handleInputChange}
                />
                <DetailInputItem
                  label="Insurance Cost"
                  name={'insurance_cost'}
                  value={this.state.insurance_cost}
                  units={'$'}
                  helperText=" "
                  onChange={this.handleInputChange}
                />
              </Grid>
            </Grid>
            <hr />
            <Grid m={1} container justify='space-around' item xs={12}>
              <Typography color="textPrimary" gutterBottom>
                <strong>Driver Pay</strong>
              </Typography>
              <Typography color="textPrimary" gutterBottom>
                <span className="float-right text-primary">
                  ${this.state.driver_base_pay}/
                  <small className="text-secondary">
                    ${this.state.driver_return_pay}
                  </small>
                </span>
              </Typography>
              <Grid container item xs={12}>
                <Typography color="primary" gutterBottom>Factors</Typography>
              </Grid>
              <Grid container item xs={12}>
                <DetailInputItem
                  label="Per Minute"
                  name={'driver_pay_per_minute'}
                  value={this.state.driver_pay_per_minute}
                  units={'$'}
                  helperText=" "
                  onChange={this.handleInputChange}
                />
                <DetailInputItem
                  label="Per Mile"
                  name={'driver_pay_per_mile'}
                  value={this.state.driver_pay_per_mile}
                  units={'$'}
                  helperText=" "
                  onChange={this.handleInputChange}
                />
                {/* Round */}
                <DetailInputItem
                  label="Average Speed"
                  name={'average_drive_speed_mph'}
                  value={this.state.average_drive_speed_mph}
                  untis={'mph'}
                  helperText=" "
                  onChange={this.handleInputChange}
                />
                <DetailInputItem
                  label="Rake"
                  name={'driver_rake'}
                  value={this.state.driver_rake}
                  units={'$'}
                  helperText=" "
                  onChange={this.handleInputChange}
                />
                <DetailInputItem
                  label="Base Pay"
                  value="1.00"
                  units={'$'}
                  helperText=" "
                  disabled={true}
                  onChange={this.handleInputChange}
                />
              </Grid>
              <Grid container item xs={12}>
                <Typography color="primary" gutterBottom>Base</Typography>
              </Grid>
              <Grid container justify="space-around" item xs={12}>
                <DetailInputItem
                  label="Time Pay"
                  name={'driver_time_pay'}
                  value={this.state.driver_time_pay}
                  units={'$'}
                  helperText=" "
                  onChange={this.handleInputChange}
                />
                <DetailInputItem
                  label="Drive Pay"
                  name={'driver_drive_pay'}
                  value={this.state.driver_drive_pay}
                  units={'$'}
                  helperText=" "
                  onChange={this.handleInputChange}
                />
                <DetailInputItem
                  label="Base Discount"
                  name={'driver_base_pay_discount'}
                  units={'$'}
                  value={this.state.driver_base_pay_discount}
                  helperText=" "
                  onChange={this.handleInputChange}
                />
              </Grid>
              <Grid container item xs={12}>
                <Typography color="primary" gutterBottom>Return</Typography>
              </Grid>
              <Grid container item xs={12}>
                <DetailInputItem
                  label="Return Pay"
                  name={'driver_return_pay'}
                  value={this.state.driver_return_pay}
                  units={'$'}
                  helperText=" "
                  onChange={this.handleInputChange}
                />
                <DetailInputItem
                  label="Return Discount"
                  name={'driver_return_pay_discount'}
                  value={this.state.driver_return_pay_discount}
                  units={'$'}
                  helperText=" "
                  onChange={this.handleInputChange}
                />
              </Grid>
            </Grid>
            <hr />
            <Grid m={1} container justify='space-around' item xs={12}>
              <Typography color="textPrimary" gutterBottom>
                <strong>Pricing</strong>
              </Typography>
              <Typography color="textPrimary" gutterBottom>
                <span className="float-right text-primary">
                  ${this.state.dealer_base_price}/
                  <small className="text-secondary">
                    ${this.state.dealer_stranded_price}
                  </small>
                </span>
              </Typography>
              <Grid container item xs={12}>
                <Typography color="primary" gutterBottom>Round Trip</Typography>
              </Grid>
              <Grid container item xs={12}>
                <DetailInputItem
                  label="Rate"
                  name={'dealer_base_rate'}
                  value={this.state.dealer_base_rate}
                  units={'$'}
                  helperText=" "
                  onChange={this.handleInputChange}
                />
                {/* change to drop down- options are flat or per */}
                <DetailInputItem
                  label="Rate Type"
                  name={'dealer_base_rate_type'}
                  value={this.state.dealer_base_rate_type}
                  helperText=" "
                  onChange={this.handleInputChange}
                />
                {/* Round */}
                <DetailInputItem
                  label="Discount"
                  name={'dealer_base_discount'}
                  value={this.state.dealer_base_discount}
                  units={'$'}
                  helperText=" "
                  onChange={this.handleInputChange}
                />
                <DetailInputItem
                  label="Tolls"
                  name={'tolls'}
                  value={this.state.tolls}
                  units={'$'}
                  helperText=" "
                  onChange={this.handleInputChange}
                />
                {/* Ask Rob how this should work re: calculations */}
                <DetailInputItem
                  label="Price"
                  name={'dealer_base_price'}
                  value={this.state.dealer_base_price}
                  units={'$'}
                  helperText=" "
                  onChange={this.handleInputChange}
                />
              </Grid>
              <Grid container item xs={12}>
                <Typography color="primary" gutterBottom>Stranded</Typography>
              </Grid>
              <Grid container item xs={12}>
                <DetailInputItem
                  label="Rate"
                  name={'dealer_stranded_rate'}
                  value={this.state.dealer_stranded_rate}
                  units={'$'}
                  helperText=" "
                  onChange={this.handleInputChange}
                />
                {/* change to drop down- options are flat or per */}
                <DetailInputItem
                  label="Rate Type"
                  name={'dealer_stranded_rate_type'}
                  value={this.state.dealer_stranded_rate_type}
                  helperText=" "
                  onChange={this.handleInputChange}
                />
                {/* Round */}
                <DetailInputItem
                  label="Discount"
                  name={'dealer_stranded_discount'}
                  value={this.state.dealer_stranded_discount}
                  units={'$'}
                  helperText=" "
                  onChange={this.handleInputChange}
                />
                <DetailInputItem
                  label="Tolls"
                  name={'tolls'}
                  value={this.state.tolls}
                  units={'$'}
                  helperText=" "
                  onChange={this.handleInputChange}
                />
                {/* Ask Rob how this should work re: calculations */}
                <DetailInputItem
                  label="Price"
                  name={'dealer_stranded_price'}
                  value={this.state.dealer_stranded_price}
                  units={'$'}
                  helperText=" "
                  onChange={this.handleInputChange}
                />
              </Grid>
            </Grid>
            <hr />
            <Grid m={1} container justify='space-around' item xs={12}>
              <Typography color="textPrimary" gutterBottom>
                <strong>Profit</strong>
              </Typography>
              <Typography color="textPrimary" gutterBottom>
                <span className="float-right text-primary">
                  $
                  {(
                    this.state.dealer_base_price -
                    this.state.insurance_cost -
                    this.state.tolls -
                    this.state.driver_drive_pay
                  ).toFixed(2)}
                  /
                  <small className="text-secondary">
                    ${(
                      this.state.dealer_stranded_price -
                      this.state.insurance_cost -
                      this.state.tolls -
                      this.state.driver_drive_pay -
                      this.state.driver_return_pay
                    ).toFixed(2)}
                  </small>
                </span>
              </Typography>
            </Grid>
            <Grid container justify="space-around" item xs={12}>
              <Typography color="textPrimary" gutterBottom>
                Total Round Trip Cost
              </Typography>
              <Typography color="textPrimary" gutterBottom>
                $
                {(
                  parseFloat(
                    this.state.insurance_cost
                  ) +
                  parseFloat(this.state.tolls) +
                  parseFloat(
                    this.state.driver_drive_pay
                  )
                ).toFixed(2)}
              </Typography>
            </Grid>
            <Grid container justify="space-around" item xs={12}>
              <Typography color="textPrimary" gutterBottom>
                Total Stranded Cost
              </Typography>
              <Typography color="textPrimary" gutterBottom>
                $
                {(
                  parseFloat(
                    this.state.insurance_cost
                  ) +
                  parseFloat(this.state.tolls) +
                  parseFloat(
                    this.state.driver_drive_pay
                  ) +
                  parseFloat(
                    this.state.driver_return_pay
                  )
                ).toFixed(2)}
              </Typography>
            </Grid>
            <hr />
            {/* Submit Button */}
            <Grid container item xs={12}>
              <Button
                color='primary'
                variant='outlined'
                onClick={this.upsertLane}
              >
                {this.state.id ? "Save Lane" : "Submit Lane"}
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}
DetailsSection.propTypes = {
  classes: PropTypes.object.isRequired,
};
DetailsSection.contextType = GlobalContext;


export default withStyles(styles)(DetailsSection);